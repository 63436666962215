import { put, takeLatest, select, call } from 'redux-saga/effects';
import { FORM_FIELD_CHANGED, bedroomAdded, bedroomRemoved, bathroomAdded, bathroomRemoved } from 'ducks/form/form';
import { getFormFieldInfo } from 'selectors/form.js';

const rooms = {
  bedrooms: { addAction: bedroomAdded, removeAction: bedroomRemoved },
  bathrooms: { addAction: bathroomAdded, removeAction: bathroomRemoved },
};

export function* putRoomActionsToAddOrRemove({ room, count }, { addAction, removeAction }) {
  const roomField = yield select(getFormFieldInfo, room);
  const size = roomField.get('value').size;
  const action = (count > size && addAction) || removeAction;
  const iterations = Math.abs(count - size);
  for (let i = 0; i < iterations; i++) {
    yield put(action());
  }
}

export function* processSpecialField({ payload: { field } }) {
  const { fieldName, fieldValue } = field || {};

  if (!fieldName) return;
  const room = fieldName.match(/(bedrooms|bathrooms)_count/);
  if (room) {
    yield call(putRoomActionsToAddOrRemove, { room: `property_${room[1]}`, count: fieldValue }, { ...rooms[room[1]] });
  }
}

export default function* watchSpecialFieldsChange() {
  yield takeLatest(FORM_FIELD_CHANGED, processSpecialField);
}
