import { connect } from 'react-redux';
import DefaultPage from '../../../components/templates/DefaultPage/DefaultPage';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { compose, withProps } from 'recompose';

const mapStateToProps = state => ({
  progress: state.view.getIn(['progress', 'overall']),
  basePath: `/${state.view.get('uuid')}`,
});

export default compose(
  connect(mapStateToProps),
  withProps(() => ({
    search: window.location.search,
  })),
  withMappedTranslations({
    navigationNextPage: 'TEXT.NAVIGATION.NEXT_PAGE',
    navigationPrevPage: 'TEXT.NAVIGATION.PREV_PAGE',
    OVERALL_PROGRESS: 'TEXT.SIDEBAR.OVERALL_PROGRESS',
    PERCENT_COMPLETE: 'TEXT.SIDEBAR.PERCENT_COMPLETE',
    pages: 'TEXT.NAVIGATION.PAGES',
    finish: 'TEXT.SIDEBAR.FINISH_EDITING',
  }),
)(DefaultPage);
