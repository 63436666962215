import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ICONS } from '../../../../constants';

import Icon from '../../Icon/Icon';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Label = styled.span`
  margin-left: 0.3rem;
  color: ${props => props.theme.colors.n800};
`;

export const Checkbox = styled(Icon)`
  &:focus {
    box-shadow: ${props => props.theme.shadows.focus};
    outline: none;
  }
  path {
    fill: ${props => props.theme.colors.n800};
  }
`;

const CheckboxInput = ({ checked, disabled, label, className, onClick }) => {
  const checkbox = (
    <Checkbox
      tabIndex={disabled ? -1 : 0}
      size="16"
      icon={checked ? ICONS.CHECKBOX_CHECKED : ICONS.CHECKBOX_UNCHECKED}
    />
  );
  return (
    <Wrapper className={className} onClick={() => onClick(!checked)}>
      {checkbox}
      {label && <Label>{label}</Label>}
    </Wrapper>
  );
};

CheckboxInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

CheckboxInput.defaultProps = {
  className: '',
  label: '',
  disabled: false,
  checked: false,
};

export default CheckboxInput;
