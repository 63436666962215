import { call, put, take, cancelled, cancel, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import healthCheckApi from '../../../api/healthCheck';
import {
  validateApi,
  invalidateApi,
  healthCheckStarted,
  STOP_HEALTH_CHECK,
  healthCheckStopped,
  FINISH_EDITING_CANCELLED,
} from '../../../ducks/view/view';
import { FETCH_DATA_SUCCESSED } from '../../../ducks/data/data';

export function* healthCheck() {
  try {
    yield call(healthCheckApi);
    yield put(validateApi());
  } catch (e) {
    yield put(invalidateApi(e));
  }
}

export function* runHealthCheck() {
  yield put(healthCheckStarted());
  try {
    while (true) {
      yield call(delay, 5000);
      yield call(healthCheck);
    }
  } finally {
    if (yield cancelled()) {
      yield put(healthCheckStopped());
    }
  }
}

export function* watchHealthCheck() {
  const runHealthCheckInstance = yield fork(runHealthCheck);
  yield take(STOP_HEALTH_CHECK);
  yield cancel(runHealthCheckInstance);
  yield fork(watchFinishEditingCancelled);
}

export function* watchFinishEditingCancelled() {
  yield take(FINISH_EDITING_CANCELLED);
  yield call(watchHealthCheck);
}

function* watchFirstFetchData() {
  yield take(FETCH_DATA_SUCCESSED);
  yield call(watchHealthCheck);
}

export default watchFirstFetchData;
