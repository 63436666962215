import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withTranslations from 'hoc/withTranslations/withTranslations';

import { sidebarToggled, checkRequiredFieldsRequested } from 'ducks/view/view';
import { getValidationSidebarData } from 'selectors/view';
import SidebarRouter from './SidebarRouter';

import SidebarGrid from './components/SidebarGrid';
import Sidebar from 'components/organisms/Sidebar/Sidebar';
import SectionProgressGrid from 'components/organisms/SectionProgressGrid/SectionProgressGrid';

const SidebarContainer = ({
  view,
  validationErrors,
  dispatchSidebarToggled,
  dispatchCheckRequiredFieldsRequested,
  t,
  ...props
}) => {
  const basePath = `/${props.match.params.uuid}`;
  return (
    <SidebarGrid open={view.sidebar.open}>
      <Sidebar
        open={view.sidebar.open}
        sidebarToggle={dispatchSidebarToggled}
        validateForm={dispatchCheckRequiredFieldsRequested}
        overallProgress={view.progress.overall}
        pathname={props.location.pathname}
        search={props.location.search}
        isValidating={view.validation.isFetching}
        basePath={basePath}
        t={{
          SECTION_PROGRESS: t('TEXT.SIDEBAR.SECTION_PROGRESS'),
          FINISH_EDITING: t('TEXT.SIDEBAR.FINISH_EDITING'),
          OVERALL_PROGRESS: t('TEXT.SIDEBAR.OVERALL_PROGRESS'),
          PERCENT_COMPLETE: t('TEXT.SIDEBAR.PERCENT_COMPLETE'),
          PROCESSING: t('TEXT.SIDEBAR.PROCESSING'),
        }}
      >
        <SectionProgressGrid
          {...view.progress.sections}
          pathname={props.location.pathname}
          search={props.location.search}
          basePath={basePath}
          validationErrors={validationErrors}
          t={{
            GETTING_IN_AND_OUT: t('TITLES.GETTING_IN_AND_OUT'),
            PROPERTY_DETAILS: t('TITLES.PROPERTY_DETAILS'),
            CLEANING_AND_MAINTENANCE: t('TITLES.CLEANING_AND_MAINTENANCE'),
            RULES_AND_ADDITIONAL_INFO: t('TITLES.RULES_AND_ADDITIONAL_INFO'),
            COMMUNICATION: t('TITLES.COMMUNICATION'),
            LISTING: t('TITLES.LISTING'),
            PROPERTY_PHOTOS: t('TITLES.PROPERTY_PHOTOS'),
            AMENITIES: t('TITLES.AMENITIES'),
            PERCENT_COMPLETE: t('TEXT.SIDEBAR.PERCENT_COMPLETE'),
            SECTION_ERROR: t('TEXT.SIDEBAR.SECTION_ERROR'),
            SECTION_ERROR_PLURAL: t('TEXT.SIDEBAR.SECTION_ERROR_PLURAL'),
          }}
        />
        <SidebarRouter {...props} t={t} />
      </Sidebar>
    </SidebarGrid>
  );
};

SidebarContainer.propTypes = {
  view: PropTypes.shape({
    sidebar: PropTypes.shape({ open: PropTypes.bool.isRequired }),
    progress: PropTypes.shape({ overall: PropTypes.number.isRequired, sections: PropTypes.object }),
    validation: PropTypes.shape({
      errors: PropTypes.object,
      isFetching: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  validationErrors: PropTypes.object,
  dispatchSidebarToggled: PropTypes.func.isRequired,
  dispatchCheckRequiredFieldsRequested: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }).isRequired,
  match: PropTypes.shape({ params: PropTypes.shape({ uuid: PropTypes.string.isRequired }) }).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  view: state.view.toJS(),
  validationErrors: getValidationSidebarData(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchSidebarToggled: sidebarToggled,
      dispatchCheckRequiredFieldsRequested: checkRequiredFieldsRequested,
    },
    dispatch,
  );

export const TSidebar = withTranslations(SidebarContainer);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TSidebar);
