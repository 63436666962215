import styled from 'styled-components';
import PropTypes from 'prop-types';

const GroupContainer = styled.div`
  margin-top: ${props => (props.label ? '1.5rem' : '0.5rem')};
  flex-grow: ${props => (props.size ? 0 : 1)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 41rem;
`;

GroupContainer.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};

GroupContainer.defaultProps = {
  size: 0,
  label: '',
};

export default GroupContainer;
