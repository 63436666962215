import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { H1, MediumText } from 'components/atoms/Typography/Typography';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

const ServerError = ({ t }) => (
  <Fragment>
    <H1 error>{t.header}</H1>
    <MediumText>
      {t.message1}
      <br />
      {t.message2}
    </MediumText>
  </Fragment>
);

ServerError.propTypes = {
  t: PropTypes.shape({
    header: PropTypes.string.isRequired,
    message1: PropTypes.string.isRequired,
    message2: PropTypes.string.isRequired,
  }).isRequired,
};

export default withMappedTranslations({
  header: 'SERVER_ERROR.HEADER',
  message1: 'SERVER_ERROR.MESSAGES_1',
  message2: 'SERVER_ERROR.MESSAGES_2',
})(ServerError);
