import styled from 'styled-components';

const ThumbnailGroup = styled.div`
  margin-left: -0.4rem;
  margin-right: -0.4rem;
  display: flex;
  flex-wrap: wrap;
`;

export default ThumbnailGroup;
