import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

import { ICONS } from '../../../constants';

const StyledIcon = styled(Icon)`
  justify-self: flex-end;
  cursor: pointer;
  path {
    fill: ${props => props.theme.colors.n800};
    transition: ${props => props.theme.transitions.fast};
  }
  &:hover {
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }
`;

const CloseButton = props => {
  return <StyledIcon className={props.className} clicked={props.clicked} size="30" icon={ICONS.CLOSE} />;
};

CloseButton.propTypes = {
  className: PropTypes.string,
  clicked: PropTypes.func,
};

export default CloseButton;
