import { Map, fromJS } from 'immutable';
import { put, takeEvery, call, all, select, fork } from 'redux-saga/effects';

import {
  AMENITIES_PHOTOS_ADDED,
  AMENITIES_PHOTO_REMOVED,
  PROPERTY_PHOTOS_ADDED,
  PROPERTY_PHOTO_UPDATED,
  PROPERTY_PHOTO_REMOVED,
  updateSpecialFieldFailed,
  updateSpecialFieldSuccessed,
} from 'ducks/form/form';

import { getFormFieldInfoJS } from 'selectors/form.js';

import DataApi from 'api/DataApi.js';
import { presignPicture, uploadPresignedPicture } from 'api/S3Api.js';

export function* addAmenityPhotos({ payload: { files, item } }) {
  const amenities = yield select(getFormFieldInfoJS, 'property_amenities');
  const amenity = amenities.value[item.id];
  const value = yield all(files.map(file => call(uploadAmenityPhoto, { file, amenity })));
  const updatedAmenities = fromJS(amenities.value).updateIn([item.id, 'pictures'], pictures =>
    pictures.concat(fromJS(value.filter(val => val))),
  );
  const mappedField = yield Map().setIn(['property_amenities', 'value'], updatedAmenities);
  yield put(updateSpecialFieldSuccessed(mappedField));
}

export function* uploadAmenityPhoto({ file, amenity }) {
  try {
    // upload picture to AWS
    const presigned = yield call(presignPicture, file);
    const url = yield call(uploadPresignedPicture, { data: presigned.data, file });

    // if the amenity has an ID, it has already been created, and we can safely add photos to it
    // if it has no ID, photos will be created at the same time as the amenity
    if (amenity.id) {
      const value = yield call([DataApi.apiInstance(), 'updateSpecialField'], `/property_amenity_pictures/`, {
        file: url,
        amenity_id: amenity.id,
      });
      return value;
    } else {
      return Map({ file: url, blob: file.blob });
    }
  } catch (e) {
    yield put(updateSpecialFieldFailed(e));
  }
}

export function* removeAmenityPhoto({ payload: { id } }) {
  if (id) {
    // TODO: add error handling & view update
    //  - at the moment the deleted photo is immediately removed from the view, regardless of the outcome here
    yield call([DataApi.apiInstance(), 'deleteSpecialField'], '/property_amenity_pictures', [id]);
  }
}

export function* uploadPropertyPhoto({ file }) {
  try {
    // upload picture to AWS
    const presigned = yield call(presignPicture, file);
    const url = yield call(uploadPresignedPicture, { data: presigned.data, file });
    const value = yield call([DataApi.apiInstance(), 'updateSpecialField'], `/property_photos/`, {
      file: url,
    });
    return value;
  } catch (e) {
    yield put(updateSpecialFieldFailed(e));
  }
}

export function* addPropertyPhotos({ payload: { files } }) {
  const uploadedPhotos = yield all(files.map(file => call(uploadPropertyPhoto, { file })));
  const existingPhotos = yield select(getFormFieldInfoJS, 'property_photos');
  const updatedPhotos = fromJS(existingPhotos.value).merge(
    fromJS(uploadedPhotos.filter(val => val).map(photo => [`${photo.id}`, photo])),
  );
  const mappedField = yield Map().setIn(['property_photos', 'value'], updatedPhotos);
  yield put(updateSpecialFieldSuccessed(mappedField));
}

export function* updatePropertyPhoto({ payload: { id, description } }) {
  if (!id) return;

  // TODO: add error handling & view update
  //  - at the moment the deleted photo is immediately removed from the view, regardless of the outcome here
  const photos = yield select(getFormFieldInfoJS, 'property_photos');
  const photo = fromJS(photos.value).get(`${id}`);

  if (photo.get('details') === description) return;

  const value = yield call(
    [DataApi.apiInstance(), 'updateSpecialField'],
    `/property_photos/${id}/`,
    { details: description },
    { method: 'put' },
  );

  const updatedPhotos = fromJS(photos.value).set(`${id}`, value);
  const mappedField = yield Map().setIn(['property_photos', 'value'], updatedPhotos);
  yield put(updateSpecialFieldSuccessed(mappedField));
}

export function* removePropertyPhoto({ payload: { id } }) {
  if (id) {
    // TODO: add error handling & view update
    //  - at the moment the deleted photo is immediately removed from the view, regardless of the outcome here
    yield call([DataApi.apiInstance(), 'deleteSpecialField'], '/property_photos', [id]);
  }
}

export function* watchAmenityPhotosAdded() {
  yield takeEvery(AMENITIES_PHOTOS_ADDED, addAmenityPhotos);
}

export function* watchAmenityPhotoRemoved() {
  yield takeEvery(AMENITIES_PHOTO_REMOVED, removeAmenityPhoto);
}

export function* watchPropertyPhotosAdded() {
  yield takeEvery(PROPERTY_PHOTOS_ADDED, addPropertyPhotos);
}

export function* watchPropertyPhotoUpdated() {
  yield takeEvery(PROPERTY_PHOTO_UPDATED, updatePropertyPhoto);
}

export function* watchPropertyPhotoRemoved() {
  yield takeEvery(PROPERTY_PHOTO_REMOVED, removePropertyPhoto);
}

export default function* watchPhotoFieldsUpdate() {
  yield all([
    fork(watchAmenityPhotosAdded),
    fork(watchAmenityPhotoRemoved),
    fork(watchPropertyPhotosAdded),
    fork(watchPropertyPhotoUpdated),
    fork(watchPropertyPhotoRemoved),
  ]);
}
