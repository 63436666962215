import { Map } from 'immutable';

export const getFormFieldInfo = (state, id) => state.form.getIn(id.split('.'));
export const getFormFieldInfoJS = (state, id) => getFormFieldInfo(state, id).toJS();
export const getForm = state => state.form;

export const getListingContentFieldLengths = (state, keys) =>
  state.form
    .getIn(['property_description', 'value'])
    .filter((val, key) => keys.indexOf(key) > -1)
    .map(val => (val ? val.length : 0));
export const getListingContentFieldLengthsJS = (state, keys) => getListingContentFieldLengths(state, keys).toJS();

export const getListingContentFieldsTotalLength = (state, keys) =>
  getListingContentFieldLengths(state, keys)
    .valueSeq()
    .reduce((total, val) => (total += val), 0);

export const getAmenityKey = (state, id) => {
  const view = state.form
    .getIn(['property_amenities', 'view'])
    .find(amenity => +amenity.get('id') === +id, null, Map({}));
  return view.get('key', null);
};

export const getAmenityItem = (state, key) => {
  const view = state.form.getIn(['property_amenities', 'view']).find(amenity => amenity.get('key') === key, null, null);
  if (!view) return null;
  const value = state.form
    .getIn(['property_amenities', 'value'])
    .find(amenity => +amenity.get('amenity_id') === +view.get('id'), null, null);
  return Map({ list: 'property_amenities', id: view.get('id'), value, category: view.get('category') });
};

export const getAmenityItemJS = (state, key) => {
  const item = getAmenityItem(state, key);
  return item && item.toJS();
};
