import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import view from './view/view';
import data from './data/data';
import form from './form/form';

export default history =>
  combineReducers({
    router: connectRouter(history),
    data,
    view,
    form,
  });
