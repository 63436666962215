import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getViewInfoJS } from 'selectors/view';

import {
  FINISH_EDITING_REQUESTED,
  finishEditingSuccessed,
  finishEditingFailed,
  stopHealthCheck,
} from 'ducks/view/view';
import DataApi from 'api/DataApi';

export function* finishEditing() {
  try {
    const { uuid } = yield select(getViewInfoJS);
    yield put(stopHealthCheck());
    yield call([DataApi.apiInstance(), 'finishForm']);
    yield put(push(`/${uuid}/finished`));
    yield put(finishEditingSuccessed());
  } catch (e) {
    yield put(finishEditingFailed(e));
  }
}

function* watchEditing() {
  yield takeLatest(FINISH_EDITING_REQUESTED, finishEditing);
}

export default watchEditing;
