import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import * as Sentry from '@sentry/react';

import { codeCrashed } from 'ducks/view/view';
import createRootReducer from 'ducks';
import rootSaga from 'sagas';

const initialState = {};
const enhancers = [];
const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();
const middleware = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  }

  middleware.push(createLogger());
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const store = createStore(createRootReducer(history), initialState, composedEnhancers);

sagaMiddleware.run(rootSaga).done.catch(error => {
  store.dispatch(codeCrashed());
  Sentry.captureException(error);
});

export default store;
