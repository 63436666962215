import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Input from '../../atoms/Input/Input';
import AmenityIcon from 'components/molecules/AmenityInput/components/AmenityIcon';
import Wrapper from 'components/molecules/AmenityInput/components/Wrapper';
import PhotoUpload from 'components/molecules/PhotoUpload/PhotoUpload';

const StyledInput = styled(Input)`
  margin-bottom: 0.25rem;
  height: auto;
  min-height: auto;
  flex-shrink: 0;
`;

const InputGroup = styled.div`
  margin: 0 -0.125rem;

  ${props => props.theme.media.mobileSmall`
    margin: 0;
  `};

  input {
    max-width: calc((20rem / 2) - 0.125rem);
    margin: 0 0.125rem 0.25rem;

    ${props => props.theme.media.mobileSmall`
      width: 100%;
      margin: 0 0 0.25rem;
    `};
  }
`;

const withDisabledStyle = ({ disabled }) =>
  disabled &&
  css`
    height: 7.5rem;
    overflow: hidden;
    position: relative;
    &::after {
      content: '';
      display: inline-block;
      height: 50%;
      width: 100%;
      background: ${props => `linear-gradient(transparent, ${props.theme.colors.white})`};
      position: absolute;
      left: 0;
      bottom: 0;
    }
  `;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${withDisabledStyle};
`;

const AmenityInput = ({
  inline,
  view,
  value,
  list,
  onChange,
  handleToggle,
  handleUpload,
  handleDelete,
  warning,
  t,
}) => {
  const kinds = view.kinds.map(kind => ({ label: kind.name, value: kind.id }));
  const showPictures = view.show_pictures;
  const hasDetails = kinds.length || view.show_detailed_fields || showPictures;

  let optionsInput = kinds.length > 0 && (
    <StyledInput
      disabled={value.disabled}
      type={kinds.length > 3 ? 'select' : 'radio'}
      value={value.type_selected_id}
      t={{
        placeholder: t.selectType,
        noResultsText: t.noResults,
      }}
      options={kinds}
      name={`${list}.value.${view.id}.type_selected_id`}
      onClick={value => onChange({ target: { name: `${list}.value.${view.id}.type_selected_id`, value: value } })}
      onChange={onChange}
    />
  );
  const detailsInput = (
    <StyledInput
      disabled={value.disabled}
      type="textarea"
      value={value.details}
      name={`${list}.value.${view.id}.details`}
      onChange={onChange}
      placeholder={t.details}
    />
  );
  const locationInput = (
    <StyledInput
      disabled={value.disabled}
      type="textarea"
      value={value.location}
      name={`${list}.value.${view.id}.location`}
      onChange={onChange}
      placeholder={t.location}
    />
  );
  const serialInput = (
    <StyledInput
      disabled={value.disabled}
      value={value.model}
      name={`${list}.value.${view.id}.model`}
      onChange={onChange}
      placeholder={t.model}
    />
  );
  const brandInput = (
    <StyledInput
      disabled={value.disabled}
      value={value.brand}
      name={`${list}.value.${view.id}.brand`}
      onChange={onChange}
      placeholder={t.brand}
    />
  );
  return (
    <Wrapper hasRadioButtons={kinds.length > 0} hasRightCol={hasDetails}>
      <AmenityIcon
        inline={inline}
        list={list}
        value={value}
        view={view}
        handleToggle={handleToggle}
        warning={warning}
      />
      {hasDetails && (
        <InputWrapper disabled={value.disabled}>
          {optionsInput}
          {view.show_detailed_fields && (
            <Fragment>
              <InputGroup>
                {serialInput}
                {brandInput}
              </InputGroup>
              {locationInput}
              {detailsInput}
            </Fragment>
          )}
          {showPictures && (
            <PhotoUpload
              t={t}
              value={value.pictures}
              handleUpload={handleUpload}
              handleDelete={handleDelete}
              name={`${list}.value.${view.id}.pictures`}
              onClick={value => onChange({ target: { name: `${list}.value.${view.id}.pictures`, value } })}
            />
          )}
        </InputWrapper>
      )}
    </Wrapper>
  );
};

AmenityInput.propTypes = {
  view: PropTypes.shape({
    id: PropTypes.number,
    icon: PropTypes.string,
    name: PropTypes.string,
    show_detailed_fields: PropTypes.bool,
    show_pictures: PropTypes.bool,
    kinds: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      }),
    ),
  }).isRequired,
  value: PropTypes.shape({
    details: PropTypes.string,
    brand: PropTypes.string,
    location: PropTypes.string,
    model: PropTypes.string,
    amenity_id: PropTypes.string,
    rental_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.number,
    type_selected_id: PropTypes.number,
    disabled: PropTypes.bool,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
  t: PropTypes.shape({
    noResults: PropTypes.string,
    details: PropTypes.string,
    brand: PropTypes.string,
    location: PropTypes.string,
    model: PropTypes.string,
    selectType: PropTypes.string,
    uploading: PropTypes.string,
    deleting: PropTypes.string,
    uploadPhotoPrompt: PropTypes.string,
    uploadHint: PropTypes.string,
    video: PropTypes.string,
    fileFormatError: PropTypes.string,
  }),
  list: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleUpload: PropTypes.func,
  handleDelete: PropTypes.func,
  warning: PropTypes.string,
  inline: PropTypes.bool,
};

AmenityInput.defaultProps = {
  value: {
    details: '',
    brand: '',
    model: '',
    location: '',
    disabled: true,
    type_selected_id: null,
    pictures: [],
    id: null,
  },
  inline: false,
  t: {},
};

export default AmenityInput;
