import { fromJS } from 'immutable';
import { AMENITY_CATEGORIES } from 'constants.js';

export const conditionalFields = fromJS({
  propertyDetails: {
    'property_has_wifi.value': {
      conditions: [
        {
          fieldNames: ['property_nom_wifi.value', 'property_wifi_code.value'],
          visibleForValues: ['yes'],
        },
      ],
    },
  },
  checkInOut: {
    'property_has_intercom.value': {
      conditions: [
        {
          fieldNames: ['property_intercom.value'],
          visibleForValues: ['yes'],
        },
      ],
    },
    'property_padlock_possible.value': {
      conditions: [
        {
          fieldNames: ['property_padlock_location.value'],
          visibleForValues: ['yes'],
        },
      ],
    },
  },
  additionalInfo: {
    'property_extra_information_letterbox_access.value': {
      conditions: [
        {
          fieldNames: ['property_indication_mailbox.value'],
          visibleForValues: ['true'],
        },
      ],
    },
  },
});

export const specialFields = fromJS({
  propertyDetails: [
    {
      fields: ['property_bedrooms.value', 'property_common_space'],
      mandatoryAttributes: ['bed_set'],
      type: 'bedroom',
    },
    {
      fields: ['property_bathrooms.value'],
      optionalAttributes: [
        'shower',
        'hairdryer',
        'bath',
        'toilet',
        'hair_dryer',
        'jacuzzi',
        'mirror',
        'spa_bath',
        'toothbrush_holder',
        'towel_warmer',
        'wastebasket',
      ],
      type: 'bathroom',
    },
  ],
  amenities: [
    {
      categories: [
        AMENITY_CATEGORIES.COOKING_AND_DINING,
        AMENITY_CATEGORIES.CLEANING,
        AMENITY_CATEGORIES.ENTERTAINMENT,
        AMENITY_CATEGORIES.GENERAL,
        AMENITY_CATEGORIES.HEATING_AND_COOLING,
        AMENITY_CATEGORIES.SECURITY,
      ],
      fieldName: 'property_amenities.value',
      type: 'amenity',
    },
  ],
  propertyPhotos: [
    {
      fieldName: 'property_photos.value',
      type: 'propertyPhotos',
    },
  ],
});

export default fromJS({
  checkInOut: [
    'property_address.value',
    'property_code_postal.value',
    'property_etage.value',
    'property_info_location.value',
    'property_access_badge.value',
    'property_building_key.value',
    'property_lift.value',
    'property_has_intercom.value',
    'property_extra_information_alarm_location.value',
    'property_padlock_possible.value',
    'property_doors_lock_when_closed.value',
    'property_indication_depot_clef.value',
    'property_all_keys_tested.value',
    'property_metro_station.value',
    'property_parking.value',
    'property_parking_badge.value',
    'property_indication_parking.value',
  ],
  propertyDetails: [
    'property_logement_type_id.value',
    'property_property_type.value',
    'property_surface_area.value',
    'property_license_number.value',
    'property_sleeps.value',
    'property_has_wifi.value',
    'property_extra_information_pillows_for_all_beds.value',
    'property_air_conditioning_supplier_details.value',
    'property_bills_and_services_up_to_date.value',
    'property_gas_supplier_details.value',
    'property_is_maintenance_allowed.value',
    'property_access_utilities.value',
  ],
  cleaning: [
    'property_extra_information_cleaning_tools_location.value',
    'property_extra_information_cleaning_products_location.value',
    'property_indication_trash.value',
    'property_extra_information_extra_linen_place.value',
    'property_extra_information_preferred_maintenance.value',
    'property_extra_information_switches_location.value',
    'property_extra_information_boiler_location.value',
    'property_extra_information_heating_control_location.value',
  ],
  additionalInfo: [
    'property_house_rules.value',
    'property_extra_information_children_friendly.value',
    'property_extra_information_infant_friendly.value',
    'property_baby_policy.value',
    'property_extra_information_letterbox_access.value',
  ],
  communication: [
    'property_permanent_check_in_internal_comment.value',
    'property_indication_welcome.value',
    'property_indication_menage.value',
    'property_retour_proprietaire_texte.value',
    'property_indication_checkout.value',
  ],
  listing: [
    'property_description.value.headline',
    'property_description.value.summary',
    'property_description.value.space',
    'property_description.value.access',
    'property_description.value.neighborhood',
    'property_description.value.transit',
  ],
});
