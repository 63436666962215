import { lifecycle, compose } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateSpecialFieldsRequested } from '../../ducks/form/form';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchUpdateSpecialFieldsRequested: updateSpecialFieldsRequested }, dispatch);

const withComponentWillUnmount = fields =>
  compose(
    connect(
      null,
      mapDispatchToProps,
    ),
    lifecycle({
      componentWillUnmount() {
        this.props.dispatchUpdateSpecialFieldsRequested(fields);
      },
    }),
  );

export default fields => withComponentWillUnmount(fields);
