import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  min-height: min-content;
  height: calc(100vh - 11.125rem);
  grid-template-rows: 1fr repeat(3, auto) minmax(auto, 9.5rem) 2fr;
  margin: 5.5625rem;
  ${props => props.theme.media.tablet`
    margin: 2rem;
  `};
  ${props => props.theme.media.mobile`
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  `};
`;

export default Wrapper;
