import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.n200};
  height: 0.5rem;
  width: 100%;
  overflow: hidden;
`;

const Bar = styled.div`
  height: 0;
  background-color: ${props => props.theme.colors.primary};
  border: 0.25rem solid ${props => props.theme.colors.primary};
  width: ${props => `${Math.round(props.progress * 100)}%`};
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: 0;
  box-sizing: content-box;
  transition: ${props => props.theme.transitions.default};
`;

const ProgressBar = props => {
  const bar = props.progress > 0 && <Bar progress={props.progress} />;
  return <Wrapper>{bar}</Wrapper>;
};

ProgressBar.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ProgressBar;
