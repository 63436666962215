import styled from 'styled-components';
import { ButtonWrapper } from 'components/atoms/ButtonGroup/ButtonGroup';

const PageNavigation = styled(ButtonWrapper)`
  grid-area: navigation;
  text-align: right;
  ${props => props.theme.media.tablet`
    padding-right: 1rem;
  `};
  ${props => props.theme.media.mobile`
    padding-right: 0;
  `};
  ${props => props.theme.media.mobileSmall`
    text-align: center;
  `};
`;

export default PageNavigation;
