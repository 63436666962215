import { Map, List, fromJS } from 'immutable';
import { getNextAvailableBedType, getRemovedBedTypeIndex } from 'utils/bedrooms';
import { mergeDeepOverwriteArrays } from 'utils/immutable';
import { VALIDATION_MAP_SUCCESSED } from '../view/view';

export const DATA_MAP_SUCCESSED = 'DATA_MAP_SUCCESSED';
export const FORM_FIELD_CHANGED = 'FORM_FIELD_CHANGED';
export const UPDATE_FIELD_REQUESTED = 'UPDATE_FIELD_REQUESTED';
export const UPDATE_FIELD_FAILED = 'UPDATE_FIELD_FAILED';
export const UPDATE_SPECIAL_FIELDS_REQUESTED = 'UPDATE_SPECIAL_FIELDS_REQUESTED';
export const UPDATE_SPECIAL_FIELD_SUCCESSED = 'UPDATE_SPECIAL_FIELD_SUCCESSED';
export const UPDATE_SPECIAL_FIELD_FAILED = 'UPDATE_SPECIAL_FIELD_FAILED';
export const DELETE_SPECIAL_FIELD_SUCCESSED = 'DELETE_SPECIAL_FIELD_SUCCESSED';
export const DELETE_SPECIAL_FIELD_FAILED = 'DELETE_SPECIAL_FIELD_FAILED';
export const AMENITIES_ITEM_ADDED = 'AMENITIES_ITEM_ADDED';
export const AMENITIES_ITEM_REMOVED = 'AMENITIES_ITEM_REMOVED';
export const AMENITIES_ITEM_UPDATED = 'AMENITIES_ITEM_UPDATED';
export const AMENITIES_PHOTO_ADDED = 'AMENITIES_PHOTO_ADDED';
export const AMENITIES_PHOTOS_ADDED = 'AMENITIES_PHOTOS_ADDED';
export const AMENITIES_PHOTO_REMOVED = 'AMENITIES_PHOTO_REMOVED';
export const AMENITIES_PHOTO_UPLOAD_SUCCESSED = 'AMENITIES_PHOTO_UPLOAD_SUCCESSED';
export const BATHROOM_ADDED = 'BATHROOMS_ADDED';
export const BATHROOM_REMOVED = 'BATHROOMS_REMOVED';
export const BEDROOM_ADDED = 'BEDROOM_ADDED';
export const BEDROOM_REMOVED = 'BEDROOM_REMOVED';
export const BED_ADDED = 'BED_ADDED';
export const BED_REMOVED = 'BED_REMOVED';
export const BED_COUNT_CHANGED = 'BED_COUNT_CHANGED';
export const BED_TYPE_ADDED = 'BED_TYPE_ADDED';
export const BED_TYPE_REMOVED = 'BED_TYPE_REMOVED';
export const PROPERTY_PHOTOS_ADDED = 'PROPERTY_PHOTOS_ADDED';
export const PROPERTY_PHOTO_REMOVED = 'PROPERTY_PHOTO_REMOVED';
export const PROPERTY_PHOTO_UPDATED = 'PROPERTY_PHOTO_UPDATED';
export const FILES_ADDED = 'FILES_ADDED';
export const FILE_REMOVED = 'FILE_REMOVED';

export function dataMapSucessed(data) {
  return {
    type: DATA_MAP_SUCCESSED,
    payload: { data },
  };
}

export function updateFieldFailed(error) {
  return {
    type: UPDATE_FIELD_FAILED,
    payload: { error },
  };
}

export function updateFieldRequested(field, options = {}) {
  return {
    type: UPDATE_FIELD_REQUESTED,
    payload: { field, options },
  };
}

export function formFieldChanged(field) {
  return {
    type: FORM_FIELD_CHANGED,
    payload: { field },
  };
}

export function amenitiesItemAdded(item) {
  return {
    type: AMENITIES_ITEM_ADDED,
    payload: { item },
  };
}

export function amenitiesItemRemoved(item) {
  return {
    type: AMENITIES_ITEM_REMOVED,
    payload: { item },
  };
}

export function amenitiesItemUpdated(item) {
  return {
    type: AMENITIES_ITEM_UPDATED,
    payload: { item },
  };
}

export function amenitiesPhotoAdded({ file, item }) {
  return {
    type: AMENITIES_PHOTO_ADDED,
    payload: { file, item },
  };
}

export function amenitiesPhotosAdded({ files, item }) {
  return {
    type: AMENITIES_PHOTOS_ADDED,
    payload: { files, item },
  };
}

export function amenitiesPhotoRemoved({ file, id, item }) {
  return {
    type: AMENITIES_PHOTO_REMOVED,
    payload: { file, id, item },
  };
}

export function amenitiesPhotoUploadSuccessed({ url, item }) {
  return {
    type: AMENITIES_PHOTO_UPLOAD_SUCCESSED,
    payload: { url, item },
  };
}

export function propertyPhotosAdded(files) {
  return {
    type: PROPERTY_PHOTOS_ADDED,
    payload: { files },
  };
}

export function propertyPhotoRemoved({ file, id }) {
  return {
    type: PROPERTY_PHOTO_REMOVED,
    payload: { file, id },
  };
}

export function propertyPhotoUpdated({ id, description }) {
  return {
    type: PROPERTY_PHOTO_UPDATED,
    payload: { id, description },
  };
}

export function filesAdded({ files, category }) {
  return {
    type: FILES_ADDED,
    payload: { files, category },
  };
}

export function fileRemoved({ file, id }) {
  return {
    type: FILE_REMOVED,
    payload: { file, id },
  };
}

export function bathroomRemoved() {
  return { type: BATHROOM_REMOVED };
}

export function bathroomAdded() {
  return { type: BATHROOM_ADDED };
}

export function bedAdded(field) {
  return { type: BED_ADDED, payload: { field } };
}

export function bedRemoved(field) {
  return { type: BED_REMOVED, payload: { field } };
}

export function bedCountChanged({ count, fieldName }) {
  return { type: BED_COUNT_CHANGED, payload: { count, fieldName } };
}

export function bedTypeAdded(field) {
  return { type: BED_TYPE_ADDED, payload: { field } };
}

export function bedTypeRemoved(field) {
  return { type: BED_TYPE_REMOVED, payload: { field } };
}

export function bedroomAdded() {
  return { type: BEDROOM_ADDED };
}

export function bedroomRemoved() {
  return { type: BEDROOM_REMOVED };
}

export function updateSpecialFieldsRequested(fields) {
  return {
    type: UPDATE_SPECIAL_FIELDS_REQUESTED,
    payload: { fields },
  };
}

export function updateSpecialFieldSuccessed(field) {
  return {
    type: UPDATE_SPECIAL_FIELD_SUCCESSED,
    payload: { field },
  };
}

export function updateSpecialFieldFailed(error) {
  return {
    type: UPDATE_SPECIAL_FIELD_FAILED,
    payload: { error },
  };
}

export function deleteSpecialFieldSuccessed(fieldName) {
  return {
    type: DELETE_SPECIAL_FIELD_SUCCESSED,
    payload: { fieldName },
  };
}

export function deleteSpecialFieldFailed(error) {
  return {
    type: DELETE_SPECIAL_FIELD_FAILED,
    payload: { error },
  };
}

export default (state = Map({}), { type, payload }) => {
  switch (type) {
    case DATA_MAP_SUCCESSED:
      return state.merge(payload.data);

    case AMENITIES_ITEM_ADDED: {
      const item = Map(state.getIn([`${payload.item.list}_removed`, payload.item.id], {}));
      return state
        .setIn(
          [payload.item.list, 'value', payload.item.id],
          item
            .mergeDeep(payload.item.value)
            .set('amenity_id', payload.item.id)
            .set('disabled', false)
            .set('rental_id', state.getIn(['property_id', 'value']))
            .set('category', payload.item.category),
        )
        .deleteIn([`${payload.item.list}_removed`, payload.item.id]);
    }

    case AMENITIES_ITEM_REMOVED: {
      const item = state.getIn([`${payload.item.list}`, 'value', payload.item.id]);
      return state
        .setIn([`${payload.item.list}_removed`, payload.item.id], item)
        .deleteIn([payload.item.list, 'value', payload.item.id]);
    }

    case AMENITIES_ITEM_UPDATED:
      return state.setIn(payload.item.name.split('.'), payload.item.value);

    case AMENITIES_PHOTO_REMOVED:
      return state.updateIn(['property_amenities', 'value', payload.item.id, 'pictures'], value =>
        value.filterNot(item => item.get('file') === payload.file),
      );

    case PROPERTY_PHOTO_REMOVED:
      return state.deleteIn(['property_photos', 'value', `${payload.id}`]);

    case FILE_REMOVED:
      return state.deleteIn(['property_documents', 'value', `${payload.id}`]);

    case FORM_FIELD_CHANGED: {
      let nextState = state;
      const { fieldType, fieldName } = payload.field;
      let { fieldValue } = payload.field;
      const path = fieldName.split('.');

      if (
        fieldName.match(/\.count/g) &&
        (fieldType === 'beds' || !nextState.getIn(['property_bedrooms', 'value']).size)
      ) {
        fieldValue = fieldValue || 1;
      }

      // remove field errors if they exist
      nextState = nextState.update(path[0], field => field && field.delete('errors'));

      return nextState.setIn(path, fieldValue);
    }

    case BATHROOM_ADDED: {
      const removedPath = 'property_bathrooms_removed';
      const count = state.getIn(['property_bathrooms', 'value']).size + 1;
      return state
        .setIn(['property_bathrooms_count', 'value'], count)
        .updateIn(['property_bathrooms', 'value'], value =>
          value.push(
            state.get(removedPath).last() ||
              Map({
                amenities: Map(
                  state.getIn(['property_bathrooms', 'view']).amenities.map(amenity => [amenity.toLowerCase(), false]),
                ).update('id', (value = null) => value),
                label: `Bathroom ${count}`,
              }),
          ),
        )
        .update(removedPath, value => value.pop());
    }

    case BATHROOM_REMOVED: {
      const bathrooms = state.getIn(['property_bathrooms', 'value']);
      return state
        .setIn(['property_bathrooms_count', 'value'], bathrooms.size - 1)
        .updateIn(['property_bathrooms', 'value'], value => value.pop())
        .update('property_bathrooms_removed', value => value.push(bathrooms.last()));
    }

    case BED_TYPE_ADDED: {
      const removedPath = 'property_beds_removed';
      const path = List(payload.field.fieldName.split('.'));
      const bedSetPath = [...path.toArray(), 'bed_set'];
      const bedroom = state.getIn(path.toArray());
      const nextAvailableBedType = getNextAvailableBedType({
        bed_types: state.getIn(['property_bedrooms', 'view', 'bed_types']),
        bed_set: state.getIn(bedSetPath),
      });
      const lastRemovedBedIndex = state
        .get(removedPath)
        .findLastIndex(bed => bed.get('bedroom_id') === bedroom.get('id'));

      if (lastRemovedBedIndex > -1) {
        return state
          .updateIn(bedSetPath, value => value.push(state.getIn([removedPath, lastRemovedBedIndex])))
          .removeIn([removedPath, lastRemovedBedIndex]);
      } else {
        return state.updateIn(bedSetPath, value =>
          value.push(Map({ bedroom_id: bedroom.get('id'), bed_type: nextAvailableBedType, beds: List([null]) })),
        );
      }
    }

    case BED_TYPE_REMOVED: {
      const removedPath = 'property_beds_removed';
      const path = List(payload.field.fieldName.split('.'));
      const bedSetPath = [...path.toArray(), 'bed_set'];
      const removedBedType = state.getIn(bedSetPath).last();
      return state
        .updateIn(bedSetPath, value => value.pop())
        .update(removedPath, value => (value || List([])).push(removedBedType));
    }

    case BED_COUNT_CHANGED: {
      const removedPath = 'property_beds_removed';
      const removedBeds = state.get('property_beds_removed');
      const path = List(payload.fieldName.split('.'));
      const bed_type = state.getIn(path.toArray());
      const beds = bed_type.get('beds');
      const bedsToAdd = payload.count - beds.size;
      const removedBedTypeIndex = getRemovedBedTypeIndex({ removedBeds, bed_type });
      let returnState = state;

      if (bedsToAdd > 0) {
        if (removedBedTypeIndex > -1) {
          const removedBedsToReAdd = state.get(removedPath).get(removedBedTypeIndex);

          returnState = returnState
            .updateIn(path.concat('beds').toArray(), value =>
              value.concat(removedBedsToReAdd.get('beds').slice(0, bedsToAdd)),
            )
            .update(removedPath, removedBeds => {
              if (removedBeds.getIn([removedBedTypeIndex, 'beds']).slice(bedsToAdd).size) {
                return removedBeds.updateIn([removedBedTypeIndex, 'beds'], beds => beds.slice(bedsToAdd));
              } else {
                return removedBeds.remove(removedBedTypeIndex);
              }
            });
        }
      }

      if (bedsToAdd < 0) {
        const removedBedsWithIDs = beds.slice(payload.count).filter(bed => bed);

        if (removedBedsWithIDs.size) {
          returnState = returnState.update(removedPath, value =>
            (value || List()).push(
              Map({
                bed_type: bed_type.get('bed_type'),
                bedroom_id: bed_type.get('bedroom_id'),
                beds: removedBedsWithIDs,
              }),
            ),
          );
        }
      }

      returnState = returnState.updateIn(path.concat('beds').toArray(), value => value.setSize(payload.count));

      return returnState;
    }

    case BEDROOM_ADDED: {
      const path = ['property_bedrooms', 'value'];
      const removedPath = 'property_bedrooms_removed';
      const defaultBedType = state.getIn(['property_bedrooms', 'view', 'bed_types', 0]);

      return state
        .updateIn(path, value =>
          value
            .push(
              state.get(removedPath).last() ||
                fromJS({
                  id: null,
                  bedroom_type: 'BEDROOM',
                  bed_set: [
                    {
                      beds: [null],
                      bed_type: defaultBedType,
                      bedroom_id: null,
                    },
                  ],
                }),
            )
            .sortBy(value => value.get('bedroom_type')),
        )
        .update(removedPath, value => value.pop());
    }

    case BEDROOM_REMOVED: {
      const path = ['property_bedrooms', 'value'];
      return state
        .update('property_bedrooms_removed', value => {
          const bedroom = state.getIn(path).last();
          if (bedroom.get('id')) {
            return value.push(bedroom);
          }
          return value;
        })
        .updateIn(path, value => value.pop());
    }

    case UPDATE_SPECIAL_FIELD_SUCCESSED: {
      return mergeDeepOverwriteArrays(state, payload.field);
    }

    case DELETE_SPECIAL_FIELD_SUCCESSED:
      return state.setIn(payload.fieldName.split('.'), (payload.fieldName.match(/amenities/g) && Map({})) || List([]));

    case VALIDATION_MAP_SUCCESSED: {
      const cleanedState = state.map(field => (Map.isMap(field) ? field.delete('errors') : field));
      return mergeDeepOverwriteArrays(cleanedState, payload.fieldErrors);
    }

    default:
      return state;
  }
};
