import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ICONS } from '../../../constants';

import Icon from '../../atoms/Icon/Icon';

const HintIconWrapper = styled.span`
  box-shadow: ${props => props.theme.shadows.default};
  border-radius: 50%;
  background: white;
  z-index: 2;
  cursor: pointer;
  outline: none;
  display: inline-block;
`;

const HintIcon = styled(Icon)`
  path {
    transition: ${props => props.theme.transitions.fast};
    fill: ${props => (props.open ? props.theme.colors.primary : props.theme.colors.n800)};
  }
`;

const HintWrapper = styled.div`
  position: relative;
  padding-left: 1rem;
  line-height: 0;
  max-width: 23.25rem;
  flex-grow: 1;
`;

const HintDescription = styled.div`
  width: 20rem;
  position: absolute;
  z-index: 1;
  right: 0;
  background: white;
  border-radius: 0.375rem;
  box-shadow: ${props => props.theme.shadows.default};
  transition: ${props => props.theme.transitions.fast};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  opacity: ${props => (props.open ? '1' : '0')};
  padding: 1rem;
  margin: 0.4rem 0.5625rem 1rem;
  color: ${props => props.theme.colors.n800};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

const Hint = ({ children, type, inputFocused }) => {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    setOpen(inputFocused);
  }, [inputFocused]);
  const handleMouseDown = e => {
    e.preventDefault();
    setOpen(value => !value);
    setHovered(false);
  };
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <HintWrapper>
      <HintIconWrapper
        open={open || hovered}
        tabIndex="0"
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <HintIcon
          icon={type === 'hint' ? ICONS.QUESTION_MARK : ICONS.EXCLAMATION_MARK}
          size="20"
          open={open || hovered}
        />
      </HintIconWrapper>
      <HintDescription open={open || hovered}>{children}</HintDescription>
    </HintWrapper>
  );
};

Hint.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  handleClick: PropTypes.func,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  hovered: PropTypes.bool,
  open: PropTypes.bool,
  type: PropTypes.oneOf(['warning', 'hint']),
  inputFocused: PropTypes.bool,
};

Hint.defaultProps = {
  hovered: false,
  open: false,
  type: 'hint',
  inputFocused: false,
};

export default Hint;
