import { put, call, select } from 'redux-saga/effects';

import { updateFieldFailed } from 'ducks/form/form';
import { updateDataFieldSuccessed, fetchDataRequested } from 'ducks/data/data';
import mapField from './fieldMapper/fieldMapper';
import { getFormFieldInfoJS } from 'selectors/form.js';
import { getDataFieldValue } from 'selectors/data.js';

import DataApi from 'api/DataApi.js';

export function* updateFormField({
  payload: {
    field: { name, type },
    options = {},
  },
}) {
  try {
    const fieldName = name.split('.')[0];
    const formFieldInfoJS = yield select(getFormFieldInfoJS, fieldName);
    const dataFieldValue = yield select(getDataFieldValue, formFieldInfoJS);
    const mappedField = yield call(mapField, type, formFieldInfoJS);
    if (mappedField.value === dataFieldValue) return;
    if (type !== 'frontend-only') {
      yield call([DataApi.apiInstance(), 'updateField'], { data: mappedField });
    }
    yield put(updateDataFieldSuccessed(mappedField));
    if (options.refresh) {
      yield put(fetchDataRequested());
    }
  } catch (e) {
    yield put(updateFieldFailed(e));
  }
}
