import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const StyledButton = styled.a`
  min-height: 2.25rem;
  min-width: 9.5625rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  margin: 0.35rem;
  appearance: none;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.n100};
  border-radius: 0.4rem;
  border: none;
  font-weight: 400;
  letter-spacing: 0.02rem;
  font-size: 0.875rem;
  cursor: pointer;
  user-select: none;
  transition: ${props => props.theme.transitions.fast};
  transform: translate3d(0, 0, 0);
  text-shadow: ${props => props.theme.shadows.small};
  &:hover:not([disabled]) {
    background-color: ${props => props.theme.colors.primary};
    transform: translate3d(0, -0.1rem, 0);
  }
  &:focus {
    outline: none;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: inherit;
    pointer-events: none;
  }
`;

export const StyledSecondaryButton = styled(StyledButton)`
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  background: none;
  text-shadow: none;
  &:hover:not([disabled]) {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }
  &[disabled] {
    background: none;
    opacity: 0.5;
  }
`;

export const StyledSecondaryGreyButton = styled(StyledSecondaryButton)`
  border-color: ${props => props.theme.colors.n800};
  color: ${props => props.theme.colors.n800};
  text-shadow: none;
  &:hover:not([disabled]) {
    background-color: ${props => props.theme.colors.n800};
  }
`;

export const StyledTertiaryButton = styled(StyledButton)`
  background: none;
  border: none;
  border-radius: 0;
  min-height: auto;
  min-width: auto;
  display: inline;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
  text-shadow: none;
  color: ${props => (props.type === 'tertiary' ? props.theme.colors.n800 : props.theme.colors.textSecondary)};
  font-weight: bold;
  &:hover:not([disabled]) {
    background: none;
    color: ${props => props.theme.colors.primary};
    transform: none;
  }
  &[disabled] {
    background: none;
    opacity: 0.5;
  }
`;

const Button = props => {
  switch (props.type) {
    case 'secondary':
      return <StyledSecondaryButton {...props}>{props.children}</StyledSecondaryButton>;
    case 'secondary-grey':
      return <StyledSecondaryButton {...props}>{props.children}</StyledSecondaryButton>;
    case 'tertiary':
    case 'tertiary-light':
      return <StyledTertiaryButton {...props}>{props.children}</StyledTertiaryButton>;
    default:
      return <StyledButton {...props}>{props.children}</StyledButton>;
  }
};

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'secondary-grey', 'tertiary', 'tertiary-light']),
};

Button.defaultProps = {
  disabled: false,
};

export default Button;
