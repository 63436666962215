export const _turquoise = {
  t100: '#EAFAFC',
  t300: '#92E4ED',
  t500: '#2CCADD',
  t700: '#0E8194',
  t800: '#0C6475',
  t900: '#002C31',
};

export const _blue = {
  b100: '#EAEFFE',
  b200: '#BBC8FC',
  b300: '#97A8F6',
  b400: '#7B8EED',
  b500: '#5167E2',
  b600: '#3B4DC2',
  b700: '#2837A2',
  b800: '#192483',
  b900: '#0F176C',
};

export const _red = {
  r100: '#FEECEC',
  r300: '#FC9494',
  r500: '#F16664',
  r700: '#CD4543',
  r800: '#8c0402',
  r900: '#320302',
};

export const _green = {
  g100: '#DCFBEC',
  g300: '#8CE5BF',
  g500: '#3EAF7B',
  g700: '#1A845E',
  g900: '#013C1A',
};

export const _yellow = {
  y100: '#FFF2E2',
  y300: '#FFD39E',
  y500: '#FFB400',
  y700: '#A1680C',
  y900: '#362600',
};

export const _orange = {
  o100: '#FEEDD5',
  o200: '#FED6AC',
  o300: '#FCB982',
  o400: '#F99C62',
  o500: '#F66F30',
  o600: '#D34F23',
  o700: '#B13418',
  o800: '#8E1E0F',
  o900: '#760F09',
};

export const _purple = {
  p100: '#F7E6FE',
  p200: '#D6ACFA',
  p300: '#B880F1',
  p400: '#9B5EE3',
  p500: '#722ED1',
  p600: '#5821B3',
  p700: '#411796',
  p800: '#2D0E79',
  p900: '#1F0864',
};

export const _neutral = {
  n100: '#f9fafb',
  n200: '#EEF0F4',
  n300: '#cfd0d5',
  n400: '#C0C1C6',
  grey: '#BBBBBB',
  bsgr: '#828282',
  n500: '#868a96',
  n600: '#656A76',
  n700: '#545862',
  quar: '#4a4a4a',
  n800: '#3d3f46',
  n900: '#222428',
};

export const blurOverlay = 'rgba(216, 216, 216, 0.6)';

export const white = '#FCFCFC';
export const black = '#0E0E10';
export const dark = _neutral.n700;
export const primary = '#4B6CCC';
export const textPrimary = '#283755';
export const secondary = dark;
export const textSecondary = '#66728C';
export const success = _green.g500;
export const error = '#E57373';
export const warning = _orange.o500;
export const grey = _neutral.n500;
export const info = _blue.b500;
export const blue = _blue.b500;
export const purple = _purple.pu500;
export const yellow = _yellow.y500;

export default {
  white,
  black,
  primary,
  textPrimary,
  secondary,
  textSecondary,
  success,
  error,
  warning,
  dark,
  blue,
  purple,
  yellow,
  info,
  blurOverlay,
  ..._neutral,
  ..._turquoise,
  ..._blue,
  ..._green,
  ..._yellow,
  ..._red,
  ..._orange,
  ..._purple,
};
