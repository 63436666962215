import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import InputControl from '../../InputControl/InputControl';
import { StyledInput } from '../../../mixins';

const StyledNumberInput = styled(StyledInput)`
  padding-right: ${props => (props.showControls ? '4.5rem' : 'inherit')};
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
`;

const StyledNumberInputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const StyledInputControls = styled.div`
  display: inline-flex;
  position: absolute;
  right: 0.5rem;
`;

const NumberInput = ({ showControls, disabled, onClick, value, ...props }) => (
  <StyledNumberInputWrapper>
    <StyledNumberInput type="number" {...props} disabled={disabled} value={value} />
    {showControls && (
      <StyledInputControls>
        <InputControl disabled={disabled} type="minus" onClick={onClick} value={value} />
        <InputControl disabled={disabled} type="plus" onClick={onClick} value={value} />
      </StyledInputControls>
    )}
  </StyledNumberInputWrapper>
);

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  showControls: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: function({ showControls, onClick }, propName, componentName) {
    if (showControls && !onClick) {
      return new Error(
        `The prop '${propName}' is marked as required in '${componentName}' when showControls, but its value is '${onClick}'`,
      );
    }
  },
};

NumberInput.defaultProps = {
  disabled: false,
  showControls: false,
  value: '',
};

export default NumberInput;
