import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import WelcomePageTemplate from 'components/templates/WelcomePageTemplate/WelcomePageTemplate';
import { findFirstExistingSection } from '../../../../utils/findFirstExistingSection';
import { sectionOptions } from '../Form/FormRouter';
import { PATHS } from '../../../../constants';

export const Page = ({ logo, uuid, t, selectedSections }) => {
  const [firstSection, setFirstSection] = useState('');

  useEffect(() => {
    const firstNonEmptyKey = findFirstExistingSection(sectionOptions, selectedSections);
    setFirstSection(firstNonEmptyKey);
  }, []);

  const heading = (
    <Fragment>
      <FormattedMessage id="TEXT.WELCOME.RETURNING.HEADINGS_1" />
      <br />
      <FormattedMessage id="TEXT.WELCOME.RETURNING.HEADINGS_2" />
    </Fragment>
  );

  const content = (
    <Fragment>
      <FormattedMessage id="TEXT.WELCOME.RETURNING.TEXT_1" />
      <br />
      <FormattedMessage id="TEXT.WELCOME.RETURNING.TEXT_2" />
    </Fragment>
  );

  return (
    <WelcomePageTemplate
      heading={heading}
      content={content}
      buttonText={t.buttonStart}
      buttonLink={`/${uuid}/${PATHS[firstSection]}`}
      logo={logo}
    />
  );
};

Page.propTypes = {
  logo: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  t: PropTypes.shape({
    buttonStart: PropTypes.string.isRequired,
  }).isRequired,
  selectedSections: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
  logo: state.form.getIn(['property_manager_logo', 'value'], ''),
  uuid: state.view.get('uuid'),
  selectedSections: state.data.toJS().value.selected_sections,
});

export const WelcomeBackPage = withMappedTranslations({
  buttonStart: 'TEXT.WELCOME.RETURNING.BUTTON_TEXT',
})(Page);
export default connect(mapStateToProps)(WelcomeBackPage);
