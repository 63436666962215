import styled from 'styled-components';
import OverallProgress from '../../../molecules/OverallProgress/OverallProgress';

const ProgressBar = styled(OverallProgress)`
  display: none;
  ${props => props.theme.media.mobile`
    display: block;
    grid-area: progress-bar;
    width: 100vw;
    margin: -1rem;
  `};
`;

export default ProgressBar;
