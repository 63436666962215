import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../../atoms/Icon/Icon';
import CircleWithShadow from '../../atoms/CircleWithShadow/CircleWithShadow';

const IconCentered = styled(Icon)`
  flex-basis: 44.73%;
  height: 44.73%;
  transition: ${props => props.theme.transitions.default};
  width: 44.73%;
  path {
    transition: ${props => props.theme.transitions.default};
    fill: ${props => props.theme.colors.textSecondary};
  }
`;

const IconBadge = ({ className, size, icon }) => (
  <CircleWithShadow className={className} size={size}>
    <IconCentered icon={icon} />
  </CircleWithShadow>
);

IconBadge.propTypes = {
  // classname is need in order to be possible to extend this component
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
};

IconBadge.defaultProps = {
  className: '',
  size: 60,
};

export default IconBadge;
