import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { callingCountries } from 'country-data';
import { BasicInput, Select } from '..';

const StyledBasicInput = styled(BasicInput)`
  width: calc(100% - 5.5rem);
  margin-left: 0.5rem;
`;

const CountryCode = styled.span`
  font-weight: 500;
`;

class CountryOption extends Component {
  constructor(props) {
    super(props);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }
  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }
  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }
  render() {
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
      >
        <CountryCode>{this.props.option.value}</CountryCode> {this.props.option.label}
      </div>
    );
  }
}

CountryOption.propTypes = {
  option: PropTypes.object.isRequired,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  isFocused: PropTypes.bool,
};

const CountryValue = ({ value: { value } }) => (
  <div className="Select-value" title={value}>
    <span className="Select-value-label">{value}</span>
  </div>
);

CountryValue.propTypes = {
  value: PropTypes.object,
};

const Wrapper = styled.div`
  display: flex;
  width: 20rem;

  ${props => props.theme.media.mobileSmall`
    width: 100%;
  `};
`;

const StyledTelephoneInput = styled(Select)`
  &,
  .Select-control {
    width: 5rem;
  }

  .Select-control,
  .Select-menu-outer {
    border-color: ${props => props.theme.colors.textSecondary};
  }
`;

const countryData = callingCountries.all
  .map(country => ({ label: country.name, value: country.countryCallingCodes[0] }))
  .sort(function(a, b) {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

const TelephoneInput = ({ disabled, countryCode, countryCodeEnabled, value, name, codeId, ...props }) =>
  countryCodeEnabled ? (
    <Wrapper>
      <StyledTelephoneInput
        placeholder="+"
        value={countryCode}
        valueComponent={CountryValue}
        optionComponent={CountryOption}
        options={countryData}
        disabled={disabled}
        name={codeId}
        {...props}
      />
      <StyledBasicInput type="tel" name={name} disabled={disabled} {...props} value={value} />
    </Wrapper>
  ) : (
    <BasicInput type="tel" name={name} {...props} value={value} disabled={disabled} />
  );

TelephoneInput.propTypes = {
  countryCode: PropTypes.string,
  countryCodeEnabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  codeId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TelephoneInput.defaultProps = {
  countryCode: '',
  disabled: false,
  countryCodeEnabled: false,
};

export default TelephoneInput;
