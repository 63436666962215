import styled from 'styled-components';

const OnboardingGrid = styled.div`
  display: grid;
  height: 100vh;
  grid-template-areas: 'main sidebar';
  grid-template-columns: 1fr 440px;
  ${props => props.theme.media.desktop`
    grid-template-columns: 1fr 390px;
  `};
  ${props => props.theme.media.tablet`
    grid-template-areas: 'main';
    grid-template-columns: 1fr;
  `};
`;

export default OnboardingGrid;
