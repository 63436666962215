import { compose, withProps, branch } from 'recompose';
import { injectIntl } from 'react-intl';

import RawField from 'components/molecules/Field/Field';
import RawConditionalField from 'components/molecules/ConditionalField/ConditionalField';

import withOnChangeHandler from 'hoc/withOnChangeHandler/withOnChangeHandler';
import withOnBlurHandler from 'hoc/withOnBlurHandler/withOnBlurHandler';
import withOnClickHandler from 'hoc/withOnClickHandler/withOnClickHandler';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

const withMaxLengthTranslation = compose(
  injectIntl,
  withProps(props => ({
    t: {
      ...props.t,
      maxLengthText: props.intl.formatMessage({ id: 'TEXT.SHARED.MAX_LENGTH' }, { maxLength: props.maxLength }),
    },
  })),
);

const withFieldValidation = compose(
  withProps(props => {
    if (!props.data) return {};
    return {
      maxLength: props.maxLength || props.data.max_length,
      required: props.required || props.data.required,
      error: props.error || (props.data.errors && props.data.errors.join('\n')),
      value: props.value || props.data.value,
    };
  }),
  withMaxLengthTranslation,
);

const BaseField = withFieldValidation(RawField);
const BaseConditionalField = withFieldValidation(RawConditionalField);

export const ConditionalField = compose(
  withOnChangeHandler,
  withOnBlurHandler,
)(BaseConditionalField);
export const ConditionalFieldWithOnClick = compose(
  withOnChangeHandler,
  withOnBlurHandler,
  withOnClickHandler,
)(BaseConditionalField);

export const FieldWithOnChange = compose(withOnChangeHandler)(BaseField);
export const FieldWithOnClick = compose(
  withOnChangeHandler,
  withOnBlurHandler,
  withOnClickHandler,
)(BaseField);

const Field = compose(
  withOnChangeHandler,
  withOnBlurHandler,
)(BaseField);

export const DelayedUpdateField = compose(
  withOnChangeHandler,
  branch(props => !props.delayedUpdate, withOnBlurHandler),
)(BaseField);

export const SelectField = compose(
  withProps(props => ({ type: props.type || 'select' })),
  withMappedTranslations({
    placeholder: 'TEXT.SHARED.SELECT_OPTION',
    noResultsText: 'TEXT.SHARED.NO_RESULTS',
  }),
)(Field);

export default Field;
