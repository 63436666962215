import DisabledPage from 'components/pages/DisabledPage/DisabledPage';
import withTranslations from 'hoc/withTranslations/withTranslations';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  propertyManager: state.form.getIn(['property_manager_name', 'value']),
});

export default compose(
  connect(mapStateToProps),
  withTranslations,
  withProps(props => {
    return {
      t: {
        header: props.t('DISABLED.HEADER', { property_manager: props.propertyManager }),
        text: props.t('DISABLED.TEXT'),
      },
    };
  }),
)(DisabledPage);
