import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { SectionHeading } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';
import withWillUnmountHandler from 'hoc/withWillUnmountHandler/withWillUnmountHandler';
import Field, { FieldWithOnClick, SelectField, ConditionalField } from 'scenes/Onboarding/components/Field';
import FileUploadField from '../../../../../../../components/molecules/FileUpload/components/FileUploadField';
import FileUpload from '../../HostInfo/components/FileUpload';
import { shouldShowSubsectionOrHeading } from '../../../../../../../utils/shouldShowSubsectionOrHeading';

const Page = ({ form, t, view, selectedFields }) => {
  return (
    <Fragment>
      {shouldShowSubsectionOrHeading(
        [
          'property_logement_type_id',
          'property_property_type',
          'property_surface_area',
          'property_license_number',
          'property_sleeps',
        ],
        selectedFields,
      ) && <SectionHeading>{t('PROPERTY_DETAILS.HEADINGS.GENERAL')}</SectionHeading>}
      {selectedFields.includes('property_logement_type_id') && (
        <SelectField
          label={t('PROPERTY_DETAILS.QUESTIONS.HOUSING_TYPE.LABEL')}
          options={view.getIn(['options', 'logement_types']).toJS()}
          name="property_logement_type_id.value"
          data={form.get('property_logement_type_id').toJS()}
        />
      )}
      {selectedFields.includes('property_property_type') && (
        <SelectField
          label={t('PROPERTY_DETAILS.QUESTIONS.PROPERTY_TYPE.LABEL')}
          options={view.getIn(['options', 'property_types']).toJS()}
          name="property_property_type.value"
          data={form.get('property_property_type').toJS()}
        />
      )}
      {selectedFields.includes('property_surface_area') && (
        <Field
          name="property_surface_area.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.SURFACE_AREA.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.SURFACE_AREA.HINT')}
          suffix="m&sup2;"
          size="4"
          data={form.get('property_surface_area').toJS()}
        />
      )}
      {selectedFields.includes('property_license_number') && (
        <Field
          name="property_license_number.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.LICENSE_NUMBER.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.LICENSE_NUMBER.HINT')}
          data={form.get('property_license_number').toJS()}
        />
      )}
      {selectedFields.includes('property_sleeps') && (
        <FieldWithOnClick
          name="property_sleeps.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.SLEEPS.LABEL')}
          type="number"
          showControls
          data={form.get('property_sleeps').toJS()}
        />
      )}
      {shouldShowSubsectionOrHeading(
        [
          'property_existing_airbnb_account_credentials',
          'property_gas_safety_certificate',
          'property_insurance_certificate',
          'property_star_certificate',
          'property_stars',
          'property_building_construction_period',
          'property_reservations_from',
          'property_building_legal_status',
          'property_open_activity_in_finance_document',
          'property_du_etisalat_account_number',
          'property_dewa_account_number',
          'property_gas_supplier_details',
          'property_air_conditioning_supplier_details',
          'property_cadastral_reference',
          'property_is_maintenance_allowed',
          'property_energy_performance_certificate',
          'property_title_deed',
          'property_bills_and_services_up_to_date',
          'property_electrical_installation_condition_report',
          'property_access_utilities',
        ],
        selectedFields,
      ) && <SectionHeading>{t('PROPERTY_DETAILS.HEADINGS.ADDITIONAL_INFO')}</SectionHeading>}
      {selectedFields.includes('property_existing_airbnb_account_credentials') && (
        <Field
          name="property_existing_airbnb_account_credentials.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.EXISTING_AIRBNB_ACCOUNT.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.EXISTING_AIRBNB_ACCOUNT.HINT')}
          data={form.get('property_existing_airbnb_account_credentials').toJS()}
        />
      )}
      {selectedFields.includes('property_gas_safety_certificate') && (
        <FileUploadField
          t={t}
          form={form}
          label={t('PROPERTY_DETAILS.QUESTIONS.GAS_SAFETY_CERTIFICATE.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.GAS_SAFETY_CERTIFICATE.HINT')}
          category="GSC"
        />
      )}
      {selectedFields.includes('property_insurance_certificate') && (
        <FileUploadField
          t={t}
          form={form}
          label={t('PROPERTY_DETAILS.QUESTIONS.INSURANCE_CERTIFICATE.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.INSURANCE_CERTIFICATE.HINT')}
          category="INSURANCE_CERTIFICATE"
        />
      )}
      {selectedFields.includes('property_star_certificate') && (
        <FileUploadField
          t={t}
          form={form}
          label={t('PROPERTY_DETAILS.QUESTIONS.STAR_CERTIFICATE.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.STAR_CERTIFICATE.HINT')}
          category="STAR_CERTIFICATE"
        />
      )}
      {selectedFields.includes('property_stars') && (
        <FieldWithOnClick
          name="property_stars.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.STARS.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.STARS.HINT')}
          data={form.get('property_stars').toJS()}
          showControls
          type="number"
        />
      )}
      {selectedFields.includes('property_building_construction_period') && (
        <SelectField
          name="property_building_construction_period.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.CONSTRUCTION_PERIOD.LABEL')}
          options={view.getIn(['options', 'building_construction_period']).toJS()}
          hint={t('PROPERTY_DETAILS.QUESTIONS.CONSTRUCTION_PERIOD.HINT')}
          data={form.get('property_building_construction_period').toJS()}
        />
      )}
      {selectedFields.includes('property_reservations_from') && (
        <Field
          name="property_reservations_from.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.AVAILABLE_FROM.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.AVAILABLE_FROM.HINT')}
          data={form.get('property_reservations_from').toJS()}
          type="datepicker"
        />
      )}
      {selectedFields.includes('property_building_legal_status') && (
        <SelectField
          name="property_building_legal_status.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.LEGAL_STATUS.LABEL')}
          options={view.getIn(['options', 'building_legal_status']).toJS()}
          data={form.get('property_building_legal_status').toJS()}
        />
      )}
      {selectedFields.includes('property_open_activity_in_finance_document') && (
        <FileUploadField
          t={t}
          form={form}
          label={t('PROPERTY_DETAILS.QUESTIONS.OPEN_ACTIVITY_IN_FINANCE_DOCUMENT.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.OPEN_ACTIVITY_IN_FINANCE_DOCUMENT.HINT')}
          category="OPEN_ACTIVITY_IN_FINANCE"
        />
      )}
      {selectedFields.includes('property_du_etisalat_account_number') && (
        <Field
          name="property_du_etisalat_account_number.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.DU_ETISALAT_ACCOUNT_NUMBER.LABEL')}
          data={form.get('property_du_etisalat_account_number').toJS()}
        />
      )}
      {selectedFields.includes('property_dewa_account_number') && (
        <>
          <Field
            name="property_dewa_account_number.value"
            label={t('PROPERTY_DETAILS.QUESTIONS.DEWA_ACCOUNT_NUMBER.LABEL')}
            hint={t('PROPERTY_DETAILS.QUESTIONS.DEWA_ACCOUNT_NUMBER.HINT')}
            data={form.get('property_dewa_account_number').toJS()}
          />
          <FileUpload
            t={t}
            value={form
              .getIn(['property_documents', 'value'])
              .toList()
              .toJS()}
            category="DEWA_BILL"
          />
        </>
      )}
      {selectedFields.includes('property_gas_supplier_details') && (
        <Field
          name="property_gas_supplier_details.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.GAS_SUPPLIER_DETAILS.LABEL')}
          data={form.get('property_gas_supplier_details').toJS()}
        />
      )}
      {selectedFields.includes('property_air_conditioning_supplier_details') && (
        <Field
          name="property_air_conditioning_supplier_details.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.AC_SUPPLIER.LABEL')}
          data={form.get('property_air_conditioning_supplier_details').toJS()}
        />
      )}
      {selectedFields.includes('property_air_conditioning_supplier_details') && (
        <Field
          name="property_cadastral_reference.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.CADASTRAL_REFERENCE.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.CADASTRAL_REFERENCE.HINT')}
          data={form.get('property_cadastral_reference').toJS()}
        />
      )}
      {selectedFields.includes('property_is_maintenance_allowed') && (
        <FieldWithOnClick
          name="property_is_maintenance_allowed.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.IS_MAINTENANCE_ALLOWED.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.IS_MAINTENANCE_ALLOWED.HINT')}
          data={form.get('property_is_maintenance_allowed').toJS()}
          type="radio-buttons"
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_energy_performance_certificate') && (
        <FileUploadField
          t={t}
          form={form}
          label={t('PROPERTY_DETAILS.QUESTIONS.ENERGY_PERFORMANCE_CERTIFICATE.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.ENERGY_PERFORMANCE_CERTIFICATE.HINT')}
          category="EPC"
        />
      )}
      {selectedFields.includes('property_title_deed') && (
        <FileUploadField
          t={t}
          form={form}
          label={t('PROPERTY_DETAILS.QUESTIONS.TITLE_DEED.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.TITLE_DEED.HINT')}
          category="TITLE_DEED"
        />
      )}
      {selectedFields.includes('property_bills_and_services_up_to_date') && (
        <FieldWithOnClick
          name="property_bills_and_services_up_to_date.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.BILLS_UP_TO_DATE.LABEL')}
          data={form.get('property_bills_and_services_up_to_date').toJS()}
          type="radio-buttons"
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_electrical_installation_condition_report') && (
        <FileUploadField
          t={t}
          form={form}
          label={t('PROPERTY_DETAILS.QUESTIONS.ELECTRICAL_INSTALLATION_CONDITION_REPORT.LABEL')}
          hint={t('PROPERTY_DETAILS.QUESTIONS.ELECTRICAL_INSTALLATION_CONDITION_REPORT.HINT')}
          category="EICR"
        />
      )}
      {selectedFields.includes('property_access_utilities') && (
        <Field
          name="property_access_utilities.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.ACCESS_UTILITIES.LABEL')}
          data={form.get('property_access_utilities').toJS()}
        />
      )}
      {shouldShowSubsectionOrHeading(['property_has_wifi', 'property_wifi_info'], selectedFields) && (
        <SectionHeading>{t('PROPERTY_DETAILS.HEADINGS.WIFI')}</SectionHeading>
      )}
      {selectedFields.includes('property_has_wifi') && (
        <FieldWithOnClick
          name="property_has_wifi.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.HAS_WIFI.LABEL')}
          data={form.get('property_has_wifi').toJS()}
          type="radio-buttons"
          dataType="frontend-only"
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      <ConditionalField type="wrapper" value={form.getIn(['property_has_wifi', 'value'])}>
        {selectedFields.includes('property_nom_wifi') && (
          <Field
            name="property_nom_wifi.value"
            label={t('PROPERTY_DETAILS.QUESTIONS.WIFI_NETWORK_NAME.LABEL')}
            data={form.get('property_nom_wifi').toJS()}
          />
        )}
        {selectedFields.includes('property_wifi_code') && (
          <Field
            name="property_wifi_code.value"
            label={t('PROPERTY_DETAILS.QUESTIONS.WIFI_PASSWORD.LABEL')}
            data={form.get('property_wifi_code').toJS()}
          />
        )}
      </ConditionalField>
      {selectedFields.includes('property_wifi_info') && (
        <Field
          name="property_wifi_info.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.WIFI_INFORMATION.LABEL')}
          placeholder={t('PROPERTY_DETAILS.QUESTIONS.WIFI_INFORMATION.PLACEHOLDER')}
          data={form.get('property_wifi_info').toJS()}
          type="textarea"
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  view: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  view: state.view,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'PROPERTY_DETAILS' in obj).PROPERTY_DETAILS,
});

const TPage = withTranslations(Page);
export default compose(
  withWillUnmountHandler(['property_amenities']),
  connect(mapStateToProps),
)(TPage);
