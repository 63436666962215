import styled from 'styled-components';
import backgroundImage from '../../../../assets/images/welcome-bg.jpg';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  background: linear-gradient(-45deg, transparent 40%, white 90%), url(${backgroundImage});
  /* background: white; */
  background-size: cover;
  background-position-x: 0;
  background-position-y: 50%;
`;

export default Container;
