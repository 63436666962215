import { Map, List } from 'immutable';

export const getViewInfo = state => state.view;
export const getViewInfoJS = state => state.view.toJS();
export const getValidationErrors = state => state.view.getIn(['validation', 'errors'], Map({}));
export const getValidationWarnings = state => state.view.getIn(['validation', 'warnings'], Map({}));

export const getValidationWarningsJS = state => {
  const warnings = getValidationWarnings(state);
  return warnings.toJS();
};

export const getValidationSidebarData = state => {
  const errors = getValidationErrors(state);
  return errors
    .map(section =>
      section
        .set('pageNumbers', section.keySeq())
        .set('count', section.valueSeq().reduce((acc, val) => acc.concat(val), List([])).size),
    )
    .toJS();
};
