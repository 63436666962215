import styled from 'styled-components';
import BaseGridColumns from './BaseGridColumns';

const ContentWrapper = styled(BaseGridColumns)`
  padding-bottom: 5rem;
  padding-right: 1rem;
  overflow: hidden;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    '. content .'
    '. navigation .';
  ${props => props.theme.media.mobile`
    padding-left: 1rem;
    overflow-y: visible;
    grid-template-areas: 'content .' 'navigation .';
  `};
  ${props => props.theme.media.mobileSmall`
    grid-template-areas: 'content' 'navigation';
  `};
`;

export default ContentWrapper;
