import { compose, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateFieldRequested } from '../../ducks/form/form';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchUpdateFieldRequested: updateFieldRequested }, dispatch);

const withDefaultOnBlurHandler = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    onBlur: props => event => {
      props.dispatchUpdateFieldRequested({
        name: event.target.name,
        type: props.dataType || props.type || event.target.type || event.target.dataType || 'default',
      });
    },
  }),
);

export default withDefaultOnBlurHandler;
