import { call, put, takeLatest } from 'redux-saga/effects';
import DataApi from '../../api/DataApi';

import { FETCH_DATA_REQUESTED, fetchDataSuccessed, fetchDataFailed } from '../../ducks/data/data';

export function* fetchData() {
  try {
    const data = yield call([DataApi.apiInstance(), 'getAll']);
    const metadata = yield call([DataApi.apiInstance(), 'getMetadata']);
    if (!(data && Object.keys(data).length)) {
      return yield put(fetchDataFailed(new Error('Error occured fetching onboarding details')));
    }

    yield put(fetchDataSuccessed({ data, metadata }));
  } catch (e) {
    yield put(fetchDataFailed(e));
  }
}

function* watchData() {
  yield takeLatest(FETCH_DATA_REQUESTED, fetchData);
}

export default watchData;
