import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { goBack } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

import { finishEditingRequested } from 'ducks/view/view';
import { getValidationWarningsJS } from 'selectors/view';

import ButtonGroup from 'components/atoms/ButtonGroup/ButtonGroup';
import { MediumText } from 'components/atoms/Typography/Typography';
import LoadingButton from 'components/atoms/LoadingButton/LoadingButton';

import withModals from 'hoc/withModals/withModals';
import SaveErrorModalContent from './components/SaveErrorModalContent';
import { Container, FormProgress, ValidationWarnings } from './components';

const Page = ({ overallProgress, dispatchFinishEditing, t, isFetching, dispatchGoBack, validationWarnings }) => (
  <Container>
    <FormProgress overallProgress={overallProgress} t={t} />
    <ValidationWarnings validationWarnings={validationWarnings} t={t} />
    <ButtonGroup>
      <MediumText>{t.confirmation}</MediumText>
      <LoadingButton isFetching={isFetching} onClick={dispatchGoBack} type="secondary-grey">
        {t.No}
      </LoadingButton>
      <LoadingButton isFetching={isFetching} onClick={dispatchFinishEditing} type="primary">
        {t.Yes}
      </LoadingButton>
    </ButtonGroup>
  </Container>
);

Page.propTypes = {
  overallProgress: PropTypes.number,
  dispatchFinishEditing: PropTypes.func.isRequired,
  dispatchGoBack: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  t: PropTypes.shape({
    confirmation: PropTypes.string.isRequired,
    Yes: PropTypes.string.isRequired,
    No: PropTypes.string.isRequired,
    OVERALL_PROGRESS: PropTypes.string.isRequired,
    PERCENT_COMPLETE: PropTypes.string.isRequired,
  }).isRequired,
  validationWarnings: PropTypes.shape({
    missing_amenities: PropTypes.arrayOf(PropTypes.string),
  }),
};

Page.defaultProps = {
  isFetching: false,
  overallProgress: 0,
};

const mapStateToProps = state => ({
  overallProgress: state.view.getIn(['progress', 'overall'], 0),
  apiDidInvalidate: state.view.get('apiDidInvalidate', false),
  isFetching: state.view.get('isFetching', false),
  validationWarnings: getValidationWarningsJS(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchFinishEditing: finishEditingRequested,
      dispatchGoBack: goBack,
    },
    dispatch,
  );

export const FinishPage = withMappedTranslations({
  confirmation: 'TEXT.FINISH.CONFIRMATION',
  missingAmenitiesWarning: 'TEXT.FINISH.MISSING_AMENITIES_WARNING',
  Yes: 'TEXT.SHARED.YES',
  No: 'TEXT.SHARED.NO',
  OVERALL_PROGRESS: 'TEXT.SIDEBAR.OVERALL_PROGRESS',
  PERCENT_COMPLETE: 'TEXT.SIDEBAR.PERCENT_COMPLETE',
})(Page);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withModals([
    {
      content: SaveErrorModalContent,
      show: props => props.apiDidInvalidate,
    },
  ]),
)(FinishPage);
