import React, { Fragment } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import { lifecycle } from 'recompose';

import store, { history } from 'store';
import { setLocale, codeCrashed } from 'ducks/view/view';
import isUUIDValid from 'utils/isUUIDValid';

import * as theme from 'components/themes/theme.js';
import NotFoundPage from 'scenes/NotFoundPage/NotFoundPage';

import Onboarding from 'scenes/Onboarding/Onboarding';

import CadizBookWoff from 'fonts/CadizWeb-Book.woff';
import CadizBookWoff2 from 'fonts/CadizWeb-Book.woff2';
import CadizBookSemiBoldWoff from 'fonts/CadizWeb-SemiBold.woff';
import CadizBookSemiBoldWoff2 from 'fonts/CadizWeb-SemiBold.woff2';

if (process.env.REACT_APP_HOST_ENV === 'production') {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, ignoreErrors: [/^ResizeObserver loop/] });
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Cadiz';
    src: url(${CadizBookWoff}) format('woff'),
         url(${CadizBookWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
}

  @font-face {
    font-family: 'Cadiz';
    src: url(${CadizBookSemiBoldWoff}) format('woff'),
         url(${CadizBookSemiBoldWoff2}) format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  html,
  body,
  form,
  input,
  textarea,
  button {
    font-family: 'Cadiz', sans-serif;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    overflow: hidden;
  }

  ::selection {
    color: white;
    background-color: #4B6CCC;
  }

  * {
    box-sizing: border-box;
  }

  @media screen and (max-width: 480px) {
    html {
      font-size: 87.5%;
    }
  }
`;

const AppRouter = props =>
  isUUIDValid(props.match.params.uuid) ? <Onboarding {...props} /> : <Redirect to="/notfound" />;

AppRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

const DEFAULT_LOCALE = 'en-gb';
let locale = 'en-gb';
let messages = null;

try {
  const fileLocale =
    (window.location.search.match('locale=fr') && 'fr-fr') ||
    (window.location.search.match('locale=pt') && 'pt-pt') ||
    (window.location.search.match('locale=es') && 'es-es') ||
    (window.location.search.match('locale=pt') && 'it-it') ||
    locale;
  messages = require(`translations/${fileLocale}.json`);
  locale = fileLocale;
} catch (e) {
  messages = require(`translations/${locale}.json`);
}

if (locale !== DEFAULT_LOCALE) {
  const fallbackMessages = require(`translations/${DEFAULT_LOCALE}.json`);
  const nonEmptyMessages = Object.fromEntries(Object.entries(messages).filter(([, value]) => Boolean(value)));
  messages = { ...fallbackMessages, ...nonEmptyMessages };
}

const localeData = require(`react-intl/locale-data/${locale.split('-')[0]}`);

addLocaleData([...localeData]);

const App = () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <GlobalStyle />
      <IntlProvider locale={locale} messages={messages}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Redirect to={`/${store.getState().view.get('uuid', 'notfound')}`} />}
              />
              <Route exact path="/notfound" component={NotFoundPage} />
              <Route path="/:uuid" component={AppRouter} />
              <Redirect to="/notfound" />
            </Switch>
          </ConnectedRouter>
        </Provider>
      </IntlProvider>
    </Fragment>
  </ThemeProvider>
);

export default lifecycle({
  componentDidMount() {
    store.dispatch(setLocale(locale));
  },
  componentDidCatch(error) {
    store.dispatch(codeCrashed());
    Sentry.captureException(error);
  },
})(App);
