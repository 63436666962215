import { takeEvery } from 'redux-saga/effects';

import { UPDATE_FIELD_FAILED, UPDATE_SPECIAL_FIELD_FAILED, DELETE_SPECIAL_FIELD_FAILED } from 'ducks/form/form';

import * as Sentry from '@sentry/react';

export function* logSentryError({ payload: { error } }) {
  yield Sentry.captureException(error);
}

export default function* watchFormErrors() {
  yield takeEvery([UPDATE_FIELD_FAILED, UPDATE_SPECIAL_FIELD_FAILED, DELETE_SPECIAL_FIELD_FAILED], logSentryError);
}
