export const getNextAvailableBedType = ({ bed_types, bed_set }) => {
  const existingBeds = bed_set.map(bedSet => bedSet.get('bed_type'));
  return bed_types.filter(bed => existingBeds.indexOf(bed) === -1).first();
};

export const getRemovedBedTypeIndex = ({ removedBeds, bed_type }) => {
  const removedBedTypeIndex = removedBeds.findLastIndex(
    bed =>
      bed.get('beds').size &&
      bed.get('bed_type') === bed_type.get('bed_type') &&
      bed.get('bedroom_id') === bed_type.get('bedroom_id'),
  );

  return removedBedTypeIndex;
};
