import { all, fork } from 'redux-saga/effects';

import data from './data/data';
import form from './form/form';
import view from './view/view';
import route from './route/route';
import localStorage from './localStorage/localStorage';

export default function* rootSaga() {
  yield all([fork(data), fork(form), fork(view), fork(route), fork(localStorage)]);
}
