import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledInput = styled.input`
  border: 1px solid ${props => props.theme.colors.textSecondary};
  color: ${props => props.theme.colors.n800};
  background-color: ${props => props.theme.colors.white};
  box-shadow: none;
  outline: none;
  padding: 0.5rem;
  padding-bottom: 0.4rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  transition: ${props => props.theme.transitions.default};
  width: ${props => (props.size ? +props.size + 2 + 'ch' : '20rem')};
  appearance: none;

  &::placeholder {
    color: ${props => props.theme.colors.textSecondary};
  }
  &:focus {
    box-shadow: ${props => props.theme.shadows.default};
  }
  &[disabled] {
    border-color: ${props => props.theme.colors.n400};
    &,
    &::placeholder {
      color: ${props => props.theme.colors.n400};
    }
  }

  ${props => props.theme.media.mobileSmall`
    width: 100%;
  `};
`;

const BasicInput = props => <StyledInput {...props} />;

BasicInput.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

BasicInput.defaultProps = {
  type: 'text',
};

export default BasicInput;
