import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoImage = styled.img`
  max-width: 160px;
  max-height: 120px;
`;

const Logo = ({ logo }) => {
  if (!logo) return null;
  return <LogoImage src={logo} alt="Logo" />;
};

Logo.propTypes = {
  logo: PropTypes.string,
};

export default Logo;
