import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field, { ConditionalFieldWithOnClick } from 'scenes/Onboarding/components/Field';
import { SectionHeading } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';

const Page = ({ form, t, selectedFields }) => {
  return (
    <Fragment>
      <SectionHeading>{t('RULES_AND_ADDITIONAL_INFO.HEADINGS.ADDITIONAL_INFO')}</SectionHeading>
      <ConditionalFieldWithOnClick
        name="property_extra_information_letterbox_access.value"
        label={t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.MAILBOX_ACCESS.LABEL')}
        type="radio-buttons"
        data={form.get('property_extra_information_letterbox_access').toJS()}
        options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
      >
        {selectedFields.includes('property_indication_mailbox') && (
          <Field
            name="property_indication_mailbox.value"
            label={t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.INDICATION_MAILBOX.LABEL')}
            data={form.get('property_indication_mailbox').toJS()}
            type="textarea"
          />
        )}
      </ConditionalFieldWithOnClick>
      {selectedFields.includes('property_extra_information_additional_observation') && (
        <Field
          name="property_extra_information_additional_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_extra_information_additional_observation').toJS()}
          type="textarea"
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'RULES_AND_ADDITIONAL_INFO' in obj)
    .RULES_AND_ADDITIONAL_INFO,
});

const TPage = withTranslations(Page);
export default connect(mapStateToProps)(TPage);
