import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { sidebarToggled } from '../../../../ducks/view/view';
import MenuToggle from '../../../../components/atoms/MenuToggle/MenuToggle';

const SidebarToggleStyled = styled(MenuToggle)`
  display: none;
  justify-self: flex-end;
  grid-area: main;
  margin: 16px;
  ${props => props.theme.media.tablet`
    display: ${props => (props.sidebarOpen ? 'none' : 'inline-flex')}
  `};
`;

const SidebarToggle = ({ sidebar, dispatchSideberToggled }) => (
  <SidebarToggleStyled sidebarOpen={sidebar.open} clicked={dispatchSideberToggled} />
);

SidebarToggle.propTypes = {
  sidebar: PropTypes.shape({
    open: PropTypes.bool.isRequired,
  }).isRequired,
  dispatchSideberToggled: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  sidebar: state.view.get('sidebar').toJS(),
});

const mapDispatchToProps = dispatch => bindActionCreators({ dispatchSideberToggled: sidebarToggled }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarToggle);
