import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Page1 from './scenes/Page1';
import Page2 from './scenes/Page2';
import Page3 from './scenes/Page3';
import DefaultPage from '../../../../components/DefaultPage';
import { ICONS } from '../../../../../../constants';
import withTranslations from 'hoc/withTranslations/withTranslations';
import PageHeading from 'components/molecules/PageHeading/PageHeading';
import { getPrevPage } from '../../../../../../utils/getPrevPage';
import { getNextPage } from '../../../../../../utils/getNextPage';

const Section = ({ t, displayedSections }) => {
  const content = (
    <Fragment>
      <Page1 />
      <Page2 />
      <Page3 />
    </Fragment>
  );
  return (
    <DefaultPage
      headerIcon={ICONS.CLEANING_AND_MAINTENANCE}
      header={<PageHeading>{t('CLEANING_AND_MAINTENANCE.HEADING')}</PageHeading>}
      content={content}
      prevPage={getPrevPage(displayedSections, 'CLEANING_AND_MAINTENANCE')}
      nextPage={getNextPage(displayedSections, 'CLEANING_AND_MAINTENANCE')}
    />
  );
};

Section.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  displayedSections: PropTypes.arrayOf(PropTypes.string),
};

export default withTranslations(Section);
