import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Number = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.25rem;
  font-size: 1rem;
  text-align: center;
  display: inline-block;
  appearance: none;
  user-select: none;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.textSecondary};
  transition: ${props => props.theme.transitions.fast};
  background-color: ${props => (props.selected ? props.theme.colors.n800 : props.theme.colors.white)};
  color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.n800)};
  &:hover {
    background-color: ${props => props.theme.colors.n800};
    color: ${props => props.theme.colors.white};
  }
  &:focus {
    outline: none;
    box-shadow: ${props => props.theme.shadows.focus};
    z-index: 1;
    position: relative;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: ${props => (props.selected ? props.theme.colors.n800 : props.theme.colors.white)};
      color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.n800)};
    }
  }
  &:nth-child(n + 2) {
    border-left: none;
  }
  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const NumberRange = ({ min, max, step, value, disabled, onClick }) => {
  const SelectArray = [];
  for (let i = +min; i <= +max; i += +step) {
    SelectArray.push(i);
  }
  return SelectArray.map(number => (
    <Number
      key={number}
      selected={number === +value}
      value={number}
      disabled={disabled}
      onClick={() => onClick(number)}
      tabIndex={disabled ? -1 : 0}
    >
      {number}
    </Number>
  ));
};

NumberRange.propTypes = {
  onClick: PropTypes.func.isRequired,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NumberRange.defaultProps = {
  step: 1,
  min: 1,
  max: 5,
};

export default NumberRange;
