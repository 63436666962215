import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { SectionHeading, Text } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';
import AmenitiesGrid from 'components/atoms/AmenitiesGrid/AmenitiesGrid';
import AmenityInput from 'scenes/Onboarding/components/AmenityInput';
import Field, { FieldWithOnClick, FieldWithOnChange, ConditionalField } from 'scenes/Onboarding/components/Field';
import BathroomsField from '../components/BathroomsField/BathroomsField';
import BedroomField from '../components/BedroomField/BedroomField';
import { AMENITY_CATEGORIES } from 'constants.js';
import withWillUnmountHandler from 'hoc/withWillUnmountHandler/withWillUnmountHandler';
import { shouldShowSubsectionOrHeading } from '../../../../../../../utils/shouldShowSubsectionOrHeading';

const Page = ({ form, t, view, bathroomAmenities, selectedFields }) => {
  const commonSpacesLabel = form.getIn(['property_bedrooms', 'view', 'bed_locations', 'LIVINGROOM'], 'common spaces');

  return (
    <Fragment>
      {shouldShowSubsectionOrHeading(
        [
          'property_bedrooms_count',
          'property_common_space',
          'property_extra_information_pillows_for_all_beds',
          'property_bedrooms_observation',
        ],
        selectedFields,
      ) && <SectionHeading>{t('PROPERTY_DETAILS.HEADINGS.BEDROOMS')}</SectionHeading>}
      {selectedFields.includes('property_bedrooms_count') && (
        <>
          <FieldWithOnChange
            name="property_bedrooms_count.value"
            label={t('PROPERTY_DETAILS.QUESTIONS.NUMBER_OF_BEDROOMS.LABEL')}
            type="select"
            options={view.getIn(['options', 'bedrooms_counter']).toJS()}
            data={form.get('property_bedrooms_count').toJS()}
            dataType="bedrooms"
            t={{
              placeholder: t('TEXT.SHARED.SELECT_OPTION'),
              noResultsText: t('TEXT.SHARED.NO_RESULTS'),
            }}
          />
          {form
            .getIn(['property_bedrooms', 'value'])
            .toList()
            .map((bedroom, key) => {
              let label = form.getIn(['property_bedrooms', 'view', 'bed_locations', bedroom.get('bedroom_type')]);
              if (bedroom.get('bedroom_type') === 'BEDROOM') {
                label += ` ${key + 1}`;
              }
              return (
                <BedroomField
                  key={`property_bedrooms.value.${key}`}
                  name={`property_bedrooms.value.${key}`}
                  label={label}
                  bedTypes={view.getIn(['options', 'bed_types']).toJS()}
                  dataType="beds"
                  bedroom={bedroom.toJS()}
                />
              );
            })
            .toArray()}
        </>
      )}
      {selectedFields.includes('property_common_space') && (
        <BedroomField
          name="property_common_space"
          bedTypes={view.getIn(['options', 'bed_types']).toJS()}
          label={`${commonSpacesLabel[0].toUpperCase()}${commonSpacesLabel.slice(1)}`}
          dataType="common_space"
          bedroom={form.get('property_common_space').toJS()}
        />
      )}
      {selectedFields.includes('property_extra_information_pillows_for_all_beds') && (
        <FieldWithOnClick
          name="property_extra_information_pillows_for_all_beds.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.BED_FURNISHINGS.LABEL')}
          type="radio-buttons"
          data={form.get('property_extra_information_pillows_for_all_beds').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_bedrooms_observation') && (
        <Field
          name="property_bedrooms_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_bedrooms_observation').toJS()}
          type="textarea"
        />
      )}
      {shouldShowSubsectionOrHeading(
        ['property_bathrooms_count', 'property_bathrooms_instruction', 'property_bathrooms_observation'],
        selectedFields,
      ) && <SectionHeading>{t('PROPERTY_DETAILS.HEADINGS.BATHROOMS')}</SectionHeading>}
      {selectedFields.includes('property_bathrooms_count') && (
        <FieldWithOnChange
          name="property_bathrooms_count.value"
          label={t('PROPERTY_DETAILS.QUESTIONS.NUMBER_OF_BATHROOMS.LABEL')}
          type="select"
          options={view.getIn(['options', 'bathrooms_counter']).toJS()}
          data={form.get('property_bathrooms_count').toJS()}
          dataType="bathrooms"
          t={{
            placeholder: t('TEXT.SHARED.SELECT_OPTION'),
            noResultsText: t('TEXT.SHARED.NO_RESULTS'),
          }}
        />
      )}
      <ConditionalField
        type="wrapper"
        visibleForValuesExcept={['0']}
        value={form.getIn(['property_bathrooms_count', 'value']) || 0}
      >
        {selectedFields.includes('property_bathrooms') && (
          <BathroomsField
            name="property_bathrooms.value"
            options={view.getIn(['options', 'bathrooms']).toJS()}
            bathrooms={form.getIn(['property_bathrooms', 'value']).toJS()}
            label={t('PROPERTY_DETAILS.QUESTIONS.BATHROOM_AMENITIES.LABEL')}
            dataType="bathrooms"
          />
        )}
      </ConditionalField>
      {selectedFields.includes('property_bathrooms_instruction') && (
        <Field
          name="property_bathrooms_instruction"
          label={t('PROPERTY_DETAILS.QUESTIONS.BATHROOM_INSTRUCTIONS.LABEL')}
          type="conditional-textarea"
          data={form.get('property_bathrooms_instruction').toJS()}
          t={{ yes: t('TEXT.SHARED.YES'), no: t('TEXT.SHARED.NO') }}
        />
      )}
      {selectedFields.includes('property_bathrooms_observation') && (
        <Field
          name="property_bathrooms_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_bathrooms_observation').toJS()}
          type="textarea"
        />
      )}
      {shouldShowSubsectionOrHeading(
        ['property_bathrooms_count', 'property_bathrooms_instruction', 'property_bathrooms_observation'],
        selectedFields,
      ) && (
        <>
          <SectionHeading>{t('PROPERTY_DETAILS.HEADINGS.BATHROOM_AMENITIES')}</SectionHeading>
          <Text>{t('PROPERTY_DETAILS.DESCRIPTIONS.BATHROOM_AMENITIES')}</Text>
          <AmenitiesGrid>
            {bathroomAmenities.map(amenityView => {
              const amenityValue = form.getIn(['property_amenities', 'value', amenityView.get('id')]);
              return (
                amenityView && (
                  <AmenityInput
                    list="property_amenities"
                    key={amenityView.get('id')}
                    view={amenityView.toJS()}
                    value={amenityValue && amenityValue.toJS()}
                  />
                )
              );
            })}
          </AmenitiesGrid>
        </>
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  view: PropTypes.object,
  t: PropTypes.func.isRequired,
  bathroomAmenities: PropTypes.object.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  view: state.view,
  bathroomAmenities: state.form
    .getIn(['property_amenities', 'view'])
    .filter(amenityView => amenityView.get('category') === AMENITY_CATEGORIES.BATHING),
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'PROPERTY_DETAILS' in obj).PROPERTY_DETAILS,
});

const TPage = withTranslations(Page);
export default compose(
  withWillUnmountHandler(['property_amenities', 'property_bedrooms', 'property_bathrooms']),
  connect(mapStateToProps),
)(TPage);
