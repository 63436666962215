const defaultProperties = field => ({
  column: field.column,
  resource: field.resource,
});

export const mapPhoneField = field => ({
  ...defaultProperties(field),
  value: field.country_code ? `(${field.country_code}) ${field.value}` : field.value,
});

export const mapRadioButtonsField = field => {
  let mappedValue;
  if (['yes', 'no'].indexOf(field.value) !== -1) {
    mappedValue = field.value === 'yes';
  } else {
    mappedValue = field.value;
  }
  return {
    ...defaultProperties(field),
    value: mappedValue,
  };
};

export const mapConditionalTextAreaField = field => ({
  ...defaultProperties(field),
  value: (field.isEnabled && field.value) || field.default,
});

export const mapTextsList = field => ({
  ...defaultProperties(field),
  value: field.value.filter(text => text),
});

const mapField = (type, field) => {
  switch (type) {
    case 'tel':
      return mapPhoneField(field);
    case 'radio-buttons':
      return mapRadioButtonsField(field);
    case 'conditional-textarea':
      return mapConditionalTextAreaField(field);
    case 'texts-list':
      return mapTextsList(field);
    default:
      return { ...field, value: field.value || field.default };
  }
};

export default mapField;
