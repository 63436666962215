import { Map } from 'immutable';

export default class API {
  constructor(uuid, locale) {
    this.baseURL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}${uuid}`;
    this.locale = locale;
  }

  get(url = '/', options = {}) {
    return this._request(url, this._options({ method: 'GET', ...options }));
  }

  options(url = '/', options = {}) {
    return this._request(url, this._options({ method: 'OPTIONS', ...options }));
  }

  post(url, data = {}, options = {}) {
    return this._request(url, this._options({ method: 'POST', data, ...options }));
  }

  put(url, data = {}, options = {}) {
    return this._request(url, this._options({ method: 'PUT', data, ...options }));
  }

  delete(url, ids) {
    return [].concat(ids).map(id => this._request(`${url}/${id}/`, this._options({ method: 'DELETE' })));
  }

  _options({ method, data, headers = {}, ...options }) {
    switch (method) {
      case 'PUT':
      case 'POST':
        return {
          method: method,
          body: JSON.stringify(data),
          headers: this._headers(headers),
          ...options,
        };
      default:
        return { ...options, method, headers: this._headers(headers) };
    }
  }

  _headers(headers) {
    return Map({
      'content-type': 'application/json',
    })
      .merge(headers)
      .toJS();
  }

  _request(url, options) {
    return fetch(`${this.baseURL}${url}`, options)
      .then(res => {
        if (res.status < 200 || res.status >= 300) throw new Error(res.statusText);
        return res.json();
      })
      .catch(error => {
        throw error;
      });
  }
}
