import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledLabel = styled.label`
  color: ${props => props.theme.colors.n800};
  font-weight: ${props => (props.disabled ? 400 : 500)};
  min-width: 17.75rem;
  ${props => props.theme.media.mobileSmall`
    min-width: calc(100% - 2.25rem);
  `};
`;

StyledLabel.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
};

StyledLabel.defaultProps = {
  size: 0,
  disabled: false,
};

export default StyledLabel;
