import styled from 'styled-components';

const FieldContainer = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
`;

export default FieldContainer;
