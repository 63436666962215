import { Map, List, fromJS } from 'immutable';
import { put, call, all, fork, select } from 'redux-saga/effects';

import {
  updateFieldFailed,
  updateSpecialFieldFailed,
  updateSpecialFieldSuccessed,
  deleteSpecialFieldSuccessed,
  deleteSpecialFieldFailed,
} from 'ducks/form/form';
import { updateDataFieldSuccessed } from 'ducks/data/data';
import {
  mapBedroomsField,
  mapBedroomsRemovedField,
  mapBedsRemovedField,
  mapBathroomsField,
  mapBathroomsRemovedField,
  mapAmenitiesField,
  mapAmenitiesRemovedField,
} from '../specialFieldsMapper/specialFieldsMapper';

import { getFormFieldInfoJS } from 'selectors/form.js';
import { getDataFieldValue } from 'selectors/data.js';

import {
  mapPropertyBathrooms,
  mapPropertyBedrooms,
  mapPropertyCommonSpace,
  mapPropertyAmenities,
} from '../../data/dataMapper/dataMapper';

import DataApi from 'api/DataApi.js';

export function* reMapSpecialField({ column, resource, value, view }) {
  switch (column) {
    case 'bathrooms': {
      const propertyId = yield select(getDataFieldValue, { resource: 'property', column: 'id' });
      const field = yield call(mapPropertyBathrooms, value, fromJS(view), propertyId);
      return yield Map().setIn(['property_bathrooms', 'value'], field);
    }
    case 'bedrooms': {
      const bedrooms = yield call(mapPropertyBedrooms, value);
      const commonSpace = yield call(mapPropertyCommonSpace, value);
      return yield Map()
        .setIn(['property_bedrooms', 'value'], bedrooms)
        .setIn(['property_common_space'], commonSpace)
        .setIn(['property_bedrooms_removed'], List([]))
        .setIn(['property_beds_removed'], List([]));
    }
    case 'property_amenities': {
      const field = yield call(mapPropertyAmenities, value, fromJS(view));
      return yield Map().setIn(['property_amenities', 'value'], field);
    }

    default:
      return yield { column, resource, value };
  }
}

export function* updateField(field) {
  try {
    yield call([DataApi.apiInstance(), 'updateField'], { data: field });
    yield put(updateDataFieldSuccessed(field));
  } catch (e) {
    yield put(updateFieldFailed(e));
  }
}

export function* updateSpecialField(route, field) {
  try {
    const value = yield call([DataApi.apiInstance(), 'updateSpecialField'], route, field.value);
    yield put(updateDataFieldSuccessed({ ...field, value }));
    const mappedField = yield call(reMapSpecialField, { ...field, value });
    yield put(updateSpecialFieldSuccessed(mappedField));
  } catch (e) {
    yield put(updateSpecialFieldFailed(e));
  }
}

export function* deleteSpecialField(route, { fieldName, fieldValue }) {
  try {
    yield call([DataApi.apiInstance(), 'deleteSpecialField'], route, fieldValue);
    yield put(deleteSpecialFieldSuccessed(fieldName));
  } catch (e) {
    yield put(deleteSpecialFieldFailed(e));
  }
}

export function* processSpecialField(fieldId) {
  let updates = [];
  let deleteData = null;
  let ignoreProperties = [];
  let payload = null;
  const type = (fieldId.match(/property_bedrooms|property_bathrooms|property_amenities/g) || [null])[0];
  const field = yield select(getFormFieldInfoJS, fieldId);
  const dataValue = yield select(getDataFieldValue, field);

  let removed = yield select(getFormFieldInfoJS, `${fieldId}_removed`);

  switch (type) {
    case 'property_bedrooms':
    case 'property_bathrooms': {
      const counter = yield select(getFormFieldInfoJS, `${fieldId}_count`);

      if (fieldId === 'property_bedrooms') {
        const bedsRemoved = yield select(getFormFieldInfoJS, 'property_beds_removed');
        const deleteDataBeds = yield call(mapBedsRemovedField, bedsRemoved);
        const deleteDataBedrooms = yield call(mapBedroomsRemovedField, removed);
        updates = updates.concat(
          (deleteDataBeds.length &&
            fork(deleteSpecialField, `/property_beds`, { fieldName: `beds_removed`, fieldValue: deleteDataBeds })) ||
            [],
        );
        deleteData = deleteDataBedrooms;
        const commonSpace = yield select(getFormFieldInfoJS, 'property_common_space');
        payload = yield call(mapBedroomsField, field.value, commonSpace);
      }

      if (fieldId === 'property_bathrooms') {
        deleteData = yield call(mapBathroomsRemovedField, removed);
        payload = yield call(mapBathroomsField, field.value);
      }
      updates = updates.concat((payload[type].length && fork(updateField, counter)) || []);
      break;
    }

    case 'property_amenities': {
      deleteData = yield call(mapAmenitiesRemovedField, removed);
      payload = yield call(mapAmenitiesField, field.value);
      ignoreProperties = ['rental_id'];
      break;
    }

    default:
      return;
  }

  if (type) {
    updates = updates
      .concat(
        (shouldUpdate(fromJS(payload[type]), dataValue, ignoreProperties) &&
          fork(updateSpecialField, `/${type}/`, { ...field, value: payload })) ||
          [],
      )
      .concat(
        (deleteData &&
          deleteData.length &&
          fork(deleteSpecialField, `/${type}`, { fieldName: `${fieldId}_removed`, fieldValue: deleteData })) ||
          [],
      );
    updates.length && (yield all(updates));
  }
}

export function* updateSpecialFields({ payload: { fields } }) {
  yield all(fields.map(fieldId => call(processSpecialField, fieldId)));
}

function mapDataForComparison(data, ignoreProperties) {
  const mappedData = data.sortBy(item => item.get('id'));
  if (!ignoreProperties.length) return mappedData;
  return mappedData.map(item => {
    let _item = item;
    ignoreProperties.forEach(property => {
      _item = _item.remove(property);
    });
    return _item;
  });
}

function shouldUpdate(payload, data, ignoreProperties) {
  const oldData = mapDataForComparison(data, ignoreProperties);
  const newData = mapDataForComparison(payload, ignoreProperties);
  return payload.size && !oldData.equals(newData);
}
