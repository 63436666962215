import { compose, withProps } from 'recompose';
import RawFileUpload from 'components/molecules/FileUpload/FileUpload';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filesAdded, fileRemoved } from 'ducks/form/form';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchFilesAdded: filesAdded,
      dispatchFileRemoved: fileRemoved,
    },
    dispatch,
  );

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    noResults: 'TEXT.SHARED.NO_RESULTS',
    brand: 'TEXT.SHARED.BRAND',
    location: 'TEXT.SHARED.LOCATION',
    model: 'TEXT.SHARED.MODEL',
    selectType: 'TEXT.SHARED.SELECT_TYPE',
    uploading: 'TEXT.SHARED.UPLOADING',
    deleting: 'TEXT.SHARED.DELETING',
    uploadFilePrompt: 'TEXT.SHARED.UPLOAD_FILE_PROMPT',
    uploadHint: 'TEXT.SHARED.UPLOAD_HINT',
    video: 'TEXT.SHARED.VIDEO',
    descriptionPrompt: 'TEXT.SHARED.DESCRIPTION_PROMPT',
    documentFormatError: 'TEXT.SHARED.DOCUMENT_FORMAT_ERROR',
  }),
  withProps(props => ({
    handleUpload: props.dispatchFilesAdded,
    handleDelete: props.dispatchFileRemoved,
  })),
)(RawFileUpload);
