import styled from 'styled-components';

const BaseGridColumns = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-columns: 14vw minmax(0, 720px) 1fr;
  grid-template-columns: 14vw minmax(0, 720px) 1fr;
  ${props => props.theme.media.desktop`
    grid-template-columns: 10vw minmax(0, 720px) 1fr;
    grid-template-columns: 10vw minmax(0, 720px)  1fr;
  `};
  ${props => props.theme.media.mobile`
    grid-template-columns: minmax(0, 720px) 1fr;
  `};
  ${props => props.theme.media.mobileSmall`
    grid-template-columns: 100%;
  `};
`;

export default BaseGridColumns;
