import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { select } from 'd3-selection';
import { arc } from 'd3-shape';
import { interpolate } from 'd3-interpolate';
// eslint-disable-next-line
import { transition } from 'd3-transition';

import Icon from 'components/atoms/Icon/Icon';

export const BaseCircle = styled.div`
  transition: ${props => props.theme.transitions.default};
  background-color: ${props => props.theme.colors.white};
  width: ${props => props.theme.helpers.rem(props.circleSize)};
  height: ${props => props.theme.helpers.rem(props.circleSize)};
  ${props => props.theme.media.desktop`
        width: ${props => props.theme.helpers.rem(props.circleSizeDesktop)};
        height: ${props => props.theme.helpers.rem(props.circleSizeDesktop)};
      `};
  border-radius: 50%;
  box-shadow: 0 0.3125rem 0.8125rem rgba(0, 0, 0, 0.07);
`;

export const StyledProgressIcon = styled.div`
  display: -ms-grid;
  display: grid;
  justify-items: center;
  align-items: center;
  transition: ${props => props.theme.transitions.default};
  * {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-align: center;
    -ms-grid-row-align: center;
  }

  .progress-bar {
    pointer-events: none;
    width: ${props => props.theme.helpers.rem(props.circleSize + props.barWidth * 2)};
    height: ${props => props.theme.helpers.rem(props.circleSize + props.barWidth * 2)};
    ${props => props.theme.media.desktop`
          width: ${props => props.theme.helpers.rem(props.circleSizeDesktop + props.barWidthDesktop * 2)};
          height: ${props => props.theme.helpers.rem(props.circleSizeDesktop + props.barWidthDesktop * 2)};
        `};
    path {
      transition: none;
      stroke: ${props => props.theme.colors.primary};
      stroke-width: ${props => props.barWidth};
      stroke-linejoin: round;
      ${props => props.theme.media.desktop`
            stroke-width: ${props.barWidthDesktop};
        `};
    }
  }
`;

export const StyledIcon = styled(Icon)`
  transition: ${props => props.theme.transitions.default};
  ${props => props.theme.media.desktop`
        width: ${props => props.theme.helpers.rem(props.iconSizeDesktop)};
        height: ${props => props.theme.helpers.rem(props.iconSizeDesktop)};
      `};
  path {
    transition: ${props => props.theme.transitions.default};
    fill: ${props => props.theme.colors.n800};
  }
`;

class ProgressIcon extends Component {
  constructor(props) {
    super(props);
    this.progressArc = this.progressArc.bind(this);
    this.d3Node = null;
  }

  componentDidMount() {
    this.d3Node = select(this.node);
    const { progress } = this.props;
    this.init(progress);
    this.update(progress);
  }

  UNSAFE_componentWillReceiveProps({ progress }) {
    this.update(progress, this.props.progress);
  }

  shouldComponentUpdate() {
    // let d3 handle DOM updates for this component
    return false;
  }

  progressArc(d) {
    return arc()
      .innerRadius(this.props.circleSize / 2)
      .outerRadius(this.props.circleSize / 2)
      .startAngle(0)
      .cornerRadius(+this.props.circleSize)(d);
  }

  arcTween(newAngle) {
    const self = this;
    return function(d) {
      var interpolateArc = interpolate(d.endAngle, newAngle);
      return function(t) {
        d.endAngle = interpolateArc(t);
        return self.progressArc(d);
      };
    };
  }

  init() {
    this.d3Node
      .selectAll('.progress-bar path')
      .datum({ endAngle: 0 })
      .attr('d', this.progressArc);
  }

  update(progress, prevProgress = null) {
    this.d3Node
      .selectAll('.progress-bar path')
      .transition()
      .duration(this.props.duration * Math.abs(prevProgress ? progress - prevProgress : progress))
      .attrTween('d', this.arcTween(progress * Math.PI * 2));
  }

  render() {
    const svgSize = this.props.circleSize + this.props.barWidth * 2;

    return (
      <div ref={node => (this.node = node)} className={this.props.className}>
        <StyledProgressIcon
          barWidth={this.props.barWidth}
          barWidthDesktop={this.props.barWidthDesktop}
          circleSize={this.props.circleSize}
          circleSizeDesktop={this.props.circleSizeDesktop}
        >
          <BaseCircle circleSize={this.props.circleSize} circleSizeDesktop={this.props.circleSizeDesktop} />
          <svg className="progress-bar" height={svgSize} width={svgSize} viewBox={`0 0 ${svgSize} ${svgSize}`}>
            <g transform={`translate(${svgSize / 2} ${svgSize / 2})`}>
              <path />
            </g>
          </svg>
          <StyledIcon icon={this.props.icon} size={this.props.iconSize} iconSizeDesktop={this.props.iconSizeDesktop} />
        </StyledProgressIcon>
      </div>
    );
  }
}

ProgressIcon.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  circleSize: PropTypes.number,
  iconSizeDesktop: PropTypes.number,
  circleSizeDesktop: PropTypes.number,
  barWidth: PropTypes.number,
  barWidthDesktop: PropTypes.number,
  className: PropTypes.string,
  duration: PropTypes.number,
};

ProgressIcon.defaultProps = {
  iconSize: 28,
  circleSize: 60,
  iconSizeDesktop: 22,
  circleSizeDesktop: 50,
  barWidth: 3,
  barWidthDesktop: 3,
  duration: 2000,
};

export default ProgressIcon;
