import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CloseButton from '../../atoms/CloseButton/CloseButton';

const Wrapper = styled.div`
  grid-area: modal;
  width: 40rem;
  min-height: 23rem;
  ${props => props.theme.media.mobile`
    min-height: 100vh;
    width: 100vw;
  `};
  border-radius: 0.3125rem;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.modal};
  padding: 1rem;
  display: grid;
  grid-template-rows: 1.875rem 1fr auto 2fr;
  grid-template-areas:
    '.'
    '.'
    'content'
    '.';
`;

const Content = styled.div`
  padding: 0 3.5rem;
  grid-area: content;
  max-width: 38rem;
  ${props => props.theme.media.mobile`
    padding: 0.25rem;
    justify-self: center;
  `};
  flex-grow: 1;
  text-align: center;
`;

const Modal = ({ closeButton, closeBtnClicked, children }) => {
  const closeBtn = closeButton && <CloseButton clicked={closeBtnClicked} />;
  return (
    <Wrapper>
      {closeBtn}
      <Content>{children}</Content>
    </Wrapper>
  );
};

Modal.propTypes = {
  closeButton: PropTypes.bool,
  closeBtnClicked: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default Modal;
