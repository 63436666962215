import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { H1, H4 } from 'components/atoms/Typography/Typography';
import Icon from 'components/atoms/Icon/Icon';

const StyledH1 = styled(H1)`
  margin-top: 0.25rem;
  margin-bottom: 0;
  line-height: 1;
`;

const StyledH4 = styled(H4)`
  margin-bottom: -0.3rem;
  margin-top: 0;
  color: ${props => props.theme.colors.textSecondary};
`;

const StyledIcon = styled(Icon)`
  fill: ${props => props.theme.colors.primary};
  margin-right: 1.25rem;
  ${props => props.theme.media.mobileSmall`
    width: 2.25rem;
    height: 2.25rem;
  `};
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  ${props => props.theme.media.mobileSmall`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

const TextWrapper = styled.div``;

const PageHeading = props => {
  const icon = props.icon && <StyledIcon size="65" icon={props.icon} />;
  return (
    <HeadingWrapper className={props.className}>
      {icon}
      <TextWrapper>
        <StyledH4>{props.sectionTitle}</StyledH4>
        <StyledH1>{props.children}</StyledH1>
      </TextWrapper>
    </HeadingWrapper>
  );
};

PageHeading.propTypes = {
  icon: PropTypes.string,
  sectionTitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: PropTypes.string,
};

export default PageHeading;
