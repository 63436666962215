import styled from 'styled-components';

const Error = styled.span`
  color: ${props => props.theme.colors.error};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.125rem;
  display: inline-block;
  font-weight: 500;
  white-space: pre-wrap;
`;

export default Error;
