import styled from 'styled-components';

const PageIconWrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.textSecondary};
  grid-area: page-icon;
  align-self: flex-end;
  justify-self: flex-end;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
  ${props => props.theme.media.mobile`
    justify-self: flex-start;
    border-bottom: none;
    padding-bottom: 0;
  `};
`;

export default PageIconWrapper;
