import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { SectionHeading, Text } from 'components/atoms/Typography/Typography';
import Field from 'scenes/Onboarding/components/Field';
import withWillUnmountHandler from 'hoc/withWillUnmountHandler/withWillUnmountHandler';
import withTranslations from 'hoc/withTranslations/withTranslations';
import { shouldShowSubsectionOrHeading } from '../../../../../../../utils/shouldShowSubsectionOrHeading';

const Page = ({ form, t, selectedFields }) => {
  return (
    <Fragment>
      {shouldShowSubsectionOrHeading(
        ['property_extra_information_cleaning_tools_location', 'property_extra_information_cleaning_tools_observation'],
        selectedFields,
      ) && (
        <>
          <SectionHeading>{t('CLEANING_AND_MAINTENANCE.HEADINGS.CLEANING_TOOLS')}</SectionHeading>
          <Text>{t('CLEANING_AND_MAINTENANCE.DESCRIPTIONS.CLEANING_TOOLS_WARNING')}</Text>
        </>
      )}
      {selectedFields.includes('property_extra_information_cleaning_tools_location') && (
        <Field
          name="property_extra_information_cleaning_tools_location.value"
          label={t('CLEANING_AND_MAINTENANCE.QUESTIONS.CLEANING_TOOLS_LOCATION.LABEL')}
          type="textarea"
          data={form.get('property_extra_information_cleaning_tools_location').toJS()}
        />
      )}
      {selectedFields.includes('property_extra_information_cleaning_tools_observation') && (
        <Field
          name="property_extra_information_cleaning_tools_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_extra_information_cleaning_tools_observation').toJS()}
          type="textarea"
        />
      )}
      {shouldShowSubsectionOrHeading(
        [
          'property_extra_information_cleaning_products_location',
          'property_extra_information_cleaning_products_observation',
        ],
        selectedFields,
      ) && <SectionHeading>{t('CLEANING_AND_MAINTENANCE.HEADINGS.CLEANING_PRODUCTS')}</SectionHeading>}
      {selectedFields.includes('property_extra_information_cleaning_products_location') && (
        <Field
          name="property_extra_information_cleaning_products_location.value"
          label={t('CLEANING_AND_MAINTENANCE.QUESTIONS.CLEANING_PRODUCTS_LOCATION.LABEL')}
          type="textarea"
          data={form.get('property_extra_information_cleaning_products_location').toJS()}
        />
      )}
      {selectedFields.includes('property_extra_information_cleaning_products_observation') && (
        <Field
          name="property_extra_information_cleaning_products_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_extra_information_cleaning_products_observation').toJS()}
          type="textarea"
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  cleaningTools: PropTypes.object.isRequired,
  cleaningProducts: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  cleaningTools: state.form
    .getIn(['property_amenities', 'view'])
    .filter(amenityView => amenityView.get('category') === 'cleaning_tools'),
  cleaningProducts: state.form
    .getIn(['property_amenities', 'view'])
    .filter(amenityView => amenityView.get('category') === 'cleaning_products'),
  form: state.form,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'CLEANING_AND_MAINTENANCE' in obj)
    .CLEANING_AND_MAINTENANCE,
});

const TPage = withTranslations(Page);
export default compose(
  withWillUnmountHandler(['property_amenities']),
  connect(mapStateToProps),
)(TPage);
