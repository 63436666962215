import { connect } from 'react-redux';
import { lifecycle, compose } from 'recompose';

import FinishedModalPage from 'components/pages/FinishedModalPage/FinishedModalPage';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

export const FinishedModalPageWithTranslations = withMappedTranslations({
  text1: 'TEXT.FINISH.THANK_YOU',
  text2: 'TEXT.FINISH.THANK_YOU_HIGHLIGHTED',
})(FinishedModalPage);

const mapStateToProps = state => ({
  logo: state.form.getIn(['property_manager_logo', 'value'], ''),
});

export default compose(
  connect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      this.props.history.block();
    },
  }),
)(FinishedModalPageWithTranslations);
