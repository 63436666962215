import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import AmenitiesGrid from 'components/atoms/AmenitiesGrid/AmenitiesGrid';
import Field from 'scenes/Onboarding/components/Field';
import AmenityInput from 'scenes/Onboarding/components/AmenityInput';
import { Text, SectionHeading } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';

import { AMENITY_CATEGORIES } from 'constants.js';

import withWillUnmountHandler from 'hoc/withWillUnmountHandler/withWillUnmountHandler';

const Page = ({ form, heatingAmenities, safetyAmenities, t, selectedFields }) => {
  return (
    <Fragment>
      <SectionHeading>{t('AMENITIES.HEADINGS.SAFETY')}</SectionHeading>
      <Text>{t('AMENITIES.DESCRIPTIONS.SAFETY')}</Text>
      <AmenitiesGrid>
        {safetyAmenities.map(amenityView => {
          const amenityValue = form.getIn(['property_amenities', 'value', amenityView.get('id')]);
          return (
            amenityView && (
              <AmenityInput
                list="property_amenities"
                key={amenityView.get('id')}
                view={amenityView.toJS()}
                value={amenityValue && amenityValue.toJS()}
              />
            )
          );
        })}
      </AmenitiesGrid>
      {selectedFields.includes('property_extra_information_safety_observation') && (
        <Field
          name="property_extra_information_safety_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_extra_information_safety_observation').toJS()}
          type="textarea"
        />
      )}
      <SectionHeading>{t('AMENITIES.HEADINGS.HEATING_AND_COOLING')}</SectionHeading>
      <Text>{t('AMENITIES.DESCRIPTIONS.HEATING_AND_COOLING')}</Text>
      <AmenitiesGrid>
        {heatingAmenities.map(amenityView => {
          const amenityValue = form.getIn(['property_amenities', 'value', amenityView.get('id')]);
          return (
            amenityView && (
              <AmenityInput
                list="property_amenities"
                key={amenityView.get('id')}
                view={amenityView.toJS()}
                value={amenityValue && amenityValue.toJS()}
              />
            )
          );
        })}
      </AmenitiesGrid>
      {selectedFields.includes('property_extra_information_heating_and_cooling_observation') && (
        <Field
          name="property_extra_information_heating_and_cooling_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_extra_information_heating_and_cooling_observation').toJS()}
          type="textarea"
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  safetyAmenities: PropTypes.object.isRequired,
  heatingAmenities: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  heatingAmenities: state.form
    .getIn(['property_amenities', 'view'])
    .filter(amenityView => amenityView.get('category') === AMENITY_CATEGORIES.HEATING_AND_COOLING),
  safetyAmenities: state.form
    .getIn(['property_amenities', 'view'])
    .filter(amenityView => amenityView.get('category') === AMENITY_CATEGORIES.SECURITY),
  form: state.form,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'AMENITIES' in obj).AMENITIES,
});

const TPage = withTranslations(Page);
export default compose(
  withWillUnmountHandler(['property_amenities']),
  connect(mapStateToProps),
)(TPage);
