import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Thumbnail, ThumbnailGroup, Wrapper } from './components';
import Error from 'components/molecules/Field/components/Error';
export { ThumbnailWrapper as Thumbnail, OverlayText } from './components';

const PhotoUpload = ({ t, value, handleUpload, handleDelete, handleUpdate, className }) => {
  const [tempImages, setTempImages] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setTempImages([]);
  }, [value]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setError(null);
      const filesToUpload = acceptedFiles.map(file => ({
        name: file.name,
        uploading: true,
        deleting: false,
        editing: true,
        blob: URL.createObjectURL(file),
        type: file.type,
        file,
      }));

      setTempImages(filesToUpload);
      handleUpload(filesToUpload);
      if (rejectedFiles.length) {
        setError(t.fileFormatError);
      }
    },
    [handleUpload, t],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/*, video/*',
  });

  return (
    <Wrapper isDragActive={isDragActive} {...getRootProps()} className={className}>
      <ThumbnailGroup>
        {value
          .sort((a, b) => +a.id - +b.id)
          .map(image => (
            <Thumbnail
              onEdit={handleUpdate ? description => handleUpdate({ id: image.id, description }) : null}
              title={image.details}
              onRemove={() => handleDelete(image)}
              key={image.id}
              image={image}
              t={t}
            />
          ))}
        {tempImages.map(image => (
          <Thumbnail key={image.id} image={image} t={t} />
        ))}
        <Thumbnail onClick={open} t={t} />
      </ThumbnailGroup>
      <input {...getInputProps()} />
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

PhotoUpload.propTypes = {
  limit: PropTypes.number,
  handleUpload: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.shape({
    descriptionPrompt: PropTypes.string,
    uploading: PropTypes.string,
    deleting: PropTypes.string,
    uploadPhotoPrompt: PropTypes.string,
    uploadHint: PropTypes.string,
    video: PropTypes.string,
    fileFormatError: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default PhotoUpload;
