import { Map, fromJS } from 'immutable';
import FIELD_LOCATIONS from './fieldLocations';

const isObject = value => Object.prototype.toString.call(value) === '[object Object]';

export const mapValidationErrors = (fields, resource = '') => {
  return Map(fields).flatMap((value, column) => {
    const key = `${resource}${resource && '.'}${column}`;
    if (isObject(value)) {
      return mapValidationErrors(value, key);
    }
    return [[key.replace(/\./g, '_'), { errors: value }]];
  });
};

const validationMapper = ({ errors = {}, warnings = {} }) => {
  const mappedFieldErrors = mapValidationErrors(errors.data);
  const mappedSidebarErrors = fromJS(FIELD_LOCATIONS).map(section => {
    return section
      .map(page => page.filter(field => mappedFieldErrors.keySeq().contains(field)))
      .filter(page => page.size);
  });

  return { sidebarErrors: mappedSidebarErrors, fieldErrors: mappedFieldErrors, warnings: fromJS(warnings) };
};

export default validationMapper;
