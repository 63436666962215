import { call, takeLatest } from 'redux-saga/effects';

import { SET_ONBOARDING_UUID } from '../../ducks/view/view';

export function* setUUID({ payload }) {
  yield call([localStorage, 'setItem'], 'onboarding_uuid', payload.uuid);
}

function* watchUUID() {
  yield takeLatest(SET_ONBOARDING_UUID, setUUID);
}

export default watchUUID;
