import styled from 'styled-components';

const Suffix = styled.span`
  color: ${props => props.theme.colors.n800};
  margin-left: 0.75rem;
  display: inline-block;
  font-weight: 500;
`;

export default Suffix;
