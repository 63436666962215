import ApiModule from './ApiModule';

class DataApi extends ApiModule {
  static apiInstance(uuid, locale) {
    if (this.instance) return this.instance;
    this.instance = new DataApi(uuid, locale);
    return this.instance;
  }

  finishForm() {
    return this.put('/finish/');
  }

  checkRequiredFields(data) {
    return this.put('/check_required_fields/', data);
  }

  getMetadata() {
    return this.options();
  }

  getAll() {
    return this.get();
  }

  updateField(field) {
    return this.put('/', field);
  }

  updateSpecialField(route, fields, options = {}) {
    if (options.method === 'put') {
      return this.put(route, fields);
    }
    return this.post(route, fields);
  }

  deleteSpecialField(route, ids) {
    return this.delete(route, ids);
  }

  updateLocation(location) {
    return this.put('/location/', { location });
  }
}

export default DataApi;
