import React from 'react';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';
import styled from 'styled-components';
import { withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { ICONS } from 'constants.js';

import Icon from 'components/atoms/Icon/Icon';

const StyledSelect = styled(ReactSelect)`
  &.is-disabled {
    .Select-control {
      cursor: default;
      user-select: none;
      border-color: ${props => props.theme.colors.n400};
      .Select-value-label {
        color: ${props => props.theme.colors.n400} !important;
      }
    }
  }
  &.is-open > {
    .Select-control {
      border-color: ${props => props.theme.colors.textSecondary} !important;
    }
    .Select-menu-outer {
      box-shadow: ${props => props.theme.shadows.default};
      z-index: 3;
      overflow: hidden;

      .Select-option {
        &.is-selected {
          background-color: ${props => props.theme.colors.n100};
        }
        &.is-focused {
          background-color: ${props => props.theme.colors.n200};
        }
      }
    }
  }
  &.is-focused {
    > .Select-control {
      background: ${props => props.theme.colors.white} !important;
    }
    &:not(.is-open) > {
      .Select-control {
        border-color: ${props => props.theme.colors.textSecondary} !important;
        box-shadow: ${props => props.theme.shadows.default} !important;
      }
    }
  }
  .Select-control,
  .Select-menu-outer {
    width: 20rem;
    border-color: ${props => props.theme.colors.textSecondary};
  }
  .Select-input > input {
    line-height: 1.25rem;
  }
  .Select-arrow-zone {
    width: 1.5625rem;
  }
  .Select-control {
    cursor: pointer;
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.textSecondary};

    &:hover {
      box-shadow: none;
    }
  }

  ${props => props.theme.media.mobileSmall`
    &, .Select-control, .Select-menu-outer {
      width: 100%;
    }
  `};
`;

const arrowRenderer = () => <Icon icon={ICONS.ARROW_DOWN} size="0.625em" />;

const Select = ({ handleOnChange, handleOnBlur, t, ...props }) => {
  return (
    <StyledSelect
      arrowRenderer={arrowRenderer}
      clearable={false}
      placeholder={t.placeholder}
      noResultsText={t.noResultsText}
      {...props}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
};

Select.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  handleOnBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  t: PropTypes.shape({
    noResultsText: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
};

const withOnChange = withHandlers({
  handleOnChange: props => option => {
    props.onChange({ target: { name: props.name, value: Map(option).get('value', '') } });
  },
  handleOnBlur: props => () => {
    props.onBlur && props.onBlur({ target: { name: props.name } });
  },
});

export default withOnChange(Select);
