import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ICONS } from '../../../constants';
import Icon from '../Icon/Icon';

const Button = styled.a`
  border-radius: 50%;
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  background: white;
  box-shadow: ${props => props.theme.shadows.default};
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  path {
    transition: ${props => props.theme.transitions.default};
  }
  &:hover,
  &:focus {
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }
`;

const MenuToggle = props => {
  return (
    <Button className={props.className} onClick={props.clicked}>
      <Icon size="20" icon={ICONS.GRID} />
    </Button>
  );
};

MenuToggle.propTypes = {
  className: PropTypes.string,
  clicked: PropTypes.func,
};

export default MenuToggle;
