import { compose, withProps } from 'recompose';
import RawAmenityInput from 'components/molecules/AmenityInput/AmenityInput';
import withAmenitySelectEventsHandlers from 'hoc/withAmenitySelectEventsHandlers/withAmenitySelectEventsHandlers';
import withAmenityPhotoHandlers from 'hoc/withAmenityPhotoHandlers/withAmenityPhotoHandlers';
import withOnClickHandler from 'hoc/withOnClickHandler/withOnClickHandler';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { AMENITY_INPUT_PLACEHOLDERS } from 'constants.js';

export default compose(
  withAmenitySelectEventsHandlers,
  withAmenityPhotoHandlers,
  withOnClickHandler,
  withMappedTranslations({
    noResults: 'TEXT.SHARED.NO_RESULTS',
    brand: 'TEXT.SHARED.BRAND',
    location: 'TEXT.SHARED.LOCATION',
    model: 'TEXT.SHARED.MODEL',
    selectType: 'TEXT.SHARED.SELECT_TYPE',
    uploading: 'TEXT.SHARED.UPLOADING',
    deleting: 'TEXT.SHARED.DELETING',
    uploadPhotoPrompt: 'TEXT.SHARED.UPLOAD_PHOTO_PROMPT',
    uploadHint: 'TEXT.SHARED.UPLOAD_HINT',
    video: 'TEXT.SHARED.VIDEO',
    descriptionPrompt: 'TEXT.SHARED.DESCRIPTION_PROMPT',
    fileFormatError: 'TEXT.SHARED.FILE_FORMAT_ERROR',
  }),
  withProps(props => ({
    warning: props.view.required ? props.intl.formatMessage({ id: 'TEXT.SHARED.REQUIRED' }) : '',
    t: {
      ...props.t,
      details: props.intl.formatMessage({
        id: AMENITY_INPUT_PLACEHOLDERS[props.view.key.toUpperCase()]
          ? `AMENITY_INPUT_PLACEHOLDERS.${props.view.key.toUpperCase()}.DETAILS`
          : 'TEXT.SHARED.DETAILS',
      }),
    },
  })),
)(RawAmenityInput);
