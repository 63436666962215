import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

const withLoadingDelayBehavior = WrapperComponent => {
  class LoadingButton extends Component {
    static getDerivedStateFromProps(props, state) {
      return { disabled: state.delaying || props.isFetching };
    }

    constructor(props) {
      super(props);
      this.state = {
        disabled: false,
        delaying: false,
      };
      this.handleClick = this.handleClick.bind(this);
    }

    componentWillUnmount() {
      clearTimeout(this.timeout);
    }

    handleClick() {
      this.setState({ disabled: true, delaying: true });
      this.timeout = setTimeout(
        function() {
          this.setState({ disabled: this.props.isFetching, delaying: false });
        }.bind(this),
        1000,
      );
      this.props.onClick();
    }

    render() {
      return <WrapperComponent {...this.props} onClick={this.handleClick} disabled={this.state.disabled} />;
    }
  }

  LoadingButton.propTypes = {
    isFetching: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };

  LoadingButton.defaultProps = {
    isFetching: false,
  };

  return LoadingButton;
};

export default withLoadingDelayBehavior(Button);
