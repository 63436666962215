import styled from 'styled-components';
import { withProps } from 'recompose';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

const ScrollWrapper = styled.div`
  grid-area: content;
  width: 100%;
  .simplebar-content {
    // to hide horizontal scrollbar
    // this issue may be related to CSS grid
    max-width: calc(100% + 14px);
  }
  ${props => props.theme.media.mobileSmall`
    // disable simplebar on mobile size
    .simplebar-track {
      display: none !important;
    }
    .simplebar-scroll-content, .simplebar-content {
      padding: 0 !important;
      margin: 0 !important;
      overflow: auto !important;
    }
  `};
`;

export default withProps(() => ({ 'data-simplebar': true }))(ScrollWrapper);
