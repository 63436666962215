export const presignPicture = file => {
  const fileType = encodeURIComponent(file.type);
  const fileName = encodeURIComponent(file.name);
  const url = `${process.env.REACT_APP_API_URL}/staffing/rental/form/presign_picture_s3/?type=${fileType}&name=${fileName}`;
  return fetch(url)
    .then(res => res.json())
    .catch(error => {
      throw new Error(error);
    });
};

export const uploadPresignedPicture = ({ data, file }) => {
  let formData = new FormData();

  for (let key in data.fields) {
    formData.append(key, data.fields[key]);
  }
  formData.append('file', file.file);

  return fetch(data.url, { method: 'POST', body: formData })
    .then(() => {
      return data.fields.key;
    })
    .catch(error => {
      throw new Error(error);
    });
};
