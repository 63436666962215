import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import { amenitiesItemAdded, amenitiesItemRemoved, amenitiesItemUpdated } from '../../ducks/form/form';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchAmenitiesItemAdded: amenitiesItemAdded,
      dispatchAmenitiesItemRemoved: amenitiesItemRemoved,
      dispatchAmenitiesItemUpdated: amenitiesItemUpdated,
    },
    dispatch,
  );

const withAmenitySelectEventsHandlers = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    handleToggle: props => (add, item) => {
      if (add) {
        props.dispatchAmenitiesItemAdded(item);
      } else {
        props.dispatchAmenitiesItemRemoved(item);
      }
    },
    onChange: props => event => {
      props.dispatchAmenitiesItemUpdated({ name: event.target.name, value: event.target.value });
    },
  }),
);

export default withAmenitySelectEventsHandlers;
