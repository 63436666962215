import styled from 'styled-components';

const SidebarGrid = styled.div`
  grid-area: sidebar;
  z-index: 1000;
  background-color: white;
  ${props => props.theme.media.tablet`
    grid-area: main;
    width: 350px;
    justify-self: flex-end;
    transform: ${props => (props.open ? 'none' : 'translate(375px, 0)')};
    transition: transform 0.5s ease-in-out;
  `};
  ${props => props.theme.media.mobile`
    width: ${props => (props.open ? '100vw' : '0')};
    margin-right: 0;
    box-shadow: none;
    transition: ${props => (props.open ? 'opacity 0.3s ease-in-out' : 'none')};
    opacity: ${props => (props.open ? '1' : '0')}
    visibility: ${props => (props.open ? 'visible' : 'hidden')}
  `};
`;

export default SidebarGrid;
