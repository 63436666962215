import { Map } from 'immutable';

const getDefaultValue = fieldType => {
  switch (fieldType) {
    case 'string':
      return '';
    default:
      return null;
  }
};

const ALLOWED_METADATA_KEYS = ['required', 'max_length'];

export const mapMetadata = (fields, resource = '') => {
  return Map(fields).flatMap((value, column) => {
    const key = `${resource}${resource && '.'}${column}`;
    if (value.type === 'nested object') {
      return mapMetadata(value.children, key);
    } else {
      const fieldMetadata = Map({
        ...value,
        default: getDefaultValue(value.type),
      }).filter((_, key) => [...ALLOWED_METADATA_KEYS, 'default'].indexOf(key) > -1);
      return [[key.replace(/\./g, '_'), fieldMetadata]];
    }
  });
};

export default metadata => mapMetadata(metadata.actions.PUT.data.children);
