import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import GettingInSection from './scenes/GettingInAndOut';
import PropertyDetailsSection from './scenes/PropertyDetails';
import AmenitiesSection from './scenes/Amenities';
import CleaningAndMaintenanceSection from './scenes/CleaningAndMaintenance';
import RulesSection from './scenes/Rules';
import CommunicationSection from './scenes/Communication';
import ListingSection from './scenes/Listing';
import PropertyPhotosSection from './scenes/PropertyPhotos';
import { PATHS } from '../../../../constants';
import { shouldHaveSection } from '../../../../utils/shouldHaveSection';

export const sectionOptions = [
  { id: 'GETTING_IN_AND_OUT', key: 'GETTING_IN_AND_OUT' },
  { id: 'PROPERTY_DETAILS', key: 'PROPERTY_DETAILS' },
  { id: 'AMENITIES', key: 'AMENITIES' },
  { id: 'CLEANING_AND_MAINTENANCE', key: 'CLEANING_AND_MAINTENANCE' },
  { id: 'RULES_AND_ADDITIONAL_INFO', key: 'RULES_AND_ADDITIONAL_INFO' },
  { id: 'COMMUNICATION_WITH_OUR_AGENTS', key: 'COMMUNICATION' },
  { id: 'YOUR_LISTING', key: 'LISTING' },
  { id: 'PROPERTY_PHOTOS', key: 'PROPERTY_PHOTOS' },
];

const FormRouter = ({ match, selectedFields, selectedSections }) => {
  const [displayedSections, setDisplayedSections] = useState([]);

  useEffect(() => {
    const newDisplayedSections = sectionOptions
      .filter(section => shouldHaveSection(selectedSections, selectedFields, section.id))
      .map(section => section.key);

    setDisplayedSections(newDisplayedSections);
  }, []);

  return (
    <Switch>
      {displayedSections && (
        <>
          {displayedSections.includes('GETTING_IN_AND_OUT') && (
            <Route
              path={`/:uuid/${PATHS.GETTING_IN_AND_OUT}`}
              render={() => <GettingInSection displayedSections={displayedSections} />}
            />
          )}
          {displayedSections.includes('PROPERTY_DETAILS') && (
            <Route
              path={`/:uuid/${PATHS.PROPERTY_DETAILS}`}
              render={() => <PropertyDetailsSection displayedSections={displayedSections} />}
            />
          )}
          {displayedSections.includes('AMENITIES') && (
            <Route
              path={`/:uuid/${PATHS.AMENITIES}`}
              render={() => <AmenitiesSection displayedSections={displayedSections} />}
            />
          )}
          {displayedSections.includes('CLEANING_AND_MAINTENANCE') && (
            <Route
              path={`/:uuid/${PATHS.CLEANING_AND_MAINTENANCE}`}
              render={() => <CleaningAndMaintenanceSection displayedSections={displayedSections} />}
            />
          )}
          {displayedSections.includes('RULES_AND_ADDITIONAL_INFO') && (
            <Route
              path={`/:uuid/${PATHS.RULES_AND_ADDITIONAL_INFO}`}
              render={() => <RulesSection displayedSections={displayedSections} />}
            />
          )}
          {displayedSections.includes('COMMUNICATION') && (
            <Route
              path={`/:uuid/${PATHS.COMMUNICATION}`}
              render={() => <CommunicationSection displayedSections={displayedSections} />}
            />
          )}
          {displayedSections.includes('LISTING') && (
            <Route
              path={`/:uuid/${PATHS.LISTING}`}
              render={() => <ListingSection displayedSections={displayedSections} />}
            />
          )}
          {displayedSections.includes('PROPERTY_PHOTOS') && (
            <Route
              path={`/:uuid/${PATHS.PROPERTY_PHOTOS}`}
              render={() => <PropertyPhotosSection displayedSections={displayedSections} />}
            />
          )}
        </>
      )}
      <Redirect to={`/${match.params.uuid}/welcome`} />
    </Switch>
  );
};

FormRouter.propTypes = {
  match: PropTypes.object.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.object),
  selectedSections: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  view: state.view,
  selectedFields: state.data.toJS().value.selected_fields,
  selectedSections: state.data.toJS().value.selected_sections,
});

export default compose(connect(mapStateToProps))(FormRouter);
