import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FieldRow from 'components/atoms/FieldRow/FieldRow';
import { SectionHeading } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';
import Field from 'scenes/Onboarding/components/Field';

const Page = ({ form, t, selectedFields }) => {
  return (
    <Fragment>
      <SectionHeading>{t('GETTING_IN_AND_OUT.HEADINGS.ADDRESS')}</SectionHeading>
      {selectedFields.includes('property_city') && (
        <Field
          label={t('GETTING_IN_AND_OUT.QUESTIONS.ADDRESS.CITY.LABEL')}
          name="property_city.value"
          value={form.getIn(['property_city', 'value'], '')}
          disabled
        />
      )}
      {selectedFields.includes('property_address') && (
        <Field
          label={t('GETTING_IN_AND_OUT.QUESTIONS.ADDRESS.STREET.LABEL')}
          name="property_address.value"
          value={form.getIn(['property_address', 'value'], '')}
          disabled
        />
      )}
      {selectedFields.includes('property_code_postal') && (
        <Field
          name="property_code_postal.value"
          value={form.getIn(['property_code_postal', 'value'], '')}
          label={t('GETTING_IN_AND_OUT.QUESTIONS.ADDRESS.POSTAL_CODE.LABEL')}
          disabled
        />
      )}
      <FieldRow error={form.getIn(['property_etage', 'errors']) || form.getIn(['property_flat_number', 'errors'])}>
        {selectedFields.includes('property_etage') && (
          <Field
            label={t('GETTING_IN_AND_OUT.QUESTIONS.ADDRESS.FLOOR.LABEL')}
            size="4"
            name="property_etage.value"
            data={form
              .get('property_etage')
              .delete('errors')
              .toJS()}
          />
        )}
        {selectedFields.includes('property_flat_number') && (
          <Field
            label={t('GETTING_IN_AND_OUT.QUESTIONS.ADDRESS.PROPERTY_NUMBER.LABEL')}
            name="property_flat_number.value"
            data={form
              .get('property_flat_number')
              .delete('errors')
              .toJS()}
          />
        )}
      </FieldRow>
      {selectedFields.includes('property_address_observation') && (
        <Field
          name="property_address_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_address_observation').toJS()}
          type="textarea"
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'GETTING_IN_AND_OUT' in obj).GETTING_IN_AND_OUT,
});

const TPage = withTranslations(Page);
export default connect(mapStateToProps)(TPage);
