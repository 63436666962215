import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const ButtonWrapper = styled.div`
  margin: 0 -0.35rem;
`;

const ButtonGroup = props => {
  return <ButtonWrapper>{props.children}</ButtonWrapper>;
};

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ButtonGroup;
