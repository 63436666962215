import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledSVG = styled.svg`
  width: ${props => props.theme.helpers.rem(props.size)};
  height: ${props => props.theme.helpers.rem(props.size)};
`;

const Icon = props => (
  <StyledSVG
    onClick={props.clicked}
    size={props.size}
    height={props.size}
    width={props.size}
    className={props.className}
    tabIndex={props.tabIndex}
    viewBox={props.viewBox}
  >
    <path d={props.icon} style={{ fill: props.color }} />
  </StyledSVG>
);

Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  color: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  viewBox: PropTypes.string,
  clicked: PropTypes.func,
};

Icon.defaultProps = {
  size: 32,
  viewBox: '0 0 1024 1024',
};

export default Icon;
