import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { shouldShowConditionalField } from 'utils/conditionalFields';
import Field from '../Field/Field';

const ConditionalField = ({ value, type, children, visibleForValues, visibleForValuesExcept, ...props }) => (
  <Fragment>
    {type && type !== 'wrapper' && <Field type={type} value={value} {...props} />}
    {shouldShowConditionalField(value, visibleForValues, visibleForValuesExcept) && children}
  </Fragment>
);

ConditionalField.propTypes = {
  children: PropTypes.node,
  type: function({ type }, propName, componentName) {
    const allowedTypes = ['radio-buttons', 'select', 'wrapper'];
    if (allowedTypes.indexOf(type) === -1) {
      return new Error(
        `The prop '${propName}' allowed values are '${allowedTypes.join(
          "', '",
        )}' at component '${componentName}', but its value is '${type}'`,
      );
    }
  },
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visibleForValues: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  visibleForValuesExcept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

ConditionalField.defaultProps = {
  children: null,
  value: '',
  visibleForValues: ['yes', 'other', 'others'],
  visibleForValuesExcept: [],
};

export default ConditionalField;
