import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledInput } from '../../../mixins';

const StyledTextArea = styled(StyledInput)`
  resize: vertical;
  min-height: 7.25rem;
  transition: ${props => props.theme.transitions.shadow.default};
`;

const TextArea = ({ children, value, ...props }) => {
  return (
    <StyledTextArea as="textarea" value={value || ''} {...props}>
      {children}
    </StyledTextArea>
  );
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default TextArea;
