import { lifecycle, compose, branch } from 'recompose';
import { connect } from 'react-redux';

import { updateFieldRequested } from 'ducks/form/form';

const mapDispatchToProps = {
  dispatchUpdateFieldRequested: updateFieldRequested,
};

const withComponentWillUnmount = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  lifecycle({
    componentWillUnmount() {
      this.props.dispatchUpdateFieldRequested({ name: this.props.fieldName }, { refresh: true });
    },
  }),
);

const DelayedUpdateWrapper = ({ delayedUpdate, fields }) => {
  return fields(delayedUpdate);
};

export default branch(props => props.delayedUpdate, withComponentWillUnmount)(DelayedUpdateWrapper);
