import { Map, fromJS } from 'immutable';

import { LOCATION_UPDATE_SUCCESSSED, FINISH_EDITING_SUCCESSED } from '../view/view';

export const UPDATE_DATA_FIELD_SUCCESSED = 'UPDATE_DATA_FIELD_SUCCESSED';
export const FETCH_DATA_REQUESTED = 'FETCH_DATA_REQUESTED';
export const FETCH_DATA_SUCCESSED = 'FETCH_DATA_SUCCESSED';
export const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED';

export function fetchDataRequested() {
  return {
    type: FETCH_DATA_REQUESTED,
  };
}

export function fetchDataSuccessed({ data, metadata }) {
  return {
    type: FETCH_DATA_SUCCESSED,
    payload: { data, metadata },
  };
}

export function fetchDataFailed(error) {
  return {
    type: FETCH_DATA_FAILED,
    payload: { error },
  };
}

export function updateDataFieldSuccessed(field) {
  return {
    type: UPDATE_DATA_FIELD_SUCCESSED,
    payload: { field },
  };
}

export const initialState = Map({
  didInvalidate: false,
  isFetching: false,
  isFetched: false,
  value: Map({}),
  warning: '',
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DATA_REQUESTED:
      return state.set('isFetching', true);

    case FETCH_DATA_SUCCESSED:
      return state.merge({ isFetching: false, isFetched: true, value: payload.data });

    case FETCH_DATA_FAILED:
      return state.merge({
        didInvalidate: true,
        isFetching: false,
        warning: 'Please try again later, something went wrong with connection.',
      });

    case UPDATE_DATA_FIELD_SUCCESSED:
      return state.setIn(
        ['value', 'data', ...payload.field.resource.split('.'), payload.field.column],
        fromJS(payload.field.value),
      );

    case LOCATION_UPDATE_SUCCESSSED:
      return state.setIn(['value', 'current_location'], payload.location);

    case FINISH_EDITING_SUCCESSED:
      return state.setIn(['value', 'status'], 'finished');

    default:
      return state;
  }
};
