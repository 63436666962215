import { Map, List } from 'immutable';

import { shouldShowConditionalField } from 'utils/conditionalFields';
import { mapBedroomsField, mapBathroomsField } from 'sagas/form/specialFields/specialFieldsMapper/specialFieldsMapper';

import fields, { conditionalFields, specialFields } from '../fields';

export function fieldCounter(fieldName, state) {
  const value = state.getIn(fieldName.split('.'), '');
  return (value !== '' && value !== null && value !== undefined && 1) || 0;
}

export function conditionalFieldCounter(parentFieldName, children, state, currentVal = Map({ sum: 0, count: 0 })) {
  let returnVal = Map(currentVal);
  const value = state.getIn(parentFieldName.split('.'), '');

  children.get('conditions').forEach(condition => {
    const visibleForValues = condition.get('visibleForValues', List([]));
    const visibleForValuesExcept = condition.get('visibleForValuesExcept', List([]));
    const shouldCount = shouldShowConditionalField(value, visibleForValues.toJS(), visibleForValuesExcept.toJS());

    if (condition.get('conditions')) {
      if (!shouldCount) {
        return Map({ sum: 0, count: 0 });
      }
      returnVal = conditionalFieldCounter(condition.get('fieldName'), condition, state, returnVal);
    } else {
      const fields = condition.get('fieldNames');
      const fieldCount = fields.size;
      let fieldSum = 0;

      if (shouldCount) {
        fieldSum = fields.reduce((sum, val) => sum + fieldCounter(val, state), 0);
      } else if (value || typeof value === 'boolean') {
        fieldSum = fields.size;
      }

      returnVal = returnVal.update('sum', value => value + fieldSum).update('count', value => value + fieldCount);
    }
  });

  return returnVal;
}

export function bedroomFieldCounter({ fields, mandatoryAttributes }, state) {
  const values = fields.map(fieldName => state.getIn(fieldName.split('.')).toJS());
  return Map({
    sum:
      (mapBedroomsField(...values).property_bedrooms.find(
        room => !mandatoryAttributes.find(att => !room[att] || !room[att].length),
      ) &&
        1) ||
      0,
    count: 1,
  });
}

export function bathroomFieldCounter({ fields, optionalAttributes }, state) {
  const values = fields.map(fieldName => state.getIn(fieldName.split('.')).toJS());
  return Map({
    sum:
      (mapBathroomsField(...values).property_bathrooms.find(room => optionalAttributes.find(att => room[att])) && 1) ||
      0,
    count: 1,
  });
}

export function defaultFieldsCounter(fieldsNames, state) {
  return Map({
    sum: fieldsNames.reduce((sum, fieldName) => sum + fieldCounter(fieldName, state), 0),
    count: fieldsNames.size,
  });
}

export function amenityFieldCounter({ categories, fieldName }, state) {
  const amenities = state.getIn(fieldName.split('.'));
  return Map({
    sum: categories.reduce(
      (sum, category) => sum + ((amenities.find(amenity => amenity.get('category') === category) && 1) || 0),
      0,
    ),
    count: categories.length,
  });
}

export function propertyPhotoFieldCounter({ fieldName }, state) {
  const photos = state.getIn(fieldName.split('.'));
  return Map({
    sum: photos.toList().size > 0 ? 1 : 0,
    count: 1,
  });
}

export function conditionalFieldsCounter(fields, state) {
  return fields.reduce((acc, children, parentFieldName) => {
    const counter = conditionalFieldCounter(parentFieldName, children, state);
    return Map({
      sum: acc.get('sum') + counter.get('sum'),
      count: acc.get('count') + counter.get('count'),
    });
  }, Map({ sum: 0, count: 0 }));
}

const specialFieldsCountersProcessors = {
  amenity: amenityFieldCounter,
  bedroom: bedroomFieldCounter,
  bathroom: bathroomFieldCounter,
  propertyPhotos: propertyPhotoFieldCounter,
};

export function specialFieldsCounter(fields, state) {
  return fields.reduce((info, field) => {
    const counter = specialFieldsCountersProcessors[field.get('type')](field.toJS(), state);
    return Map({
      sum: info.get('sum') + counter.get('sum'),
      count: info.get('count') + counter.get('count'),
    });
  }, Map({ sum: 0, count: 0 }));
}

const progressCounters = Map({
  checkInOut: ((fields, conditionalFields, state) => {
    const defaultCounter = defaultFieldsCounter(fields, state);
    const conditionalCounter = conditionalFieldsCounter(conditionalFields, state);
    return Map({
      sum: defaultCounter.get('sum') + conditionalCounter.get('sum'),
      count: defaultCounter.get('count') + conditionalCounter.get('count'),
    });
  }).bind(null, fields.get('checkInOut'), conditionalFields.get('checkInOut')),

  propertyDetails: ((fields, specialFields, conditionalFields, state) => {
    const defaultCounter = defaultFieldsCounter(fields, state);
    const specialCounter = specialFieldsCounter(specialFields, state);
    const conditionalCounter = conditionalFieldsCounter(conditionalFields, state);
    return Map({
      sum: defaultCounter.get('sum') + specialCounter.get('sum') + conditionalCounter.get('sum'),
      count: defaultCounter.get('count') + specialCounter.get('count') + conditionalCounter.get('count'),
    });
  }).bind(
    null,
    fields.get('propertyDetails'),
    specialFields.get('propertyDetails'),
    conditionalFields.get('propertyDetails'),
  ),

  amenities: specialFieldsCounter.bind(null, specialFields.get('amenities')),

  cleaning: defaultFieldsCounter.bind(null, fields.get('cleaning')),

  additionalInfo: ((fields, conditionalFields, state) => {
    const defaultCounter = defaultFieldsCounter(fields, state);
    const conditionalCounter = conditionalFieldsCounter(conditionalFields, state);
    return Map({
      sum: defaultCounter.get('sum') + conditionalCounter.get('sum'),
      count: defaultCounter.get('count') + conditionalCounter.get('count'),
    });
  }).bind(null, fields.get('additionalInfo'), conditionalFields.get('additionalInfo')),

  communication: defaultFieldsCounter.bind(null, fields.get('communication')),

  listing: defaultFieldsCounter.bind(null, fields.get('listing')),

  propertyPhotos: specialFieldsCounter.bind(null, specialFields.get('propertyPhotos')),
});

export function calculateProgressForAllSections(state) {
  return progressCounters.map(section => section(state));
}

export default progressCounters;
