import { Map, fromJS } from 'immutable';

const _mapBedroomData = bedroom =>
  bedroom.update('bed_set', bedSet => {
    return bedSet.flatMap(bedType =>
      bedType
        .get('beds')
        .map(bed => Map({ id: bed || null, bed_type: bedType.get('bed_type'), bedroom_id: bedroom.get('id') })),
    );
  });

const _mapCommonSpace = commonSpace => {
  const _commonSpace = fromJS(commonSpace);
  const sumValidBeds = _commonSpace
    .get('bed_set')
    .reduce((bedCount, bedSet) => bedCount + (bedSet.get('bed_type') ? bedSet.get('beds').size : 0), 0);

  if (sumValidBeds) {
    return [_commonSpace];
  }
  return [];
};

export const mapBedroomsField = (bedrooms = [], commonSpace) => {
  return {
    property_bedrooms: fromJS(bedrooms)
      .concat(_mapCommonSpace(commonSpace))
      .map(_mapBedroomData)
      .toJS(),
  };
};

export const mapBathroomsField = bathrooms => ({
  property_bathrooms: bathrooms.map(bathroom => ({
    ...bathroom.amenities,
  })),
});

export const mapAmenitiesField = amenities => {
  return {
    property_amenities: Map(amenities)
      .map(amenity =>
        Map(amenity)
          .remove('disabled')
          .remove('category')
          .set('amenity_id', parseInt(amenity.amenity_id, 10)),
      )
      .toList()
      .toJS(),
  };
};

export const mapBedroomsRemovedField = bedroomsRemoved => {
  return fromJS(bedroomsRemoved)
    .map(bedroom => bedroom.get('id'))
    .filter(id => id)
    .toJS();
};

export const mapBedsRemovedField = bedsRemoved => {
  return fromJS(bedsRemoved)
    .flatMap(bedSet => bedSet.get('beds'))
    .filter(id => id)
    .toJS();
};

export const mapBathroomsRemovedField = bathroomsRemoved =>
  bathroomsRemoved.map(bathroom => bathroom.amenities.id).filter(id => id);

export const mapAmenitiesRemovedField = amenitiesRemoved =>
  Map(amenitiesRemoved)
    .map(amenity => amenity.id)
    .filter(id => id)
    .toList()
    .toJS();
