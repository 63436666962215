import { css } from 'styled-components';
export { default as colors } from './colors';

export const transitions = {
  default: 'all 0.3s ease-in-out',
  fast: 'all 0.2s ease-in-out',
  shadow: {
    default: 'box-shadow 0.3s ease-in-out',
    fast: 'box-shadow 0.2s ease-in-out',
  },
};

export const icons = {
  scale: (original, size) => {
    if (typeof size === 'string' && size.match(/r?em$/)) {
      size = +size.replace(/[^\d.]/g, '') * 16;
    }
    return (original * +size) / 120;
  },
};

export const buttons = {
  shadowColor: hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgba(
          ${parseInt(result[1], 16)},
          ${parseInt(result[2], 16)},
          ${parseInt(result[3], 16)},
          0.1)`
      : null;
  },
};

export const helpers = {
  rem: size => {
    if (typeof size === 'string' && size.match(/r?em$/)) {
      return size;
    }
    return +('' + size).replace(/[^\d.]/g, '') / 16 + 'rem';
  },
};

export const shadows = {
  big: '0.125rem 0.8125rem 0.6875rem rgba(0, 0, 0, 0.1)',
  default: '0 0.375rem 0.6875rem rgba(0, 0, 0, 0.07)',
  small: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.05)',
  modal: '0 0.3125rem 0.8125rem rgba(0, 0, 0, 0.07)',
  focus: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.25)',
  iconButton: size => {
    return `
      0
      ${icons.scale(0.3125, size)}rem
      ${icons.scale(0.8125, size)}rem
      rgba(0, 0, 0, 0.07)`;
  },
  iconButtonHover: size => {
    return `
      ${icons.scale(0.125, size)}rem
      ${icons.scale(0.8125, size)}rem
      ${icons.scale(0.6875, size)}rem
      rgba(0, 0, 0, 0.1)`;
  },
  buttons: color => {
    const rgba = buttons.shadowColor(color);
    return `
      0.125rem
      0.8125rem
      0.6875rem
      ${rgba}`;
  },
};

export const sizes = {
  desktop: 1439,
  tablet: 1023,
  mobile: 767,
  mobileSmall: 479,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});
