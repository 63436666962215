import styled from 'styled-components';
import Icon from '../../../atoms/Icon/Icon';

const PageIcon = styled(Icon)`
  fill: ${props => props.theme.colors.primary};
  margin-right: 0.5rem;

  ${props => props.theme.media.tablet`
    margin-left: 1rem;
  `};

  ${props => props.theme.media.mobileSmall`
    width: 2.25rem;
    height: 2.25rem;
  `};
`;

export default PageIcon;
