import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SectionHeading } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';
import Field, { FieldWithOnClick, ConditionalFieldWithOnClick } from 'scenes/Onboarding/components/Field';
import { shouldShowSubsectionOrHeading } from '../../../../../../../utils/shouldShowSubsectionOrHeading';

const Page = ({ form, t, selectedFields }) => {
  return (
    <Fragment>
      {shouldShowSubsectionOrHeading(
        [
          'property_info_location',
          'property_code_immeuble_1',
          'property_code_immeuble_2',
          'property_access_badge',
          'property_building_key',
        ],
        selectedFields,
      ) && <SectionHeading>{t('GETTING_IN_AND_OUT.HEADINGS.GETTING_IN')}</SectionHeading>}
      {selectedFields.includes('property_info_location') && (
        <Field
          label={t('GETTING_IN_AND_OUT.QUESTIONS.PROPERTY_ACCESS.LABEL')}
          hint={t('GETTING_IN_AND_OUT.QUESTIONS.PROPERTY_ACCESS.HINT')}
          type="textarea"
          name="property_info_location.value"
          data={form.get('property_info_location').toJS()}
        />
      )}
      {selectedFields.includes('property_code_immeuble_1') && (
        <Field
          label={t('GETTING_IN_AND_OUT.QUESTIONS.INTERCOM_CODE_1.LABEL')}
          name="property_code_immeuble_1.value"
          data={form.get('property_code_immeuble_1').toJS()}
        />
      )}
      {selectedFields.includes('property_code_immeuble_2') && (
        <Field
          label={t('GETTING_IN_AND_OUT.QUESTIONS.INTERCOM_CODE_2.LABEL')}
          name="property_code_immeuble_2.value"
          data={form.get('property_code_immeuble_2').toJS()}
        />
      )}
      {selectedFields.includes('property_access_badge') && (
        <FieldWithOnClick
          label={t('GETTING_IN_AND_OUT.QUESTIONS.ACCESS_BADGE.LABEL')}
          type="radio-buttons"
          name="property_access_badge.value"
          data={form.get('property_access_badge').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_building_key') && (
        <FieldWithOnClick
          label={t('GETTING_IN_AND_OUT.QUESTIONS.BUILDING_KEY.LABEL')}
          type="radio-buttons"
          name="property_building_key.value"
          data={form.get('property_building_key').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {shouldShowSubsectionOrHeading(['property_lift'], selectedFields) && (
        <SectionHeading>{t('GETTING_IN_AND_OUT.HEADINGS.LIFT')}</SectionHeading>
      )}
      {selectedFields.includes('property_lift') && (
        <ConditionalFieldWithOnClick
          label={t('GETTING_IN_AND_OUT.QUESTIONS.LIFT.LABEL')}
          type="radio-buttons"
          name="property_lift.value"
          data={form.get('property_lift').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        >
          {selectedFields.includes('property_lift_code') && (
            <Field
              label={t('GETTING_IN_AND_OUT.QUESTIONS.LIFT_CODE.LABEL')}
              name="property_lift_code.value"
              data={form.get('property_lift_code').toJS()}
            />
          )}
        </ConditionalFieldWithOnClick>
      )}
      {shouldShowSubsectionOrHeading(['property_has_intercom'], selectedFields) && (
        <SectionHeading>{t('GETTING_IN_AND_OUT.HEADINGS.INTERCOM')}</SectionHeading>
      )}
      {selectedFields.includes('property_has_intercom') && (
        <ConditionalFieldWithOnClick
          label={t('GETTING_IN_AND_OUT.QUESTIONS.INTERCOM_V2.LABEL')}
          type="radio-buttons"
          name="property_has_intercom.value"
          data={form.get('property_has_intercom').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        >
          {selectedFields.includes('property_intercom') && (
            <Field
              label={t('GETTING_IN_AND_OUT.QUESTIONS.INTERCOM_V2.DETAIL')}
              type="textarea"
              name="property_intercom.value"
              data={form.get('property_intercom').toJS()}
            />
          )}
        </ConditionalFieldWithOnClick>
      )}
      {shouldShowSubsectionOrHeading(['property_extra_information_alarm_location'], selectedFields) && (
        <SectionHeading>{t('GETTING_IN_AND_OUT.HEADINGS.ALARM')}</SectionHeading>
      )}
      {selectedFields.includes('property_extra_information_alarm_location') && (
        <Field
          name="property_extra_information_alarm_location.value"
          label={t('GETTING_IN_AND_OUT.QUESTIONS.ALARM_V2.LABEL')}
          data={form.get('property_extra_information_alarm_location').toJS()}
          type="textarea"
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'GETTING_IN_AND_OUT' in obj).GETTING_IN_AND_OUT,
});

const TPage = withTranslations(Page);
export default connect(mapStateToProps)(TPage);
