import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextArea } from 'components/atoms/Input/Inputs';

const Wrapper = styled.div`
  padding: 1rem;
  color: ${props => props.theme.colors.n900};
`;

const Placeholder = styled.div`
  padding: 1rem;
  color: ${props => props.theme.colors.n400};
`;

const StyledTextArea = styled(TextArea)`
  margin: 0.4rem;
  padding: 0.6rem;
  width: 100%;
  color: ${props => props.theme.colors.n900};
`;

const Description = ({ description, editing, setEditing, onClick, onEdit, t }) => {
  const [tempValue, setTempValue] = useState(description);

  useEffect(() => setTempValue(description), [description]);

  if (editing) {
    return (
      <StyledTextArea
        onChange={e => setTempValue(e.target.value)}
        autoFocus
        onBlur={() => {
          onEdit(tempValue);
          setEditing(false);
        }}
        value={tempValue}
      />
    );
  } else {
    return description ? (
      <Wrapper onClick={onClick}>{description}</Wrapper>
    ) : (
      <Placeholder onClick={onClick}>{t.descriptionPrompt}</Placeholder>
    );
  }
};

Description.propTypes = {
  description: PropTypes.string,
  className: PropTypes.string,
};

export default Description;
