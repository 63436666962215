import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Input from '../../atoms/Input/Input';
import { StyledTertiaryButton } from '../../mixins';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  max-width: 500px;
`;

const FieldLabel = styled.span`
  color: ${props => props.theme.colors.n800};
  margin-bottom: 0.5rem;
  font-weight: 500;
  display: inline-block;
`;

const RowWrapper = styled.div`
  display: flex;
  margin-top: 0.1875rem;
  margin-bottom: 0.625rem;

  > * {
    flex-grow: 1;
    flex-basis: 6rem;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  min-width: 6rem;
  height: 36px;

  .Select-control,
  .Select-menu-outer {
    width: 100%;
    min-width: 6rem;
    .Select-value {
      width: calc(100% - 0.8625rem);
    }
  }
`;

const ActionsWrapper = styled.div`
  align-self: flex-end;
`;

const AddBedType = styled(StyledTertiaryButton)`
  font-weight: bold;
  letter-spacing: normal;
  color: ${props => props.theme.colors.n800};
`;

const RemoveBedType = styled(AddBedType)`
  color: ${props => props.theme.colors.textSecondary};
`;

const BedroomField = ({ name, bedTypes, label, bedroom, onChange, onSetBedCount, onAdd, onRemove, t }) => {
  const removeBeds = bedroom.bed_set.length > 1 && (
    <RemoveBedType name={name} tabIndex="0" onClick={onRemove}>
      {t.removeABed}
    </RemoveBedType>
  );
  const [...existingBeds] = bedroom.bed_set.map(bedSet => bedSet.bed_type);
  const beds = bedroom.bed_set.map((bed_set, i) => {
    return (
      <RowWrapper key={`${name}.bed_set.${i}`}>
        <StyledInput
          name={`${name}.bed_set.${i}`}
          type="number"
          showControls
          value={bed_set.beds.length}
          onClick={value => onSetBedCount({ count: value, fieldName: `${name}.bed_set.${i}` })}
          onChange={e => onSetBedCount({ count: e.target.value, fieldName: `${name}.bed_set.${i}` })}
        />
        <StyledInput
          name={`${name}.bed_set.${i}.bed_type`}
          type="select"
          options={bedTypes.filter(
            bedType => existingBeds.indexOf(bedType.value) === -1 || bedType.value === bed_set.bed_type,
          )}
          value={bed_set.bed_type}
          onChange={onChange}
          t={{
            placeholder: t.selectOption,
            noResultsText: t.noResultsText,
          }}
        />
      </RowWrapper>
    );
  });

  const addBeds = beds.length < bedTypes.length && (
    <AddBedType name={name} tabIndex="0" onClick={onAdd}>
      {t.addABed}
    </AddBedType>
  );

  return (
    <Wrapper>
      <FieldLabel>{label}</FieldLabel>
      {beds}
      <ActionsWrapper>
        {removeBeds}
        {addBeds}
      </ActionsWrapper>
    </Wrapper>
  );
};

BedroomField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  bedTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSetBedCount: PropTypes.func.isRequired,
  bedroom: PropTypes.shape({
    id: PropTypes.number,
    bed_set: PropTypes.arrayOf(
      PropTypes.shape({
        beds: PropTypes.arrayOf(PropTypes.number).isRequired,
        bed_type: PropTypes.string.isRequired,
      }),
    ).isRequired,
    bedroom_type: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.shape({
    noResultsText: PropTypes.string.isRequired,
    selectOption: PropTypes.string.isRequired,
    addABed: PropTypes.string.isRequired,
    removeABed: PropTypes.string.isRequired,
  }).isRequired,
};

export default BedroomField;
