import { compose, withProps } from 'recompose';
import { injectIntl } from 'react-intl';
import { Map } from 'immutable';

export const withMappedTranslations = ids =>
  compose(
    injectIntl,
    withProps(props => ({
      t: Map(ids).reduce(
        (translations, tid, key) => {
          translations[key] = props.intl.formatMessage({ id: tid });
          return translations;
        },
        { ...props.t },
      ),
    })),
  );

export default compose(
  injectIntl,
  withProps(props => ({
    t: (id, values, descriptor = {}) => props.intl.formatMessage({ id: id, ...descriptor }, values),
  })),
);
