import { List, Map, fromJS } from 'immutable';
import { AMENITY_CATEGORIES } from 'constants.js';

export const toggleableFields = ['^bathrooms_instruction$'];
export const arrayFields = ['^specific_rules$'];
export const specialFields = [
  ...arrayFields,
  ...toggleableFields,
  'phone',
  'flat_manager',
  'description',
  '^property_amenities$',
  '^bedrooms$',
  '^bathrooms$',
  '^photos$',
];

const isObject = value => Object.prototype.toString.call(value) === '[object Object]';
const valueOrDefault = (value, defaultValue = '') => ([null, undefined].indexOf(value) > -1 ? defaultValue : value);

export const mapDefaultFields = (fields, avoidFields, metadata = {}, resource = '') =>
  Map(fields)
    .filter((value, key) => !new RegExp(avoidFields.join('|')).exec(key))
    .flatMap((value, column) => {
      const key = `${resource}${resource && '.'}${column}`;
      if (isObject(value)) {
        return mapDefaultFields(value, avoidFields, metadata, key);
      }
      const fieldMetadata = metadata[key.replace(/\./g, '_')] || {};
      return Map().set(
        key.replace(/\./g, '_'),
        Map({
          ...fieldMetadata,
          column,
          resource,
          ...(typeof value === 'boolean'
            ? { value: (value && 'yes') || 'no' }
            : { value: valueOrDefault(value, fieldMetadata.default) }),
        }),
      );
    });

export const mapToggleableFields = (fields, useFields, metadata = {}, resource = '') =>
  Map(fields)
    .filter((value, key) => isObject(value) || new RegExp(useFields.join('|')).exec(key))
    .flatMap((value, column) => {
      const key = `${resource}${resource && '.'}${column}`;
      if (isObject(value)) {
        return mapToggleableFields(value, useFields, metadata, key);
      }
      const fieldMetadata = metadata[key.replace(/\./g, '_')] || {};
      return Map().set(
        key.replace(/\./g, '_'),
        Map({ ...fieldMetadata, column, resource, value: valueOrDefault(value), isEnabled: !!value }),
      );
    });

export const mapArrayFields = (fields, useFields, metadata = {}, resource = '') =>
  Map(fields)
    .filter((value, key) => isObject(value) || new RegExp(useFields.join('|')).exec(key))
    .flatMap((value, column) => {
      const key = `${resource}${resource && '.'}${column}`;
      if (isObject(value)) {
        return mapToggleableFields(value, useFields, metadata, key);
      }
      const fieldMetadata = metadata[key.replace(/\./g, '_')] || {};
      return Map().set(
        key.replace(/\./g, '_'),
        Map({ ...fieldMetadata, column, resource, value: valueOrDefault(value, []) }),
      );
    });

export const mapBathrooms = (bathrooms, property_id, codelist) => {
  const amenities = codelist.map(amenity => amenity.key);
  return Map({
    property_bathrooms_removed: List([]),
    property_bathrooms: Map({
      resource: 'property',
      column: 'bathrooms',
      view: { amenities },
      value: mapPropertyBathrooms(bathrooms, Map({ amenities }), property_id),
    }),
  });
};

export const mapPropertyBathrooms = (bathrooms, viewBathroom, property_id) => {
  const propertyBathrooms = List(
    (bathrooms || []).map((bathroom, index) =>
      Map({
        amenities: Map({ ...bathroom }).update('id', (value = null) => value),
        label: `Bathroom ${index + 1}`,
      }),
    ),
  );
  return (
    (propertyBathrooms.size && propertyBathrooms) ||
    List([
      Map({
        label: 'Bathroom 1',
        amenities: Map(viewBathroom.get('amenities').map(amenity => [amenity, false])).set('id', null),
      }),
    ])
  );
};

export const mapObjectWithID = (data, resource, column) =>
  fromJS({
    [`${resource}_${column}`]: {
      value: data[resource][column] || { id: null },
      resource,
      column,
    },
  });

export const mapObject = (data, resource, column) =>
  fromJS({
    [`${resource}_${column}`]: {
      value: data[resource][column] || {},
      resource,
      column,
    },
  });

const _mapBeds = bedroom =>
  bedroom.update('bed_set', bedSet =>
    bedSet.size
      ? bedSet
          .groupBy(bed => bed.get('bed_type'))
          .map((beds, bed_type) =>
            Map({
              beds: beds.map(bed => bed.get('id')),
              bedroom_id: bedroom.get('id'),
              bed_type,
            }),
          )
          .toList()
      : fromJS([{ bed_type: '', beds: [] }]),
  );

export const mapPropertyBedrooms = bedrooms => {
  const mappedBeds = fromJS(bedrooms || []).map(_mapBeds);
  return mappedBeds.filter(bedroom => bedroom.get('bedroom_type') !== 'LIVINGROOM');
};

export const mapPropertyCommonSpace = bedrooms => {
  const mappedBeds = fromJS(bedrooms || []).map(_mapBeds);
  return mappedBeds.filter(bedroom => bedroom.get('bedroom_type') === 'LIVINGROOM').first();
};

export const mapBedrooms = (bedrooms, property_id, codelist) => {
  const bed_locations = codelist.bed_locations.reduce(
    (bedLocations, { label, value }) => bedLocations.set(value, label),
    Map({}),
  );
  const bed_types = codelist.bed_types.reduce((bedLocations, { value }) => bedLocations.push(value), List([]));

  const propertyBedrooms = mapPropertyBedrooms(bedrooms);
  const commonSpace = mapPropertyCommonSpace(bedrooms);

  return Map({
    property_bedrooms_removed: List([]),
    property_beds_removed: List([]),
    property_bedrooms: Map({
      resource: 'property',
      column: 'bedrooms',
      value: propertyBedrooms,
      view: Map({
        bed_locations,
        bed_types,
      }),
    }),
    property_common_space_removed: List([]),
    property_common_space: commonSpace
      ? commonSpace.update('bed_set', value => (value.size ? value : fromJS([{ bed_type: '', beds: [] }])))
      : Map({
          id: null,
          bedroom_type: 'LIVINGROOM',
          bed_set: fromJS([{ bed_type: '', beds: [] }]),
        }),
  });
};

export const mapAmenityCategory = amenity => {
  const DEFAULT_CATEGORY = AMENITY_CATEGORIES.GENERAL;
  if (amenity.category && Object.values(AMENITY_CATEGORIES).indexOf(amenity.category.key) !== -1) {
    return amenity.category.key;
  } else {
    return DEFAULT_CATEGORY;
  }
};

export const mapPropertyAmenities = (amenities, viewAmenities) => {
  return Map(
    List(amenities).map(amenity => [
      `${amenity.amenity_id}`,
      Map(amenity)
        .set('amenity_id', `${amenity.amenity_id}`)
        .set('details', valueOrDefault(amenity.details))
        .set('brand', valueOrDefault(amenity.brand))
        .set('location', valueOrDefault(amenity.location))
        .set('model', valueOrDefault(amenity.model))
        .set('category', findCategory(viewAmenities, `${amenity.amenity_id}`))
        .set('pictures', fromJS(amenity.pictures || [])),
    ]),
  );
};

export const mapPropertyHasWifi = (amenities, amenitiesCodeList) => {
  const wifi_amenity = amenitiesCodeList.find(amenity => amenity.key === 'internet') || {};
  const has_wifi_amenity = amenities.find(amenity => +amenity.amenity_id === +wifi_amenity.id);
  return has_wifi_amenity ? 'yes' : 'no';
};

export const mapPropertyPhotos = photos => {
  const mappedPhotos = Map(List(photos).map(photo => [`${photo.id}`, Map(photo)]));
  return Map({
    property_photos: Map({
      resource: 'property',
      column: 'property_photos',
      value: mappedPhotos,
    }),
  });
};

export const mapDocuments = documents => {
  const mappedDocuments = Map(List(documents).map(document => [`${document.id}`, Map(document)]));
  return Map({
    property_documents: Map({
      resource: 'property',
      column: 'property_documents',
      value: mappedDocuments,
    }),
  });
};

function findCategory(viewAmenities, amenityViewId) {
  return (viewAmenities.find(viewAmenity => viewAmenity.get('id') === amenityViewId) || Map({})).get('category', '');
}

export const flatCodelistAmenities = amenities =>
  fromJS(
    amenities
      .map(amenity =>
        fromJS(amenity)
          .set('id', `${amenity.id}`)
          .set('category', mapAmenityCategory(amenity)),
      )
      .sort((a, b) => {
        let aa = 0;
        let bb = 0;
        const nameA = a.get('name').toUpperCase();
        const nameB = b.get('name').toUpperCase();

        const aHasDetails = a.get('show_detailed_fields') || a.get('kinds').size;
        const bHasDetails = b.get('show_detailed_fields') || b.get('kinds').size;

        if (aHasDetails && !bHasDetails) aa = 2;
        if (!aHasDetails && bHasDetails) bb = 2;

        nameA > nameB && bb++;
        nameB > nameA && aa++;

        if (aa > bb) return -1;
        if (bb > aa) return 1;
        return 0;
      }),
  );

export const mapAmenities = (amenitiesCodeList, propertyData) => {
  const viewAmenities = flatCodelistAmenities(amenitiesCodeList);
  return Map({
    property_amenities: Map({
      resource: 'property',
      column: 'property_amenities',
      view: viewAmenities,
      value: mapPropertyAmenities(propertyData.property_amenities, viewAmenities),
    }),
    property_amenities_removed: Map({}),
  });
};

export const mapFrontendOnlyFields = (amenitiesCodeList, data) => {
  return Map({
    property_has_wifi: Map({
      resource: 'property',
      column: 'has_wifi',
      value: mapPropertyHasWifi(data.property.property_amenities, amenitiesCodeList),
    }),
  });
};

export const setRoomsCountValues = ({ bedrooms_count, bathrooms_count }) =>
  fromJS({
    property_bedrooms_count: { value: valueOrDefault(bedrooms_count, 0) },
    property_bathrooms_count: { value: valueOrDefault(bathrooms_count, 1) },
  });

const mapData = (codelist, metadata, { data }) =>
  Map()
    .merge(mapDefaultFields(data, specialFields, metadata))
    .merge(mapToggleableFields(data, toggleableFields, metadata))
    .merge(mapAmenities(codelist.amenities, data.property))
    .merge(mapObjectWithID(data, 'property', 'flat_manager'))
    .merge(mapPropertyPhotos(data.property.photos))
    .merge(mapObjectWithID(data, 'property', 'description'))
    .merge(mapBathrooms(data.property.bathrooms, data.property.id, codelist.bathrooms))
    .merge(mapBedrooms(data.property.bedrooms, data.property.id, codelist))
    .mergeDeep(setRoomsCountValues(data.property))
    .merge(mapArrayFields(data, arrayFields, metadata))
    .merge(mapFrontendOnlyFields(codelist.amenities, data))
    .merge(mapDocuments(data.property.documents));

export default mapData;
