import { compose, withProps } from 'recompose';
import RawPhotoUpload from 'components/molecules/PhotoUpload/PhotoUpload';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { propertyPhotosAdded, propertyPhotoUpdated, propertyPhotoRemoved } from 'ducks/form/form';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchPropertyPhotosAdded: propertyPhotosAdded,
      dispatchPropertyPhotoUpdated: propertyPhotoUpdated,
      dispatchPropertyPhotoRemoved: propertyPhotoRemoved,
    },
    dispatch,
  );

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withMappedTranslations({
    noResults: 'TEXT.SHARED.NO_RESULTS',
    brand: 'TEXT.SHARED.BRAND',
    location: 'TEXT.SHARED.LOCATION',
    model: 'TEXT.SHARED.MODEL',
    selectType: 'TEXT.SHARED.SELECT_TYPE',
    uploading: 'TEXT.SHARED.UPLOADING',
    deleting: 'TEXT.SHARED.DELETING',
    uploadPhotoPrompt: 'TEXT.SHARED.UPLOAD_PHOTO_PROMPT',
    uploadHint: 'TEXT.SHARED.UPLOAD_HINT',
    video: 'TEXT.SHARED.VIDEO',
    descriptionPrompt: 'TEXT.SHARED.DESCRIPTION_PROMPT',
    fileFormatError: 'TEXT.SHARED.FILE_FORMAT_ERROR',
  }),
  withProps(props => ({
    handleUpload: props.dispatchPropertyPhotosAdded,
    handleUpdate: props.dispatchPropertyPhotoUpdated,
    handleDelete: props.dispatchPropertyPhotoRemoved,
  })),
)(RawPhotoUpload);
