import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Description from 'components/molecules/Description/Description';
import { SectionHeading } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';
import Field, { FieldWithOnClick, ConditionalFieldWithOnClick } from 'scenes/Onboarding/components/Field';
import { shouldShowSubsectionOrHeading } from '../../../../../../../utils/shouldShowSubsectionOrHeading';

const Page = ({ form, t, selectedFields }) => {
  return (
    <Fragment>
      {shouldShowSubsectionOrHeading(
        ['property_padlock_possible', 'property_doors_lock_when_closed', 'property_indication_depot_clef'],
        selectedFields,
      ) && <SectionHeading>{t('GETTING_IN_AND_OUT.HEADINGS.GETTING_OUT')}</SectionHeading>}
      {selectedFields.includes('property_padlock_possible') && (
        <ConditionalFieldWithOnClick
          label={t('GETTING_IN_AND_OUT.QUESTIONS.POSSIBLE_TO_PUT_LOCK.LABEL')}
          type="radio-buttons"
          name="property_padlock_possible.value"
          data={form.get('property_padlock_possible').toJS()}
          hint={t('GETTING_IN_AND_OUT.QUESTIONS.POSSIBLE_TO_PUT_LOCK.HINT')}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        >
          {selectedFields.includes('property_padlock_location') && (
            <Field
              label={t('GETTING_IN_AND_OUT.QUESTIONS.POSSIBLE_TO_PUT_LOCK.DETAILS_LABEL')}
              type="textarea"
              name="property_padlock_location.value"
              data={form.get('property_padlock_location').toJS()}
            />
          )}
        </ConditionalFieldWithOnClick>
      )}
      {selectedFields.includes('property_doors_lock_when_closed') && (
        <FieldWithOnClick
          name="property_doors_lock_when_closed.value"
          label={t('GETTING_IN_AND_OUT.QUESTIONS.DO_DOORS_CLOSE.LABEL')}
          type="radio-buttons"
          data={form.get('property_doors_lock_when_closed').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_indication_depot_clef') && (
        <Field
          name="property_indication_depot_clef.value"
          label={t('GETTING_IN_AND_OUT.QUESTIONS.KEY_DEPOSIT_LOCATION.LABEL')}
          hint={t('GETTING_IN_AND_OUT.QUESTIONS.KEY_DEPOSIT_LOCATION.HINT')}
          type="textarea"
          data={form.get('property_indication_depot_clef').toJS()}
        />
      )}
      {shouldShowSubsectionOrHeading(['property_all_keys_tested', 'property_keys_observation'], selectedFields) && (
        <>
          <SectionHeading>{t('GETTING_IN_AND_OUT.HEADINGS.KEYS')}</SectionHeading>
          <Description>{t('GETTING_IN_AND_OUT.DESCRIPTIONS.KEYS')}</Description>
          <Description bold>{t('GETTING_IN_AND_OUT.DESCRIPTIONS.KEYS_IMPORTANT')}</Description>
        </>
      )}
      {selectedFields.includes('property_all_keys_tested') && (
        <FieldWithOnClick
          label={t('GETTING_IN_AND_OUT.QUESTIONS.KEYS_TESTED.LABEL')}
          type="radio-buttons"
          name="property_all_keys_tested.value"
          data={form.get('property_all_keys_tested').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_keys_observation') && (
        <Field
          name="property_keys_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_keys_observation').toJS()}
          type="textarea"
        />
      )}
      {shouldShowSubsectionOrHeading(
        [
          'property_metro_station',
          'property_parking',
          'property_parking_badge',
          'property_indication_parking',
          'property_transport_observation',
        ],
        selectedFields,
      ) && <SectionHeading>{t('GETTING_IN_AND_OUT.HEADINGS.TRANSPORT')}</SectionHeading>}
      {selectedFields.includes('property_metro_station') && (
        <Field
          label={t('GETTING_IN_AND_OUT.QUESTIONS.METRO_STATION.LABEL')}
          hint={t('GETTING_IN_AND_OUT.QUESTIONS.METRO_STATION.HINT')}
          name="property_metro_station.value"
          data={form.get('property_metro_station').toJS()}
        />
      )}
      {selectedFields.includes('property_parking') && (
        <FieldWithOnClick
          label={t('GETTING_IN_AND_OUT.QUESTIONS.PARKING_V2.LABEL')}
          type="radio-buttons"
          name="property_parking.value"
          data={form.get('property_parking').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_parking_badge') && (
        <FieldWithOnClick
          label={t('GETTING_IN_AND_OUT.QUESTIONS.PARKING_BADGE.LABEL')}
          type="radio-buttons"
          name="property_parking_badge.value"
          data={form.get('property_parking_badge').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_indication_parking') && (
        <Field
          label={t('GETTING_IN_AND_OUT.QUESTIONS.INDICATION_PARKING.LABEL')}
          hint={t('GETTING_IN_AND_OUT.QUESTIONS.INDICATION_PARKING.HINT')}
          type="textarea"
          name="property_indication_parking.value"
          data={form.get('property_indication_parking').toJS()}
        />
      )}
      {selectedFields.includes('property_transport_observation') && (
        <Field
          name="property_transport_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_transport_observation').toJS()}
          type="textarea"
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'GETTING_IN_AND_OUT' in obj).GETTING_IN_AND_OUT,
});

const TPage = withTranslations(Page);
export default connect(mapStateToProps)(TPage);
