import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import ProgressIcon, { BaseCircle, StyledIcon } from 'components/molecules/ProgressIcon/ProgressIcon';
import { ICONS } from 'constants.js';

const withErrorStyle = ({ variation }) =>
  variation === 'error' &&
  css`
    ${BaseCircle} {
      background: ${props => props.theme.colors.error};
    }

    ${StyledIcon} {
      path {
        fill: ${props => props.theme.colors.white};
      }
    }

    .progress-bar {
      path {
        stroke: none;
      }
    }
  `;

export const StyledProgressIcon = styled(ProgressIcon)`
  ${withErrorStyle}
`;

export const SummaryItem = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  box-shadow: ${props => props.theme.shadows.default};
  overflow: hidden;
  background: ${props => (props.variation === 'error' ? props.theme.colors.r100 : props.theme.colors.n100)};
  color: ${props => (props.variation === 'error' ? props.theme.colors.r800 : props.theme.colors.n800)};
  text-align: left;
  flex-shrink: 0;
  margin-bottom: 1.5rem;
`;

export const SummaryContent = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  padding-left: 1rem;
`;

const ProgressText = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
  padding: 1rem;
  padding-bottom: 10vh;
`;

export const ValidationWarnings = ({ validationWarnings, t }) => {
  if (!validationWarnings.missing_amenities) return null;
  return (
    <SummaryItem variation="error">
      <StyledProgressIcon progress={0} variation="error" icon={ICONS.AMENITIES} />
      <SummaryContent>
        {t.missingAmenitiesWarning}
        <strong>{validationWarnings.missing_amenities.join(', ')}</strong>
      </SummaryContent>
    </SummaryItem>
  );
};

ValidationWarnings.propTypes = {
  validationWarnings: PropTypes.shape({
    missing_amenities: PropTypes.arrayOf(PropTypes.string),
  }),
  t: PropTypes.shape({
    missingAmenitiesWarning: PropTypes.string.isRequired,
  }).isRequired,
};

export const FormProgress = ({ overallProgress, t }) => (
  <SummaryItem>
    <StyledProgressIcon progress={overallProgress} icon={ICONS.FORM_CHECK} />
    <SummaryContent>
      <span>
        {t.OVERALL_PROGRESS} <ProgressText>{`${Math.round(overallProgress * 100)}${t.PERCENT_COMPLETE}`}</ProgressText>
      </span>
    </SummaryContent>
  </SummaryItem>
);

FormProgress.propTypes = {
  overallProgress: PropTypes.number.isRequired,
  t: PropTypes.shape({
    OVERALL_PROGRESS: PropTypes.string.isRequired,
    PERCENT_COMPLETE: PropTypes.string.isRequired,
  }).isRequired,
};
