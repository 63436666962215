import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import WelcomePage from './scenes/WelcomePage/WelcomePage';
import WelcomeBackPage from './scenes/WelcomeBackPage/WelcomeBackPage';
import DisabledPage from './scenes/DisabledPage/DisabledPage';
import FinishPage from './scenes/FinishPage/FinishPage';
import FinishedModalPage from './scenes/FinishedModalPage/FinishedModalPage';
import SidebarToggle from './scenes/SidebarToggle/SidebarToggle';
import Sidebar from './scenes/Sidebar/Sidebar';
import Form from './scenes/Form/Form';

import OnboardingGrid from './components/OnboardingGrid';

const OnboardingRouter = () => (
  <Switch>
    <Route path="/:uuid/finish" exact component={FinishPage} />
    <Route path="/:uuid/finished" exact component={FinishedModalPage} />
    <Route path="/:uuid/disabled" exact component={DisabledPage} />
    <Route path="/:uuid/welcome" exact component={WelcomePage} />
    <Route path="/:uuid/welcomeback" exact component={WelcomeBackPage} />
    <Route
      render={props => (
        <OnboardingGrid>
          <Form {...props} />
          <SidebarToggle />
          <Sidebar {...props} />
        </OnboardingGrid>
      )}
    />
  </Switch>
);

OnboardingRouter.propTypes = {
  match: PropTypes.object.isRequired,
};

export default OnboardingRouter;
