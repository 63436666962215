import { compose } from 'recompose';
import PropTypes from 'prop-types';

import RawBedroomField from 'components/molecules/BedroomField/BedroomField';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';
import withOnChangeHandler from 'hoc/withOnChangeHandler/withOnChangeHandler';
import { withBedroomsEventsHandlers } from 'hoc/withRoomsEventsHandlers/withRoomsEventsHandlers';

import {
  bedTypeAdded as addAction,
  bedTypeRemoved as removeAction,
  bedCountChanged as setBedCountAction,
} from 'ducks/form/form';

const BedroomField = compose(
  withOnChangeHandler,
  withMappedTranslations({
    selectOption: 'TEXT.SHARED.SELECT_OPTION',
    noResultsText: 'TEXT.SHARED.NO_RESULTS',
    addABed: 'PROPERTY_DETAILS.QUESTIONS.BED_DETAILS.ADD',
    removeABed: 'PROPERTY_DETAILS.QUESTIONS.BED_DETAILS.REMOVE',
  }),
  withBedroomsEventsHandlers({
    addAction,
    removeAction,
    setBedCountAction,
  }),
)(RawBedroomField);

BedroomField.propTypes = {
  dataType: PropTypes.string.isRequired,
};

export default BedroomField;
