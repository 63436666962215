import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { amenitiesPhotosAdded, amenitiesPhotoRemoved } from '../../ducks/form/form';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { dispatchAmenitiesPhotosAdded: amenitiesPhotosAdded, dispatchAmenitiesPhotoRemoved: amenitiesPhotoRemoved },
    dispatch,
  );

const withAmenityPhotoHandlers = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withProps(props => {
    return {
      handleUpload: files =>
        props.dispatchAmenitiesPhotosAdded({ files, item: { list: props.list, id: props.view.id } }),
      handleDelete: ({ file, id }) =>
        props.dispatchAmenitiesPhotoRemoved({ file, id, item: { list: props.list, id: props.view.id } }),
    };
  }),
);

export default withAmenityPhotoHandlers;
