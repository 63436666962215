import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Thumbnail, ThumbnailGroup, Wrapper } from './components';
import Error from 'components/molecules/Field/components/Error';
export { ThumbnailWrapper as Thumbnail } from './components';

const FileUpload = ({ t, value, handleUpload, handleDelete, className, category }) => {
  const [error, setError] = useState();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setError(null);
      const filesToUpload = acceptedFiles.map(file => ({
        name: file.name,
        uploading: true,
        deleting: false,
        editing: true,
        blob: URL.createObjectURL(file),
        type: file.type,
        file,
      }));

      handleUpload({ files: filesToUpload, category });
      if (rejectedFiles.length) {
        setError(t.documentFormatError);
      }
    },
    [handleUpload, t],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: 'image/*, application/pdf',
  });

  return (
    <Wrapper isDragActive={isDragActive} {...getRootProps()} className={className}>
      <ThumbnailGroup>
        {value
          .filter(file => file.category === category)
          .sort((a, b) => +a.id - +b.id)
          .map(image => (
            <Thumbnail onRemove={() => handleDelete(image)} key={image.id} image={image} t={t} />
          ))}
        <Thumbnail onClick={open} t={t} />
      </ThumbnailGroup>
      <input {...getInputProps()} />
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

FileUpload.propTypes = {
  limit: PropTypes.number,
  handleUpload: PropTypes.func,
  handleDelete: PropTypes.func,
  handleUpdate: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.shape({
    uploading: PropTypes.string,
    deleting: PropTypes.string,
    uploadFilePrompt: PropTypes.string,
    uploadHint: PropTypes.string,
    video: PropTypes.string,
    documentFormatError: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  category: PropTypes.string.isRequired,
};

export default FileUpload;
