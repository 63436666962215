import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

import { StyledSecondaryButton } from '../../mixins';
import { H3 } from '../../atoms/Typography/Typography';
import CloseButton from '../../atoms/CloseButton/CloseButton';
import OverallProgress from '../../molecules/OverallProgress/OverallProgress';

const ScrollWrapper = styled.div`
  height: 100vh;
  box-shadow: ${props => props.theme.shadows.default};
  .simplebar-content {
    // to hide horizontal scrollbar
    // this issue may be related to CSS grid
    max-width: calc(100% + 14px);
  }
`;

const StyledSidebar = styled.div`
  display: grid;
  padding: 0 1rem;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  justify-items: center;
  > * {
    grid-column: 1;
  }
`;

const SidebarInnerWrapper = styled.div`
  display: grid;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  max-width: 20.25rem;
  opacity: ${props => props.isValidating && 0.4};
`;

const HeadingAndButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.8rem 0 1rem;
  min-height: 2.4rem;
  h3 {
    margin: 0;
  }
`;

const FullWidthWrapper = styled.div`
  margin: 0 -1rem;
  grid-row: 1;
  justify-self: stretch;
`;

const StyledCloseButton = styled(CloseButton)`
  display: none;
  grid-row: 1;
  margin-top: 1.25rem;
  margin-right: 0.25rem;
  ${props => props.theme.media.tablet`
    display: inline;
  `};
`;

const FinishEditingButton = styled(StyledSecondaryButton)`
  min-width: 0;
  line-height: 2.2rem;
  margin: 0;
  padding: 0 1.2rem;
  flex-shrink: 0;
`;

const Sidebar = ({ children, sidebarToggle, validateForm, isValidating, overallProgress, open, t }) => {
  return (
    <ScrollWrapper data-simplebar>
      <StyledSidebar open={open}>
        <FullWidthWrapper>{overallProgress && <OverallProgress t={t} progress={overallProgress} />}</FullWidthWrapper>
        <StyledCloseButton clicked={sidebarToggle} />
        <SidebarInnerWrapper isValidating={isValidating}>
          <HeadingAndButton>
            {isValidating ? (
              <H3>{t.PROCESSING}</H3>
            ) : (
              <Fragment>
                <H3>{t.SECTION_PROGRESS}</H3>
                <FinishEditingButton onClick={validateForm} type="secondary">
                  {t.FINISH_EDITING}
                </FinishEditingButton>
              </Fragment>
            )}
          </HeadingAndButton>
          {children}
        </SidebarInnerWrapper>
      </StyledSidebar>
    </ScrollWrapper>
  );
};

Sidebar.propTypes = {
  sidebarToggle: PropTypes.func,
  validateForm: PropTypes.func,
  open: PropTypes.bool,
  isValidating: PropTypes.bool,
  overallProgress: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  basePath: PropTypes.string.isRequired,
  t: PropTypes.shape({
    SECTION_PROGRESS: PropTypes.string.isRequired,
    FINISH_EDITING: PropTypes.string.isRequired,
    OVERALL_PROGRESS: PropTypes.string.isRequired,
    PERCENT_COMPLETE: PropTypes.string.isRequired,
    PROCESSING: PropTypes.string.isRequired,
  }).isRequired,
  search: PropTypes.string,
};

Sidebar.defaultProps = {
  search: '',
};

export default Sidebar;
