import { compose } from 'recompose';
import PropTypes from 'prop-types';

import RawBathroomField from 'components/molecules/BathroomField/BathroomField';

import withOnChangeHandler from 'hoc/withOnChangeHandler/withOnChangeHandler';
import withRoomsEventsHandlers from 'hoc/withRoomsEventsHandlers/withRoomsEventsHandlers';
import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

import { bathroomAdded as addAction, bathroomRemoved as removeAction } from 'ducks/form/form';

const BathroomField = compose(
  withOnChangeHandler,
  withMappedTranslations({
    addABathroom: 'PROPERTY_DETAILS.QUESTIONS.BATHROOM_AMENITIES.ADD',
    removeABathroom: 'PROPERTY_DETAILS.QUESTIONS.BATHROOM_AMENITIES.REMOVE',
  }),
  withRoomsEventsHandlers({ addAction, removeAction }),
)(RawBathroomField);

BathroomField.propTypes = {
  dataType: PropTypes.string.isRequired,
};

export default BathroomField;
