import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DelayedUpdateWrapper from 'scenes/Onboarding/components/DelayedUpdateWrapper/DelayedUpdateWrapper';
import Description from 'components/molecules/Description/Description';
import { DelayedUpdateField } from 'scenes/Onboarding/components/Field';
import withTranslations from 'hoc/withTranslations/withTranslations';
import { H3, Text } from 'components/atoms/Typography/Typography';
import { getListingContentFieldLengthsJS, getListingContentFieldsTotalLength } from 'selectors/form';
import { shouldShowSubsectionOrHeading } from '../../../../../../../utils/shouldShowSubsectionOrHeading';

const MaxLengthWarning = styled.span`
  background-color: ${props => (props.overLimit ? props.theme.colors.error : props.theme.colors.g500)};
  color: white;
  border-radius: 0.4rem;
  display: inline-block;
  padding: 0.2rem 0.4rem;
  font-size: 0.8rem;
  margin-top: 0.4rem;
`;

const DEFAULT_MAX_LENGTHS = {
  headline: 75,
  summary: 150,
  space: null,
  access: null,
  neighborhood: null,
  transit: null,
  notes: null,
};

// the following 5 fields have a combined limit of 10000 chars on BookingSync
const LISTING_CONTENT_FIELDS = {
  keys: ['space', 'access', 'neighborhood', 'transit', 'notes'],
  limit: 10000,
};

const Page = ({ form, t, listingContentFieldLengths, listingContentFieldsTotalLength, selectedFields }) => {
  const [maxLengths, setMaxLengths] = useState(DEFAULT_MAX_LENGTHS);
  const [overLimit, setOverLimit] = useState(false);

  if (!overLimit && listingContentFieldsTotalLength >= LISTING_CONTENT_FIELDS.limit) {
    // if the combined length goes over the limit, set the maxLength of all content fields to the current length
    setMaxLengths({ ...DEFAULT_MAX_LENGTHS, ...listingContentFieldLengths });
    setOverLimit(true);
  }

  if (overLimit && listingContentFieldsTotalLength < LISTING_CONTENT_FIELDS.limit) {
    // if the combined length goes back under the limit, set back to default maxLengths
    setMaxLengths(DEFAULT_MAX_LENGTHS);
    setOverLimit(false);
  }

  return (
    <Fragment>
      <Description>{t('LISTING.DESCRIPTION')}</Description>
      <DelayedUpdateWrapper
        delayedUpdate={!form.getIn(['property_description', 'value', 'id'])}
        fieldName="property_description.value"
        fields={delayedUpdate => (
          <Fragment>
            {selectedFields.includes('property_description.value.headline') && (
              <DelayedUpdateField
                delayedUpdate={delayedUpdate}
                name="property_description.value.headline"
                value={form.getIn(['property_description', 'value', 'headline'], '') || ''}
                label={t('LISTING.QUESTIONS.HEADLINE.LABEL')}
                maxLength={maxLengths.headline}
                showMaxLengthHint
              />
            )}
            {selectedFields.includes('property_description.value.summary') && (
              <DelayedUpdateField
                delayedUpdate={delayedUpdate}
                name="property_description.value.summary"
                value={form.getIn(['property_description', 'value', 'summary'], '') || ''}
                label={t('LISTING.QUESTIONS.SUMMARY.LABEL')}
                type="textarea"
                maxLength={maxLengths.summary}
                showMaxLengthHint
              />
            )}
            {shouldShowSubsectionOrHeading(
              [
                'property_description.value.space',
                'property_description.value.access',
                'property_description.value.neighborhood',
                'property_description.value.transit',
                'property_description.value.notes',
              ],
              selectedFields,
            ) && (
              <>
                <H3>{t('LISTING.CONTENT_FIELDS.TITLE')}</H3>
                <Text>
                  {t('LISTING.CONTENT_FIELDS.DESCRIPTION', { limit: LISTING_CONTENT_FIELDS.limit })}
                  <br />
                  <MaxLengthWarning overLimit={overLimit}>
                    {t('LISTING.CONTENT_FIELDS.CHARACTERS_USED', {
                      total: listingContentFieldsTotalLength,
                      limit: LISTING_CONTENT_FIELDS.limit,
                    })}
                  </MaxLengthWarning>
                </Text>
              </>
            )}
            {selectedFields.includes('property_description.value.space') && (
              <DelayedUpdateField
                delayedUpdate={delayedUpdate}
                name="property_description.value.space"
                value={form.getIn(['property_description', 'value', 'space'], '') || ''}
                label={t('LISTING.QUESTIONS.SPACE.LABEL')}
                type="textarea"
                maxLength={maxLengths.space}
              />
            )}
            {selectedFields.includes('property_description.value.access') && (
              <DelayedUpdateField
                delayedUpdate={delayedUpdate}
                name="property_description.value.access"
                value={form.getIn(['property_description', 'value', 'access'], '') || ''}
                label={t('LISTING.QUESTIONS.ACCESS.LABEL')}
                type="textarea"
                maxLength={maxLengths.access}
              />
            )}
            {selectedFields.includes('property_description.value.neighborhood') && (
              <DelayedUpdateField
                delayedUpdate={delayedUpdate}
                name="property_description.value.neighborhood"
                value={form.getIn(['property_description', 'value', 'neighborhood'], '') || ''}
                label={t('LISTING.QUESTIONS.NEIGHBORHOOD.LABEL')}
                type="textarea"
                maxLength={maxLengths.neighborhood}
              />
            )}
            {selectedFields.includes('property_description.value.transit') && (
              <DelayedUpdateField
                delayedUpdate={delayedUpdate}
                name="property_description.value.transit"
                value={form.getIn(['property_description', 'value', 'transit'], '') || ''}
                label={t('LISTING.QUESTIONS.TRANSIT.LABEL')}
                type="textarea"
                maxLength={maxLengths.transit}
              />
            )}
            {selectedFields.includes('property_description.value.notes') && (
              <DelayedUpdateField
                delayedUpdate={delayedUpdate}
                name="property_description.value.notes"
                value={form.getIn(['property_description', 'value', 'notes'], '') || ''}
                label={t('LISTING.QUESTIONS.NOTES.LABEL')}
                type="textarea"
                maxLength={maxLengths.notes}
              />
            )}
          </Fragment>
        )}
      />
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  listingContentFieldLengths: getListingContentFieldLengthsJS(state, LISTING_CONTENT_FIELDS.keys),
  listingContentFieldsTotalLength: getListingContentFieldsTotalLength(state, LISTING_CONTENT_FIELDS.keys),
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'YOUR_LISTING' in obj).YOUR_LISTING,
});

const TPage = withTranslations(Page);
export default compose(connect(mapStateToProps))(TPage);
