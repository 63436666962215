import React from 'react';
import PropTypes from 'prop-types';
import { lifecycle, compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataApi from '../../api/DataApi';

import LoadingPage from '../LoadingPage/LoadingPage';
import ServerError from './components/ServerErrorModalContent';

import { fetchDataRequested } from '../../ducks/data/data';
import { setOnboardingUUID } from '../../ducks/view/view';
import isUUIDValid from '../../utils/isUUIDValid';

import withModals from '../../hoc/withModals/withModals';

import OnboardingRouter from './OnboardingRouter';

const Onboarding = ({ isDataMapped, isCodelistMapped, ...props }) =>
  isDataMapped && isCodelistMapped ? <OnboardingRouter {...props} /> : <LoadingPage />;

Onboarding.propTypes = {
  isDataMapped: PropTypes.bool.isRequired,
  isCodelistMapped: PropTypes.bool.isRequired,
};

const withComponentDidMount = lifecycle({
  componentDidMount() {
    let uuid = this.props.match.params.uuid;
    if ((isUUIDValid(uuid) && uuid !== this.props.uuid) || (uuid = null)) {
      this.props.dispatchSetOnboardingUIID(uuid);
    }
    // initialise DataApi
    DataApi.apiInstance(uuid || this.props.uuid, this.props.locale);
    this.props.dispatchFetchDataRequested();
  },
});

const mapStateToProps = state => ({
  uuid: state.view.get('uuid', ''),
  isDataMapped: state.view.get('isDataMapped', false),
  isCodelistMapped: state.view.get('isCodelistMapped', false),
  apiDidInvalidate: state.view.get('apiDidInvalidate', false),
  location: state.view.get('location', ''),
  locale: state.view.get('locale'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchFetchDataRequested: fetchDataRequested,
      dispatchSetOnboardingUIID: setOnboardingUUID,
    },
    dispatch,
  );

const withStore = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withStore,
  withComponentDidMount,
  withModals([{ content: ServerError, show: props => props.apiDidInvalidate && props.location !== '/finish' }]),
)(Onboarding);
