import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withHandlers, compose } from 'recompose';

import TextArea from '../TextArea';
import Label from '../../../../molecules/Field/components/Label';
import RadioButtonInput from '../RadioButtonInput';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const InputWrapper = styled.div`
  flex: 1 0 100%;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ConditionalTextArea = ({
  id,
  name,
  data,
  disabled,
  handleIsEnabledChange,
  onChange,
  onBlur,
  textareaLabel,
  t,
}) => {
  const label = textareaLabel && (
    <LabelWrapper>
      <Label htmlFor={id}>{textareaLabel}</Label>
    </LabelWrapper>
  );
  const textarea = data.isEnabled && (
    <InputWrapper>
      {label}
      <TextArea
        id={id}
        value={data.value}
        disabled={disabled}
        name={`${name}.value`}
        onChange={onChange}
        onBlur={onBlur}
      />
    </InputWrapper>
  );
  return (
    <Fragment>
      <InputWrapper>
        <RadioButtonInput
          options={[{ label: t.yes, value: 'yes' }, { label: t.no, value: 'no' }]}
          value={data.isEnabled ? 'yes' : 'no'}
          disabled={disabled}
          onClick={handleIsEnabledChange}
        />
      </InputWrapper>
      {textarea}
    </Fragment>
  );
};

ConditionalTextArea.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  data: PropTypes.shape({
    column: PropTypes.string,
    resource: PropTypes.string,
    value: PropTypes.string,
    isEnabled: PropTypes.bool,
  }),
  handleIsEnabledChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  textareaLabel: PropTypes.string,
  t: PropTypes.shape({
    yes: PropTypes.string,
    no: PropTypes.string,
  }).isRequired,
};

ConditionalTextArea.defaultProps = {
  disabled: false,
  data: {
    value: '',
    isEnabled: false,
  },
};

const withVisibilityHandler = compose(
  withHandlers({
    handleIsEnabledChange: props => value => {
      props.onChange({ target: { value: value === 'yes', name: `${props.name}.isEnabled` } });
      props.onBlur({ target: { name: `${props.name}.value` } });
    },
  }),
);

export default withVisibilityHandler(ConditionalTextArea);
