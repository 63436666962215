import { Map, fromJS } from 'immutable';
import { sizes } from 'components/themes/theme';

import { DATA_MAP_SUCCESSED } from 'ducks/form/form';
import { FETCH_DATA_SUCCESSED } from 'ducks/data/data';

export const SIDEBAR_TOGGLED = 'SIDEBAR_TOGGLED';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';
export const LOCATION_CHANGED = 'LOCATION_CHANGED';
export const LOCATION_UPDATE_SUCCESSSED = 'LOCATION_UPDATE_SUCCESSSED';
export const LOCATION_UPDATE_FAILED = 'LOCATION_UPDATE_FAILED';
export const CHECK_REQUIRED_FIELDS_REQUESTED = 'CHECK_REQUIRED_FIELDS_REQUESTED';
export const CHECK_REQUIRED_FIELDS_SUCCESSED = 'CHECK_REQUIRED_FIELDS_SUCCESSED';
export const CHECK_REQUIRED_FIELDS_FAILED = 'CHECK_REQUIRED_FIELDS_FAILED';
export const VALIDATION_MAP_SUCCESSED = 'VALIDATION_MAP_SUCCESSED';
export const FINISH_EDITING_REQUESTED = 'FINISH_EDITING_REQUESTED';
export const FINISH_EDITING_SUCCESSED = 'FINISH_EDITING_SUCCESSED';
export const FINISH_EDITING_FAILED = 'FINISH_EDITING_FAILED';
export const FINISH_EDITING_CANCELLED = 'FINISH_EDITING_CANCELLED';
export const SET_ONBOARDING_UUID = 'SET_ONBOARDING_UUID';
export const SET_PROGRESS_FOR_ALL_SECTIONS = 'SET_PROGRESS_FOR_ALL_SECTIONS';
export const SET_ERRORS_FOR_ALL_SECTIONS = 'SET_ERRORS_FOR_ALL_SECTIONS';
export const VALIDATE_API = 'VALIDATE_API';
export const INVALIDATE_API = 'INVALIDATE_API';
export const STOP_HEALTH_CHECK = 'STOP_HEALTH_CHECK';
export const HEALTH_CHECK_STARTED = 'HEALTH_CHECK_STARTED';
export const HEALTH_CHECK_STOPPED = 'HEALTH_CHECK_STOPPED';
export const CODELIST_MAP_SUCCESSED = 'CODELIST_MAP_SUCCESSED';
export const SET_LOCALE = 'SET_LOCALE';
export const CODE_CRASHED = 'CODE_CRASHED';

export function codeCrashed() {
  return { type: CODE_CRASHED };
}

export function setLocale(locale) {
  return { type: SET_LOCALE, payload: { locale } };
}

export function sidebarToggled() {
  return {
    type: SIDEBAR_TOGGLED,
  };
}

export function setOnboardingUUID(uuid) {
  return {
    type: SET_ONBOARDING_UUID,
    payload: { uuid },
  };
}

export function locationChanged(location) {
  return {
    type: LOCATION_CHANGED,
    payload: { location },
  };
}

export function locationUpdateSuccessed(location) {
  return {
    type: LOCATION_UPDATE_SUCCESSSED,
    payload: { location },
  };
}

export function checkRequiredFieldsRequested() {
  return {
    type: CHECK_REQUIRED_FIELDS_REQUESTED,
  };
}

export function checkRequiredFieldsSuccessed() {
  return {
    type: CHECK_REQUIRED_FIELDS_SUCCESSED,
  };
}

export function checkRequiredFieldsFailed() {
  return {
    type: CHECK_REQUIRED_FIELDS_FAILED,
  };
}

export function validationMapSuccessed({ sidebarErrors, fieldErrors, warnings }) {
  return {
    type: VALIDATION_MAP_SUCCESSED,
    payload: { sidebarErrors, fieldErrors, warnings },
  };
}

export function finishEditingRequested() {
  return {
    type: FINISH_EDITING_REQUESTED,
  };
}

export function finishEditingSuccessed() {
  return {
    type: FINISH_EDITING_SUCCESSED,
  };
}

export function finishEditingFailed(error) {
  return {
    type: FINISH_EDITING_FAILED,
    payload: { error },
  };
}

export function finishEditingCancelled() {
  return {
    type: FINISH_EDITING_CANCELLED,
  };
}

export function locationUpdateFailed(error) {
  return {
    type: LOCATION_UPDATE_FAILED,
    payload: { error },
  };
}

export function setProgressForAllSections(counters) {
  return {
    type: SET_PROGRESS_FOR_ALL_SECTIONS,
    payload: { counters },
  };
}

export function setErrorsForAllSections(errors) {
  return {
    type: SET_ERRORS_FOR_ALL_SECTIONS,
    payload: { errors },
  };
}

export function validateApi() {
  return { type: VALIDATE_API };
}

export function invalidateApi() {
  return { type: INVALIDATE_API };
}

export function healthCheckStarted() {
  return { type: HEALTH_CHECK_STARTED };
}

export function healthCheckStopped() {
  return { type: HEALTH_CHECK_STOPPED };
}

export function stopHealthCheck() {
  return { type: STOP_HEALTH_CHECK };
}

export function codelistMapSuccessed(codelist) {
  return {
    type: CODELIST_MAP_SUCCESSED,
    payload: { codelist },
  };
}

export const initialState = Map({
  sidebar: Map({ open: false }),
  location: '',
  progress: Map({
    overall: 0,
    sections: Map({
      info: 0,
      checkInOut: 0,
      propertyDetails: 0,
      amenities: 0,
      cleaning: 0,
      additionalInfo: 0,
      nearby: 0,
      communication: 0,
    }),
  }),
  uuid: localStorage.getItem('onboarding_uuid'),
  isDataMapped: false,
  isCodelistMapped: false,
  apiDidInvalidate: false,
  isFetching: false,
  locale: 'en-gb',
  validation: fromJS({
    isFetching: false,
    warnings: {},
    errors: {
      info: {},
      checkInOut: {},
      propertyDetails: {},
      amenities: {},
      cleaning: {},
      additionalInfo: {},
      nearby: {},
      communication: {},
      propertyPhotos: {},
      listing: {},
    },
  }),
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SIDEBAR_TOGGLED: {
      const path = ['sidebar', 'open'];
      return state.setIn(path, !state.getIn(path));
    }

    case LOCATION_CHANGED: {
      if (state.getIn(['sidebar', 'open']) && window.innerWidth <= sizes.mobile) {
        return state.setIn(['sidebar', 'open'], false).set('location', payload.location);
      }
      return state.set('location', payload.location);
    }

    case FINISH_EDITING_REQUESTED: {
      return state.set('isFetching', true);
    }

    case FINISH_EDITING_SUCCESSED:
      return state
        .set('status', 'finished')
        .set('apiDidInvalidate', false)
        .set('isFetching', false);

    case FINISH_EDITING_CANCELLED:
      return state.set('apiDidInvalidate', false);

    case SET_ONBOARDING_UUID:
      return state.set('uuid', payload.uuid);

    case DATA_MAP_SUCCESSED:
      return state.set('isDataMapped', true);

    case CODELIST_MAP_SUCCESSED:
      return state.set('isCodelistMapped', true).merge({ options: payload.codelist });

    case CHECK_REQUIRED_FIELDS_REQUESTED:
      return state.setIn(['validation', 'isFetching'], true);

    case CHECK_REQUIRED_FIELDS_SUCCESSED:
      return state.setIn(['validation', 'isFetching'], false);

    case CHECK_REQUIRED_FIELDS_FAILED:
      return state.set('apiDidInvalidate', true).setIn(['validation', 'isFetching'], false);

    case VALIDATION_MAP_SUCCESSED:
      return state
        .set('validation', initialState.get('validation'))
        .setIn(['validation', 'warnings'], payload.warnings)
        .mergeIn(['validation', 'errors'], payload.sidebarErrors);

    case SET_ERRORS_FOR_ALL_SECTIONS:
      return state.setIn(['validation', 'errors'], initialState.getIn(['validation', 'errors']).merge(payload.errors));

    case CODE_CRASHED:
    case LOCATION_UPDATE_FAILED:
    case FINISH_EDITING_FAILED:
      return state.set('apiDidInvalidate', true).set('isFetching', false);

    case SET_PROGRESS_FOR_ALL_SECTIONS:
      return state
        .setIn(
          ['progress', 'sections'],
          payload.counters.map(counter => round(counter.get('sum') / counter.get('count'))),
        )
        .setIn(['progress', 'overall'], calculateOverallProgress(payload.counters));

    case FETCH_DATA_SUCCESSED:
      return state.set('location', payload.data.current_location);

    case VALIDATE_API:
      return state.set('apiDidInvalidate', false);

    case INVALIDATE_API:
      return state.set('apiDidInvalidate', true);

    case SET_LOCALE:
      return state.set('locale', payload.locale);

    default:
      return state;
  }
};

function round(value) {
  return Math.round(value * 100) / 100;
}

function calculateOverallProgress(sectionsCounters) {
  const counter = sectionsCounters.reduce(
    (overallCounter, counter) =>
      Map({
        sum: overallCounter.get('sum') + counter.get('sum'),
        count: overallCounter.get('count') + counter.get('count'),
      }),
    Map({ sum: 0, count: 0 }),
  );
  return round(counter.get('sum') / counter.get('count'));
}
