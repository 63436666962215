import styled from 'styled-components';

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: minmax(5vh, 2fr) auto minmax(5vh, 3fr);
  grid-template-areas: '.' 'modal' '.';
  ${props => props.theme.media.mobile`
    display: block;
  `};
`;

export default ModalWrapper;
