import { all, fork, take, actionChannel, call } from 'redux-saga/effects';

import data from './data/data';
import specialFields from './specialFields/specialFields';
import errors from './errors/errors';
import sideEffects from './sideEffects/sideEffects';
import { updateFormField } from './field/field';
import { updateSpecialFields } from './specialFields/update/update';
import { validateForm } from 'sagas/view/validation/validation';

import { UPDATE_FIELD_REQUESTED, UPDATE_SPECIAL_FIELDS_REQUESTED } from 'ducks/form/form';
import { CHECK_REQUIRED_FIELDS_REQUESTED } from 'ducks/view/view';

export function* watchQueuedUpdates() {
  // to make sure that all relevant field updates have completed before form validation,
  // we put them into the same channel so only one task can run at a time.
  // NOTE: right now backend validation is only performed on default fields + amenities
  //  - if backend validation is added on other special fields, this may need to be updated
  const fieldUpdateChannel = yield actionChannel([
    UPDATE_FIELD_REQUESTED,
    UPDATE_SPECIAL_FIELDS_REQUESTED,
    CHECK_REQUIRED_FIELDS_REQUESTED,
  ]);

  while (true) {
    const payload = yield take(fieldUpdateChannel);
    switch (payload.type) {
      case UPDATE_FIELD_REQUESTED:
        yield call(updateFormField, payload);
        break;
      case UPDATE_SPECIAL_FIELDS_REQUESTED:
        yield call(updateSpecialFields, payload);
        break;
      case CHECK_REQUIRED_FIELDS_REQUESTED: {
        // trigger update of property amenities before starting form validation
        yield call(updateSpecialFields, { payload: { fields: ['property_amenities'] } });
        yield call(validateForm);
        break;
      }
      default:
        break;
    }
  }
}

export default function* rootSaga() {
  yield all([fork(watchQueuedUpdates), fork(data), fork(specialFields), fork(errors), fork(sideEffects)]);
}
