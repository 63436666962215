import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/atoms/Input/Input';
import Hint from 'components/molecules/Hint/Hint';

import Label, { LabelContainer } from 'components/atoms/Label/Label';
import Error from './components/Error';
import MaxLengthText from './components/MaxLengthText';
import GroupContainer from './components/GroupContainer';
import Suffix from './components/Suffix';
import FieldContainer from './components/FieldContainer';

export const LabelElement = ({ label, hint, disabled, id, required, hintType, focused }) => {
  return label || hint ? (
    <LabelContainer>
      <Label disabled={disabled} htmlFor={id}>
        {label}
        {required && ' *'}
      </Label>
      {hint && (
        <Hint inputFocused={focused} type={hintType}>
          {hint}
        </Hint>
      )}
    </LabelContainer>
  ) : (
    <></>
  );
};

const Field = ({
  className,
  disabled,
  name,
  id = name,
  size,
  label,
  hint,
  hintType,
  error,
  suffix,
  maxLength,
  showMaxLengthHint,
  required,
  t,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const labelElement = LabelElement({ label, hint, disabled, id, required, hintType, focused });
  const errorElement = error && <Error>{error}</Error>;
  const maxLengthElement = showMaxLengthHint && !!maxLength && <MaxLengthText>{t.maxLengthText}</MaxLengthText>;

  return (
    <GroupContainer
      className={className}
      size={size}
      label={label}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      {labelElement}
      <FieldContainer>
        <Input maxLength={maxLength} name={name} id={id} disabled={disabled} size={size} t={t} {...props} />
        {suffix && <Suffix>{suffix}</Suffix>}
      </FieldContainer>
      {errorElement}
      {maxLengthElement}
    </GroupContainer>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hint: PropTypes.string,
  hintType: PropTypes.string,
  suffix: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  isEnabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  showMaxLengthHint: PropTypes.bool,
  t: PropTypes.shape({
    maxLengthText: PropTypes.string.isRequired,
  }).isRequired,
};

LabelElement.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  required: PropTypes.bool,
  hintType: PropTypes.string,
  focused: PropTypes.bool,
};

export default Field;
