import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { H1, MediumText } from '../../atoms/Typography/Typography';
import { StyledButton } from '../../mixins';
import Logo from '../../atoms/Logo/Logo';

import Container from './components/Container';
import Wrapper from './components/Wrapper';
import Separator from './components/Separator';

const LogoWrapper = styled.div`
  margin-bottom: 2rem;
  align-self: flex-end;
`;

const Heading = styled(H1)`
  font-size: 3.5rem;
  line-height: 0.95;
  margin-bottom: 0;
  margin-top: 0;
  ${props => props.theme.media.tablet`
    font-size: 3rem;
    line-height: 1.125;
  `};
  ${props => props.theme.media.mobile`
    font-size: 2.5rem;
    line-height: 1.15;
  `};
`;

const TextWrapper = styled(MediumText)`
  max-width: 600px;
  line-height: 1.2;
  > {
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const Button = styled(StyledButton)`
  font-size: 1.3125rem;
  width: 14.5rem;
  height: 3.5rem;
  font-weight: 500;
  border-radius: 1.75rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-self: flex-end;
`;

const WelcomePageTemplate = ({ heading, content, logo, buttonText, buttonLink }) => {
  return (
    <Container>
      <Wrapper>
        {logo && (
          <LogoWrapper>
            <Logo logo={logo} />
          </LogoWrapper>
        )}
        <Heading secondary>{heading}</Heading>
        <Separator />
        <TextWrapper as="div">{content}</TextWrapper>
        <Button as={Link} to={buttonLink}>
          {buttonText}
        </Button>
      </Wrapper>
    </Container>
  );
};

WelcomePageTemplate.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string.isRequired,
  logo: PropTypes.string,
};

WelcomePageTemplate.defaultProps = {
  buttonLink: '/',
};

export default WelcomePageTemplate;
