import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon, { StyledSVG } from 'components/atoms/Icon/Icon';
import CheckboxInput, { Checkbox } from 'components/atoms/Input/Inputs/CheckboxInput';

const IconWrapper = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const Label = styled.span`
  color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.n800)};
  font-size: ${props => (0.875 / 120) * +props.size}rem;
  margin-top: 0.5rem;
  display: inline-block;
  text-align: center;
`;

const Warning = styled(Label)`
  color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.error)};
  opacity: ${props => (props.selected ? 0.75 : 1)};
  font-size: ${props => (0.7 / 120) * +props.size}rem;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  font-weight: bold;
`;

const StyledIconButton = styled.a`
  background: ${props => (props.selected ? props.theme.colors.primary : props.theme.colors.white)};
  border: none;
  border-radius: 0.625rem;
  box-shadow: ${props => props.theme.shadows.default};
  width: ${props => props.size / 16 + 'rem'};
  height: ${props => props.size / 16 + 'rem'};
  display: grid;
  grid-template: 1fr / 1fr;
  cursor: pointer;
  outline: none;
  user-select: none;
  vertical-align: top;
  box-shadow: ${props => props.theme.shadows.iconButton(+props.size)};
  margin: 0.5rem;
  padding: 0.5rem;
  transition: ${props => props.theme.transitions.default};
  transform: translate3d(0, 0, 0);
  ${StyledSVG} {
    path {
      fill: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.primary)};
    }
  }
  &:focus,
  &:hover {
    transform: translate3d(0, -${props => (0.1875 / 120) * +props.size}rem, 0);
  }
`;

const StyledCheckbox = styled(CheckboxInput)`
  align-self: flex-start;
  justify-self: flex-end;
  margin-bottom: -1rem;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  ${Checkbox} {
    &:focus {
      box-shadow: none;
    }
    path {
      fill: ${props => (props.checked ? props.theme.colors.white : props.theme.colors.textSecondary)};
    }
  }
`;

const IconButton = props => {
  const { icon, ...other } = props;
  const { selected, size, onClick } = other;
  const label = props.label && (
    <Label selected={selected} size={size}>
      {props.label}
    </Label>
  );
  const warning = props.warning && (
    <Warning selected={selected} size={size}>
      {props.warning}
    </Warning>
  );
  return (
    <StyledIconButton tabIndex="0" {...other}>
      <StyledCheckbox checked={selected} onClick={onClick} />
      <IconWrapper>
        <Icon icon={icon} size={size / 2.5} />
        {label}
        {warning}
      </IconWrapper>
    </StyledIconButton>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string,
  selected: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  warning: PropTypes.string,
};

IconButton.defaultProps = {
  size: 120,
};

export default IconButton;
