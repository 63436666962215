import styled from 'styled-components';

export const LabelContainer = styled.div`
  margin-bottom: 0.5rem;
  display: inline-flex;
  min-width: ${props => (props.size ? +props.size + 2 + 'ch' : '20rem')};
  width: 100%;
  justify-content: space-between;

  ${props => props.theme.media.mobileSmall`
    min-width: 100%;
  `};
`;

LabelContainer.defaultProps = {
  size: 0,
};

const Label = styled.label`
  color: ${props => props.theme.colors.n800};
  font-weight: ${props => (props.disabled ? 400 : 500)};
  min-width: 17.75rem;
  ${props => props.theme.media.mobileSmall`
    min-width: calc(100% - 2.25rem);
  `};
`;

export default Label;
