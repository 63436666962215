import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledSecondaryButton } from 'components/mixins';

import ButtonGroup from 'components/atoms/ButtonGroup/ButtonGroup';

const StyledRadioButton = styled(StyledSecondaryButton)`
  background: ${props => (props.selected ? props.theme.colors.primary : 'none')};
  border: ${props => (props.selected ? 'none' : '1px solid ' + props.theme.colors.primary)};
  color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.primary)};
  &:focus,
  &:hover:not([disabled]) {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }
  &[disabled] {
    background: ${props => (props.selected ? props.theme.colors.primary : 'none')};
    opacity: 0.5;
  }
`;

const RadioButtonInput = ({ disabled, value, options, onClick }) => {
  return (
    <ButtonGroup>
      {options.map(option => (
        <StyledRadioButton
          tabIndex={disabled ? -1 : 0}
          disabled={disabled}
          key={option.value}
          selected={option.value === value}
          value={option.value}
          onClick={() => onClick(option.value)}
        >
          {option.label}
        </StyledRadioButton>
      ))}
    </ButtonGroup>
  );
};

RadioButtonInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    }),
  ).isRequired,
};

export default RadioButtonInput;
