import styled from 'styled-components';

export default styled.div`
  display: inline-grid;
  grid-template-rows: ${props => (props.hasRadioButtons ? 'auto 1fr' : 'auto')};
  grid-template-columns: ${props => (props.hasRightCol ? 'auto 1fr' : 'auto')};
  grid-column: ${props => (props.hasRightCol ? '1 / -1' : '')};

  a {
    margin: 0;
    grid-row: 1 / -1;
  }
  input,
  textarea {
    grid-column: 2;
    max-width: 20rem;
    ${props => props.theme.media.mobileSmall`
      max-width: 100%;
    `};
  }
  margin-top: 1.5rem;
  grid-column-gap: 1rem;
`;
