import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon, { StyledSVG } from 'components/atoms/Icon/Icon';
import CheckboxInput, { Checkbox } from 'components/atoms/Input/Inputs/CheckboxInput';

const Label = styled.span`
  color: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.n800)};
  font-size: ${props => (0.9 / 24) * +props.size}rem;
  flex-grow: 1;
`;

const StyledIconButton = styled.a`
  background: ${props => (props.selected ? props.theme.colors.primary : props.theme.colors.white)};
  border: none;
  border-radius: 0.625rem;
  box-shadow: ${props => props.theme.shadows.default};
  width: 100%;
  display: grid;
  grid-template-columns: ${props => (props.hasIcon ? 'auto 1fr auto' : '1fr auto')};
  grid-column-gap: 0.4rem;
  align-items: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  box-shadow: ${props => (props.selected ? 'none' : props.theme.shadows.s)};
  padding: 0.5rem;
  transition: ${props => props.theme.transitions.default};
  transform: translate3d(0, 0, 0);
  ${StyledSVG} {
    path {
      fill: ${props => (props.selected ? props.theme.colors.white : props.theme.colors.primary)};
    }
  }
  &:focus,
  &:hover {
    transform: translate3d(0, -${props => (0.1875 / 120) * +props.size}rem, 0);
  }
`;

const StyledCheckbox = styled(CheckboxInput)`
  ${Checkbox} {
    &:focus {
      box-shadow: none;
    }
    path {
      fill: ${props => (props.checked ? props.theme.colors.white : props.theme.colors.textSecondary)};
    }
  }
`;

const IconButton = props => {
  const { icon, ...other } = props;
  const { selected, size, onClick } = other;
  const label = props.label && (
    <Label selected={selected} size={size}>
      {props.label}
    </Label>
  );
  return (
    <StyledIconButton hasIcon={!!icon} tabIndex="0" {...other}>
      {icon && <Icon icon={icon} size={size} />}
      {label}
      <StyledCheckbox checked={selected} onClick={onClick} />
    </StyledIconButton>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string,
  selected: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};

IconButton.defaultProps = {
  size: 24,
};

export default IconButton;
