import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from 'scenes/Onboarding/components/Field';
import { SectionHeading } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';

const Page = ({ form, t, selectedFields }) => {
  return (
    <Fragment>
      <SectionHeading>{t('CLEANING_AND_MAINTENANCE.HEADINGS.CLEANING_INSTRUCTIONS')}</SectionHeading>
      {selectedFields.includes('property_indication_trash') && (
        <Field
          name="property_indication_trash.value"
          label={t('CLEANING_AND_MAINTENANCE.QUESTIONS.TRASH_INSTRUCTIONS.LABEL')}
          hint={t('CLEANING_AND_MAINTENANCE.QUESTIONS.TRASH_INSTRUCTIONS.HINT')}
          type="textarea"
          data={form.get('property_indication_trash').toJS()}
        />
      )}
      {selectedFields.includes('property_extra_information_extra_linen_place') && (
        <Field
          name="property_extra_information_extra_linen_place.value"
          label={t('CLEANING_AND_MAINTENANCE.QUESTIONS.EXTRA_LINEN_STORAGE.LABEL')}
          type="textarea"
          data={form.get('property_extra_information_extra_linen_place').toJS()}
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'CLEANING_AND_MAINTENANCE' in obj)
    .CLEANING_AND_MAINTENANCE,
});

const TPage = withTranslations(Page);
export default connect(mapStateToProps)(TPage);
