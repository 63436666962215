import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import AmenitiesGrid from 'components/atoms/AmenitiesGrid/AmenitiesGrid';
import AmenityInput from 'scenes/Onboarding/components/AmenityInput';
import { SectionHeading, Text } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';

import { AMENITY_CATEGORIES } from 'constants.js';

import withWillUnmountHandler from 'hoc/withWillUnmountHandler/withWillUnmountHandler';

const Page = ({ entertainmentAmenities, generalAmenities, form, t }) => {
  return (
    <Fragment>
      <SectionHeading>{t('AMENITIES.HEADINGS.GENERAL')}</SectionHeading>
      <Text>{t('AMENITIES.DESCRIPTIONS.GENERAL')}</Text>
      <AmenitiesGrid>
        {generalAmenities.map(amenityView => {
          const amenityValue = form.getIn(['property_amenities', 'value', amenityView.get('id')]);
          return (
            amenityView && (
              <AmenityInput
                list="property_amenities"
                key={amenityView.get('id')}
                view={amenityView.toJS()}
                value={amenityValue && amenityValue.toJS()}
              />
            )
          );
        })}
      </AmenitiesGrid>
      <SectionHeading>{t('AMENITIES.HEADINGS.WORK_AND_ENTERTAINMENT')}</SectionHeading>
      <Text>{t('AMENITIES.DESCRIPTIONS.WORK_AND_ENTERTAINMENT')}</Text>
      <AmenitiesGrid>
        {entertainmentAmenities.map(amenityView => {
          const amenityValue = form.getIn(['property_amenities', 'value', amenityView.get('id')]);
          return (
            amenityView && (
              <AmenityInput
                list="property_amenities"
                key={amenityView.get('id')}
                view={amenityView.toJS()}
                value={amenityValue && amenityValue.toJS()}
              />
            )
          );
        })}
      </AmenitiesGrid>
    </Fragment>
  );
};

Page.propTypes = {
  entertainmentAmenities: PropTypes.object.isRequired,
  generalAmenities: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  entertainmentAmenities: state.form
    .getIn(['property_amenities', 'view'])
    .filter(amenityView => amenityView.get('category') === AMENITY_CATEGORIES.ENTERTAINMENT),
  generalAmenities: state.form
    .getIn(['property_amenities', 'view'])
    .filter(amenityView => amenityView.get('category') === AMENITY_CATEGORIES.GENERAL),
  form: state.form,
});

const TPage = withTranslations(Page);
export default compose(
  withWillUnmountHandler(['property_amenities']),
  connect(mapStateToProps),
)(TPage);
