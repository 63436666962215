import styled from 'styled-components';

const HeadingWrapper = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.textSecondary};
  > :first-child {
    margin-top: 0.3rem;
  }
  > :last-child {
    margin-bottom: 0;
  }
  ${props => props.theme.media.desktop`
    padding-right: 1rem;
  `};
  ${props => props.theme.media.tablet`
    max-width: calc(90vw - 4rem);
    margin-top: 0;
  `};
  ${props => props.theme.media.mobile`
    max-width: 100vw;
    padding-left: 1rem;
  `};
`;

export default HeadingWrapper;
