import React from 'react';
import PropTypes from 'prop-types';
import {
  BasicInput,
  CheckboxInput,
  NumberInput,
  NumberRange,
  RadioButtonInput,
  RadioInput,
  Select,
  TelephoneInput,
  TextArea,
  ConditionalTextArea,
  CurrencyInput,
  DatePicker,
} from './Inputs';

const Input = ({ type, ...props }) => {
  switch (type) {
    case 'textarea':
      return <TextArea {...props} />;
    case 'tel':
      return <TelephoneInput {...props} />;
    case 'number':
      return <NumberInput {...props} />;
    case 'currency':
      return <CurrencyInput {...props} />;
    case 'number-range':
      return <NumberRange {...props} />;
    case 'radio':
      return <RadioInput {...props} />;
    case 'radio-buttons':
      return <RadioButtonInput {...props} />;
    case 'select':
      return <Select {...props} />;
    case 'checkbox':
      return <CheckboxInput {...props} />;
    case 'conditional-textarea':
      return <ConditionalTextArea {...props} />;
    case 'datepicker':
      return <DatePicker {...props} />;
    default:
      return <BasicInput type={type} {...props} />;
  }
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
