import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'components/atoms/Typography/Typography';
import CloseButton from 'components/atoms/CloseButton/CloseButton';

export const ThumbnailWrapper = styled.div`
  height: 7.5rem;
  width: 7.5rem;
  border-radius: 0.4rem;
  margin: 0.4rem;
  padding: 0.4rem;
  box-shadow: ${props => props.theme.shadows.focus};
  display: inline-flex;
  flex-direction: column;
  background-color: ${props => (props.isPdf ? props.theme.colors.textSecondary : '')};
  background-size: cover;
  background-position: center;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
`;

export const ThumbnailImage = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const Wrapper = styled.div`
  display: flex;
`;

const CloseButtonWrapper = styled.span`
  background: #ffffffcc;
  align-self: flex-end;
  border-radius: 5rem;
  display: inline-block;
  line-height: 0;
  height: 18px;
  width: 18px;
  position: absolute;
  svg {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 20px;
    height: 20px;
  }
`;

export const OverlayText = styled(Text)`
  color: white;
  text-align: center;
  font-weight: bold;
  opacity: 1;
  text-shadow: ${props => props.theme.shadows.focus};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
`;

const EmptyThumbnailWrapper = styled(ThumbnailWrapper)`
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.n100};
  text-align: center;
  cursor: pointer;
  color: ${props => props.theme.colors.n700};
  user-select: none;
`;

const HelpText = styled.small`
  margin-top: 0.5rem;
`;

const Thumbnail = ({ image, onRemove, onClick, className, t }) => {
  if (!image) {
    return (
      <EmptyThumbnailWrapper onClick={onClick} className={className}>
        {t.uploadFilePrompt}
        {t.uploadHint && <HelpText>{t.uploadHint}</HelpText>}
      </EmptyThumbnailWrapper>
    );
  } else {
    const { blob, file, uploading, deleting } = image;
    const fileName = (typeof file === 'object' && file.name) || (typeof file === 'string' && file) || '';
    const isPdf =
      String.prototype.match.call(blob || '', /\.pdf\b/) || String.prototype.match.call(fileName || '', /\.pdf\b/);
    const maxNameLength = 10;
    const truncatedFileName = file.length > maxNameLength ? file.slice(0, maxNameLength - 1) + '...' : file;

    return (
      <Wrapper>
        <ThumbnailWrapper
          onClick={onClick}
          isPdf={isPdf}
          uploading={uploading}
          className={className}
          src={!isPdf && (blob || file)}
        >
          <ThumbnailImage src={!isPdf && (blob || file)} />
          {onRemove && (
            <CloseButtonWrapper
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onRemove();
              }}
            >
              <CloseButton />
            </CloseButtonWrapper>
          )}
          {/* TODO: Add link to open image/video in new tab */}
          {isPdf && <OverlayText>{truncatedFileName}</OverlayText>}
          {uploading && <OverlayText>{t.uploading}</OverlayText>}
          {deleting && <OverlayText>{t.deleting}</OverlayText>}
        </ThumbnailWrapper>
      </Wrapper>
    );
  }
};

Thumbnail.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    uploading: PropTypes.bool,
    deleting: PropTypes.bool,
  }),
  t: PropTypes.shape({
    uploading: PropTypes.string,
    deleting: PropTypes.string,
    uploadFilePrompt: PropTypes.string,
    uploadHint: PropTypes.string,
    video: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func,
  className: PropTypes.string,
};

export default Thumbnail;
