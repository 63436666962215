import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Field from 'scenes/Onboarding/components/Field';
import { SectionHeading } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';

const Page = ({ form, t, selectedFields }) => {
  return (
    <Fragment>
      <SectionHeading>{t('CLEANING_AND_MAINTENANCE.HEADINGS.MAINTENANCE')}</SectionHeading>
      {selectedFields.includes('property_extra_information_preferred_maintenance') && (
        <Field
          name="property_extra_information_preferred_maintenance.value"
          label={t('CLEANING_AND_MAINTENANCE.QUESTIONS.MAINTENANCE_CONTACTS.LABEL')}
          type="textarea"
          data={form.get('property_extra_information_preferred_maintenance').toJS()}
        />
      )}
      {selectedFields.includes('property_extra_information_switches_location') && (
        <Field
          name="property_extra_information_switches_location.value"
          label={t('CLEANING_AND_MAINTENANCE.QUESTIONS.SWITCH_LOCATION.LABEL')}
          type="textarea"
          data={form.get('property_extra_information_switches_location').toJS()}
        />
      )}
      {selectedFields.includes('property_extra_information_boiler_location') && (
        <Field
          name="property_extra_information_boiler_location.value"
          label={t('CLEANING_AND_MAINTENANCE.QUESTIONS.BOILER_LOCATION.LABEL')}
          type="textarea"
          data={form.get('property_extra_information_boiler_location').toJS()}
        />
      )}
      {selectedFields.includes('property_extra_information_heating_control_location') && (
        <Field
          name="property_extra_information_heating_control_location.value"
          label={t('CLEANING_AND_MAINTENANCE.QUESTIONS.HEATING_CONTROL_LOCATION.LABEL')}
          type="textarea"
          data={form.get('property_extra_information_heating_control_location').toJS()}
        />
      )}
      {selectedFields.includes('property_extra_information_maintenance_observation') && (
        <Field
          name="property_extra_information_maintenance_observation.value"
          label={t('TEXT.SHARED.ADDITIONAL_COMMENTS_LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          type="textarea"
          data={form.get('property_extra_information_maintenance_observation').toJS()}
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'CLEANING_AND_MAINTENANCE' in obj)
    .CLEANING_AND_MAINTENANCE,
});

const TPage = withTranslations(Page);
export default connect(mapStateToProps)(TPage);
