import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import InlineIconButton from 'components/molecules/InlineIconButton/InlineIconButton';
import { StyledTertiaryButton } from '../../mixins';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

const FieldLabel = styled.span`
  color: ${props => props.theme.colors.n800};
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 0.4rem;
  justify-items: stretch;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

const Label = styled.span`
  margin-top: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
  color: ${props => props.theme.colors.n800};
`;

const ActionsWrapper = styled.div`
  align-self: flex-end;
  margin-top: 0.5rem;
`;

const AddBathroom = styled(StyledTertiaryButton)`
  font-weight: bold;
  color: ${props => props.theme.colors.n800};
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  ${props => props.theme.media.mobileSmall`
    margin-right: 0rem;
  `};
`;

const RemoveBathroom = styled(AddBathroom)`
  color: ${props => props.theme.colors.textSecondary};
`;

const BathroomField = ({ name, options, label, bathrooms, onChange, onAdd, onRemove, t }) => {
  const removeBathroom =
    bathrooms.length > 1 ? (
      <RemoveBathroom name={name} tabIndex="0" onClick={onRemove}>
        {t.removeABathroom}
      </RemoveBathroom>
    ) : (
      ''
    );
  return (
    <Wrapper>
      <FieldLabel>{label}</FieldLabel>
      {bathrooms.map((bathroom, i) => (
        <Fragment key={i}>
          <Label>{bathroom.label}</Label>
          <FieldWrapper>
            {options.map(option => {
              const selected = bathroom.amenities[option.key];
              return (
                <InlineIconButton
                  key={option.key}
                  tabIndex="0"
                  icon={option.icon}
                  label={option.name}
                  selected={selected}
                  onClick={() =>
                    onChange({ target: { name: `${name}.${i}.amenities.${option.key}`, value: !selected } })
                  }
                />
              );
            })}
          </FieldWrapper>
        </Fragment>
      ))}
      <ActionsWrapper>
        {removeBathroom}
        <AddBathroom name={name} tabIndex="0" onClick={onAdd}>
          {t.addABathroom}
        </AddBathroom>
      </ActionsWrapper>
    </Wrapper>
  );
};

BathroomField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string,
  bathrooms: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      amenities: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  t: PropTypes.shape({
    addABathroom: PropTypes.string,
    removeABathroom: PropTypes.string,
  }).isRequired,
};

export default BathroomField;
