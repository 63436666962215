import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';

const withRoomsEventsHandlers = ({ addAction, removeAction }) =>
  compose(
    connect(),
    withHandlers({
      onAdd: props => event => props.dispatch(addAction({ fieldName: event.target.name })),
      onRemove: props => event => props.dispatch(removeAction({ fieldName: event.target.name })),
    }),
  );

export const withBedroomsEventsHandlers = ({ addAction, removeAction, setBedCountAction }) =>
  compose(
    connect(),
    withHandlers({
      onSetBedCount: props => event => props.dispatch(setBedCountAction(event)),
      onAdd: props => event => props.dispatch(addAction({ fieldName: event.target.name })),
      onRemove: props => event => props.dispatch(removeAction({ fieldName: event.target.name })),
    }),
  );

export default withRoomsEventsHandlers;
