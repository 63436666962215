import styled from 'styled-components';

export const H1 = styled.h1`
  color: ${props =>
    (props.error && props.theme.colors.error) ||
    (props.secondary && props.theme.colors.primary) ||
    props.theme.colors.textPrimary};
  font-size: 2.25rem;
  letter-spacing: 0.03rem;
`;

export const H2 = styled.h2`
  color: ${props => props.theme.colors.textPrimary};
  font-size: 1.5rem;
  letter-spacing: 0.03rem;
`;

export const SectionHeading = styled(H2)`
  margin-top: 4rem;
`;

export const H3 = styled.h3`
  color: ${props => props.theme.colors.textPrimary};
  font-size: 1.25rem;
  letter-spacing: 0.03rem;
`;

export const H4 = styled(H3)`
  font-size: 1rem;
`;

export const Text = styled.p`
  color: ${props => props.theme.colors.n800};
  line-height: 1.25rem;
`;

export const MediumText = styled.p`
  color: ${props => props.theme.colors.n800};
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

export const LargeText = styled.p`
  color: ${props => props.theme.colors.textSecondary};
  font-size: 1.5rem;
  font-weight: 500;
`;

export const LabelHeading = styled(H4)`
  color: ${props => props.theme.colors.n800};
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 1.5rem;
`;
