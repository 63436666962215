import { takeLatest, put, call, select, all, fork } from 'redux-saga/effects';

import { getForm } from 'selectors/form';

import {
  DATA_MAP_SUCCESSED,
  FORM_FIELD_CHANGED,
  UPDATE_FIELD_REQUESTED,
  AMENITIES_ITEM_REMOVED,
  AMENITIES_ITEM_ADDED,
  AMENITIES_ITEM_UPDATED,
  PROPERTY_PHOTO_REMOVED,
  UPDATE_SPECIAL_FIELD_SUCCESSED,
} from 'ducks/form/form';
import { setProgressForAllSections } from 'ducks/view/view';

import { calculateProgressForAllSections } from './counter/counter';

export function* handleAllSectionsProgressAfterDataMapped({ payload: { data } }) {
  const counters = yield call(calculateProgressForAllSections, data);
  yield put(setProgressForAllSections(counters));
}

export function* watchDataMapSuccessed() {
  yield takeLatest(DATA_MAP_SUCCESSED, handleAllSectionsProgressAfterDataMapped);
}

export function* handleAllSectionsProgressAfterFieldChanged({ type, payload }) {
  const { fieldType } = payload.field || {};
  if (type === FORM_FIELD_CHANGED && ['beds', 'bedrooms', 'bathrooms', 'common_space'].indexOf(fieldType) === -1) {
    return;
  }

  const form = yield select(getForm);
  const counters = yield call(calculateProgressForAllSections, form);
  yield put(setProgressForAllSections(counters));
}

export function* watchFormFieldChanged() {
  yield takeLatest(
    [
      FORM_FIELD_CHANGED,
      UPDATE_FIELD_REQUESTED,
      AMENITIES_ITEM_REMOVED,
      AMENITIES_ITEM_ADDED,
      AMENITIES_ITEM_UPDATED,
      PROPERTY_PHOTO_REMOVED,
    ],
    handleAllSectionsProgressAfterFieldChanged,
  );
}

export function* handleAllSectionsProgressAfterSpecialFieldUpdated({ type, payload }) {
  const fieldType = payload.field.keySeq().first() || '';

  if (type === UPDATE_SPECIAL_FIELD_SUCCESSED && fieldType !== 'property_photos') {
    return;
  }

  const form = yield select(getForm);
  const counters = yield call(calculateProgressForAllSections, form);
  yield put(setProgressForAllSections(counters));
}

export function* watchSpecialFieldUpdated() {
  yield takeLatest([UPDATE_SPECIAL_FIELD_SUCCESSED], handleAllSectionsProgressAfterSpecialFieldUpdated);
}

export default function* watchProgress() {
  yield all([fork(watchDataMapSuccessed), fork(watchFormFieldChanged), fork(watchSpecialFieldUpdated)]);
}
