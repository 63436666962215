import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LargeText } from 'components/atoms/Typography/Typography';
import ModalPage from 'components/templates/ModalPage/ModalPage';
import Logo from 'components/atoms/Logo/Logo';

const StyledLargeText = styled(LargeText)`
  ${props => props.theme.media.mobileSmall`
    letter-spacing: -0.2px;
  `};
`;

const HighlightedText = styled.span`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  ${props => props.theme.media.mobileSmall`
    display: block;
  `};
`;

const StyledModalContent = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  justify-items: center;
`;

const FinishedModalPage = ({ t, logo }) => {
  return (
    <ModalPage>
      <StyledModalContent>
        <StyledLargeText>
          {t.text1} <HighlightedText>{t.text2}</HighlightedText>
        </StyledLargeText>
        {logo && <Logo logo={logo} />}
      </StyledModalContent>
    </ModalPage>
  );
};

FinishedModalPage.propTypes = {
  t: PropTypes.shape({
    text1: PropTypes.string.isRequired,
    text2: PropTypes.string.isRequired,
  }).isRequired,
};

export default FinishedModalPage;
