import styled, { css } from 'styled-components';

const withDragStyle = ({ isDragActive }) =>
  isDragActive &&
  css`
    margin-left: -9999px;
    margin-right: -9999px;
    padding-left: 9999px;
    padding-right: 9999px;
    background: ${props => props.theme.colors.n200};
  `;

const Wrapper = styled.div`
  outline: none;
  ${withDragStyle};
`;

export default Wrapper;
