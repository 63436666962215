import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Text } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';
import PhotoUpload from '../components/PhotoUpload';

const Page = ({ form, t }) => {
  return (
    <Fragment>
      <Text>{t('PROPERTY_PHOTOS.DESCRIPTION')}</Text>
      <PhotoUpload
        t={t}
        value={form
          .getIn(['property_photos', 'value'])
          .toList()
          .toJS()}
      />
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  form: state.form,
});

const TPage = withTranslations(Page);
export default connect(mapStateToProps)(TPage);
