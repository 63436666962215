import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NotificationPageTemplate from '../../templates/NotificationPageTemplate/NotificationPageTemplate';
import ProgressIcon from '../../molecules/ProgressIcon/ProgressIcon';
import { H1 } from '../../atoms/Typography/Typography';

import { ICONS } from 'constants.js';

const LoadingDiv = styled.div`
  text-align: center;
  max-width: 495px;
  margin: 0 16px;
`;

const LoadingPage = ({ t }) => (
  <NotificationPageTemplate>
    <LoadingDiv>
      <ProgressIcon
        barWidth={7}
        barWidthDesktop={7}
        circleSize={97}
        circleSizeDesktop={75}
        duration={2250}
        icon={ICONS.ROCKET}
        iconSize={39}
        iconSizeDesktop={30}
        progress={0.75}
      />
      <H1 secondary>{t.text1}</H1>
      <H1 secondary>{t.text2}</H1>
    </LoadingDiv>
  </NotificationPageTemplate>
);

LoadingPage.propTypes = {
  t: PropTypes.shape({
    text1: PropTypes.string.isRequired,
    text2: PropTypes.string.isRequired,
  }).isRequired,
};

export default LoadingPage;
