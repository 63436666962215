import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../molecules/Modal/Modal';
import ModalWrapper from '../../atoms/ModalWrapper/ModalWrapper';

const ModalPage = ({ closeButton, closeBtnClicked, children }) => {
  return (
    <ModalWrapper>
      <Modal closeButton={closeButton} closeBtnClicked={closeBtnClicked}>
        {children}
      </Modal>
    </ModalWrapper>
  );
};

ModalPage.propTypes = {
  closeButton: PropTypes.bool,
  closeBtnClicked: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default ModalPage;
