import styled from 'styled-components';

const FormGrid = styled.div`
  grid-area: main;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0;
  transition: ${props => props.theme.transitions.fast}, padding-right 0.4s ease-in-out;
  ${props => props.theme.media.tablet`
    padding-right: ${props.sidebarOpen ? '350px' : '0'};
  `};
  ${props => props.theme.media.mobile`
    filter: ${props => (props.sidebarOpen ? 'blur(4.8px)' : '')};
    padding-right: 0;
  `};
`;

export default FormGrid;
