export const findFirstExistingSection = (sectionOptions, selectedSections) => {
  if (selectedSections) {
    if (!selectedSections.length) {
      return 'GETTING_IN_AND_OUT';
    } else {
      for (let i = 0; i < sectionOptions.length; i++) {
        if (selectedSections.includes(sectionOptions[i].id)) {
          return sectionOptions[i].key;
        }
      }
    }
  }
};
