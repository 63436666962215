import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';

import { H1, MediumText } from 'components/atoms/Typography/Typography';
import { finishEditingRequested, finishEditingCancelled } from 'ducks/view/view';
import LoadingButton from 'components/atoms/LoadingButton/LoadingButton';
import ButtonGroup from 'components/atoms/ButtonGroup/ButtonGroup';

import { withMappedTranslations } from 'hoc/withTranslations/withTranslations';

const mapStateToProps = state => ({
  isFetching: state.view.get('isFetching'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatchFinishEditing: finishEditingRequested,
      dispatchFinishEditingCancelled: finishEditingCancelled,
    },
    dispatch,
  );

const SaveErrorModalContent = ({ onTryAgain, onCancel, isFetching, t }) => (
  <Fragment>
    <H1 error>{t.header}</H1>
    <MediumText>
      {t.message1}
      <br />
      {t.message2}
    </MediumText>
    <ButtonGroup>
      <LoadingButton isFetching={isFetching} onClick={onCancel} type="secondary-grey">
        {t.cancel}
      </LoadingButton>
      <LoadingButton isFetching={isFetching} onClick={onTryAgain}>
        {t.tryAgain}
      </LoadingButton>
    </ButtonGroup>
  </Fragment>
);

SaveErrorModalContent.propTypes = {
  onTryAgain: PropTypes.func,
  onCancel: PropTypes.func,
  isFetching: PropTypes.bool,
  t: PropTypes.shape({
    header: PropTypes.string.isRequired,
    message1: PropTypes.string.isRequired,
    message2: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    tryAgain: PropTypes.string.isRequired,
  }).isRequired,
};

export default compose(
  withMappedTranslations({
    header: 'SAVE_ERROR.HEADER',
    message1: 'SAVE_ERROR.MESSAGES_1',
    message2: 'SAVE_ERROR.MESSAGES_2',
    cancel: 'TEXT.SHARED.CANCEL',
    tryAgain: 'TEXT.SHARED.TRY_AGAIN',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withProps(props => ({
    onTryAgain: props.dispatchFinishEditing,
    onCancel: props.dispatchFinishEditingCancelled,
  })),
)(SaveErrorModalContent);
