import { call, select, put, take, fork, all } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { locationChanged, locationUpdateSuccessed, locationUpdateFailed } from 'ducks/view/view';
import DataApi from 'api/DataApi';
import { getViewInfoJS } from 'selectors/view';
import { getDataInfo } from 'selectors/data';

export function* handleRoute({ payload }) {
  const { location, uuid } = yield select(getViewInfoJS);
  const { current_location, isFetched } = yield select(getDataInfo);
  const newLocation = payload.location.pathname.match(/\/(([A-Za-z]*\/[0-9])|welcome|finish|finished)$/g);
  const search = payload.location.search || '';

  if (!uuid || !newLocation || !isFetched || (newLocation[0] === current_location && newLocation[0] === location)) {
    return;
  }

  yield all([fork(updateRoute, newLocation[0]), fork(put, locationChanged(`${newLocation[0]}${search}`))]);
}

export function* updateRoute(location) {
  try {
    yield call([DataApi.apiInstance(), 'updateLocation'], location);
    yield put(locationUpdateSuccessed(location));
  } catch (e) {
    yield put(locationUpdateFailed(e));
  }
}

function* watchRoute() {
  while (true) {
    const action = yield take(LOCATION_CHANGE);
    yield call(handleRoute, action);
  }
}

export default watchRoute;
