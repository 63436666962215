import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NotificationPageTemplate from '../../templates/NotificationPageTemplate/NotificationPageTemplate';
import IconBadge from '../../molecules/IconBadge/IconBadge';
import { H1, MediumText } from '../../atoms/Typography/Typography';

import { ICONS } from '../../../constants';

const CustomMediaIconBadge = styled(IconBadge)`
  ${props => props.theme.media.tablet`
    width: ${props => props.theme.helpers.rem(71)};
    height: ${props => props.theme.helpers.rem(71)};
  `};
`;

const DisabledPageDiv = styled.div`
  text-align: center;
  max-width: 495px;
  margin: 0 16px;
`;

const DisabledPage = ({ t }) => (
  <NotificationPageTemplate>
    <DisabledPageDiv>
      <CustomMediaIconBadge size={91} icon={ICONS.FORM_CHECK} />
      <H1>{t.header}</H1>
      <MediumText>{t.text}</MediumText>
    </DisabledPageDiv>
  </NotificationPageTemplate>
);

DisabledPage.propTypes = {
  t: PropTypes.shape({
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default DisabledPage;
