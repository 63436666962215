import styled from 'styled-components';

const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 7.5rem) minmax(7.5rem, 1fr);
  grid-column-gap: 1rem;
  max-width: 41.5rem;
`;

export default AmenitiesGrid;
