import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Error = styled.span`
  color: ${props => props.theme.colors.error};
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.125rem;
  display: inline-block;
  font-weight: 500;
`;

const Row = styled.div`
  width: 20rem;
  display: flex;
  > * {
    &:nth-child(n + 2) {
      margin-left: 0.5rem;
    }
  }
  * {
    min-width: auto;
  }
  input,
  label {
    width: 100%;
  }

  ${props => props.theme.media.mobileSmall`
    width: 100%;
  `};
`;

const FieldRow = ({ children, error }) => {
  return (
    <Fragment>
      <Row>{children}</Row>
      {error && <Error>{error}</Error>}
    </Fragment>
  );
};

FieldRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default FieldRow;
