import React, { Fragment } from 'react';
import styled from 'styled-components';

import Modal from 'components/molecules/Modal/Modal';
import BasicModalWrapper from 'components/atoms/ModalWrapper/ModalWrapper';

const ModalWrapper = styled(BasicModalWrapper)`
  background-color: ${props => props.theme.colors.blurOverlay};
`;

const ContentWrapper = styled.div`
  filter: ${props => (props.blur ? 'blur(4.8px)' : 'none')};
`;

const withModal = (modalArray = []) => Component =>
  function WithModal(props) {
    const modals = modalArray.reduce((modalsList, modal, i) => {
      const ModalContent = modal.content;
      modal.show(props) &&
        modalsList.push(
          <Modal key={i}>
            <ModalContent />
          </Modal>,
        );
      return modalsList;
    }, []);
    return (
      <Fragment>
        {modals.length > 0 && <ModalWrapper>{modals}</ModalWrapper>}
        <ContentWrapper blur={modals.length > 0}>
          <Component {...props} />
        </ContentWrapper>
      </Fragment>
    );
  };

export default withModal;
