import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgressBar from 'components/atoms/ProgressBar/ProgressBar';

const Label = styled.div`
  color: ${props => props.theme.colors.n800};
  text-align: center;
  margin: 0.5rem 0;
  font-size: 0.875rem;
  letter-spacing: 0.02rem;
`;

const ProgressText = styled.span`
  color: ${props => props.theme.colors.primary};
  letter-spacing: 0.02rem;
  font-size: 0.875rem;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const OverallProgress = ({ progress, className, t }) => {
  return (
    <Wrapper className={className}>
      <ProgressBar progress={progress} />
      <Label>
        {t.OVERALL_PROGRESS} <ProgressText>{`${Math.round(progress * 100)}${t.PERCENT_COMPLETE}`}</ProgressText>
      </Label>
    </Wrapper>
  );
};

OverallProgress.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  t: PropTypes.shape({
    OVERALL_PROGRESS: PropTypes.string.isRequired,
    PERCENT_COMPLETE: PropTypes.string.isRequired,
  }).isRequired,
};

export default OverallProgress;
