import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StyledSecondaryButton } from 'components/mixins';

import Wrapper from './components/Wrapper';
import ContentWrapper from './components/ContentWrapper';
import ScrollWrapper from './components/ScrollWrapper';
import Content from './components/Content';
import PageIcon from './components/PageIcon';
import PageIconWrapper from './components/PageIconWrapper';
import HeadingWrapper from './components/HeadingWrapper';
import ProgressBar from './components/ProgressBar';
import PageNavigation from './components/PageNavigation';
import { PATHS } from '../../../constants';

export const DefaultPage = ({ headerIcon, header, content, progress, basePath, search, t, nextPage, prevPage }) => {
  const nextPageBtn = nextPage ? (
    <StyledSecondaryButton as={Link} to={{ pathname: `${basePath}/${PATHS[nextPage]}`, search }}>
      {t.navigationNextPage}
    </StyledSecondaryButton>
  ) : (
    <StyledSecondaryButton as={Link} to={{ pathname: `${basePath}/${PATHS.FINISH}`, search }}>
      {t.finish}
    </StyledSecondaryButton>
  );

  const prevPageBtn = prevPage ? (
    <StyledSecondaryButton as={Link} to={{ pathname: `${basePath}/${PATHS[prevPage]}`, search }}>
      {t.navigationPrevPage}
    </StyledSecondaryButton>
  ) : (
    <StyledSecondaryButton disabled>{t.navigationPrevPage}</StyledSecondaryButton>
  );
  return (
    <Wrapper>
      <PageIconWrapper>
        <PageIcon size="48" icon={headerIcon} />
      </PageIconWrapper>
      <ProgressBar t={t} progress={progress} />
      <HeadingWrapper>{header}</HeadingWrapper>
      <ScrollWrapper>
        <ContentWrapper>
          <Content>{content}</Content>
          <PageNavigation>
            {prevPageBtn}
            {nextPageBtn}
          </PageNavigation>
        </ContentWrapper>
      </ScrollWrapper>
    </Wrapper>
  );
};

DefaultPage.propTypes = {
  headerIcon: PropTypes.string,
  nextPage: PropTypes.string,
  prevPage: PropTypes.string,
  header: PropTypes.node,
  content: PropTypes.node,
  progress: PropTypes.number,
  basePath: PropTypes.string.isRequired,
  t: PropTypes.shape({
    navigationNextPage: PropTypes.string.isRequired,
    navigationPrevPage: PropTypes.string.isRequired,
    OVERALL_PROGRESS: PropTypes.string.isRequired,
    PERCENT_COMPLETE: PropTypes.string.isRequired,
    pages: PropTypes.string.isRequired,
    finish: PropTypes.string.isRequired,
  }).isRequired,
  search: PropTypes.string,
};

export default DefaultPage;
