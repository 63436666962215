import { compose, withHandlers } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { formFieldChanged } from '../../ducks/form/form';

const mapDispatchToProps = dispatch => bindActionCreators({ dispatchFormFieldChanged: formFieldChanged }, dispatch);

const withDefaultOnChangeHandler = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withHandlers({
    onChange: props => event => {
      props.dispatchFormFieldChanged({
        fieldName: event.target.name,
        fieldValue: event.target.value,
        fieldType: props.dataType || props.type || 'default',
      });
    },
  }),
);

export default withDefaultOnChangeHandler;
