import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ICONS, PATHS } from '../../../constants';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import SectionProgress from '../../molecules/SectionProgress/SectionProgress';
import { shouldHaveSection } from '../../../utils/shouldHaveSection';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-gap: 1rem 1.6rem;
  margin-bottom: 1rem;
`;

const SectionProgressGrid = ({
  basePath,
  pathname,
  t,
  search,
  validationErrors,
  selectedFields,
  selectedSections,
  ...props
}) => {
  return (
    <Grid>
      {shouldHaveSection(selectedSections, selectedFields, 'GETTING_IN_AND_OUT') && (
        <SectionProgress
          link={{ pathname: `${basePath}/${PATHS.GETTING_IN_AND_OUT}`, search }}
          label={t.GETTING_IN_AND_OUT}
          icon={ICONS.GETTING_IN_AND_OUT}
          progress={props.checkInOut}
          pathname={pathname}
          percentCompleteText={t.PERCENT_COMPLETE}
          errors={validationErrors.checkInOut}
          t={t}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'PROPERTY_DETAILS') && (
        <SectionProgress
          link={{ pathname: `${basePath}/${PATHS.PROPERTY_DETAILS}`, search }}
          label={t.PROPERTY_DETAILS}
          icon={ICONS.PROPERTY_DETAILS}
          progress={props.propertyDetails}
          pathname={pathname}
          percentCompleteText={t.PERCENT_COMPLETE}
          errors={validationErrors.propertyDetails}
          t={t}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'AMENITIES') && (
        <SectionProgress
          link={{ pathname: `${basePath}/${PATHS.AMENITIES}`, search }}
          label={t.AMENITIES}
          icon={ICONS.AMENITIES}
          progress={props.amenities}
          pathname={pathname}
          percentCompleteText={t.PERCENT_COMPLETE}
          errors={validationErrors.amenities}
          t={t}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'CLEANING_AND_MAINTENANCE') && (
        <SectionProgress
          link={{ pathname: `${basePath}/${PATHS.CLEANING_AND_MAINTENANCE}`, search }}
          label={t.CLEANING_AND_MAINTENANCE}
          icon={ICONS.CLEANING_AND_MAINTENANCE}
          progress={props.cleaning}
          pathname={pathname}
          percentCompleteText={t.PERCENT_COMPLETE}
          errors={validationErrors.cleaning}
          t={t}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'RULES_AND_ADDITIONAL_INFO') && (
        <SectionProgress
          link={{ pathname: `${basePath}/${PATHS.RULES_AND_ADDITIONAL_INFO}`, search }}
          label={t.RULES_AND_ADDITIONAL_INFO}
          icon={ICONS.RULES_AND_ADDITIONAL_INFO}
          progress={props.additionalInfo}
          pathname={pathname}
          percentCompleteText={t.PERCENT_COMPLETE}
          errors={validationErrors.additionalInfo}
          t={t}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'COMMUNICATION_WITH_OUR_AGENTS') && (
        <SectionProgress
          link={{ pathname: `${basePath}/${PATHS.COMMUNICATION}`, search }}
          label={t.COMMUNICATION}
          icon={ICONS.COMMUNICATION}
          progress={props.communication}
          pathname={pathname}
          percentCompleteText={t.PERCENT_COMPLETE}
          errors={validationErrors.communication}
          t={t}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'YOUR_LISTING') && (
        <SectionProgress
          link={{ pathname: `${basePath}/${PATHS.LISTING}`, search }}
          label={t.LISTING}
          icon={ICONS.LISTING}
          progress={props.listing}
          pathname={pathname}
          percentCompleteText={t.PERCENT_COMPLETE}
          errors={validationErrors.listing}
          t={t}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'PROPERTY_PHOTOS') && (
        <SectionProgress
          link={{ pathname: `${basePath}/${PATHS.PROPERTY_PHOTOS}`, search }}
          label={t.PROPERTY_PHOTOS}
          icon={ICONS.PROPERTY_PHOTOS}
          progress={props.propertyPhotos}
          pathname={pathname}
          percentCompleteText={t.PERCENT_COMPLETE}
          errors={validationErrors.propertyPhotos}
          t={t}
        />
      )}
    </Grid>
  );
};

SectionProgressGrid.propTypes = {
  checkInOut: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  propertyDetails: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amenities: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cleaning: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  additionalInfo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  nearby: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  communication: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  listing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  propertyPhotos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pathname: PropTypes.string,
  basePath: PropTypes.string.isRequired,
  validationErrors: PropTypes.shape({
    checkInOut: PropTypes.object,
    propertyDetails: PropTypes.object,
    amenities: PropTypes.object,
    cleaning: PropTypes.object,
    additionalInfo: PropTypes.object,
    nearby: PropTypes.object,
    communication: PropTypes.object,
    listing: PropTypes.object,
    propertyPhotos: PropTypes.object,
  }),
  t: PropTypes.shape({
    GETTING_IN_AND_OUT: PropTypes.string.isRequired,
    PROPERTY_DETAILS: PropTypes.string.isRequired,
    CLEANING_AND_MAINTENANCE: PropTypes.string.isRequired,
    RULES_AND_ADDITIONAL_INFO: PropTypes.string.isRequired,
    COMMUNICATION: PropTypes.string.isRequired,
    LISTING: PropTypes.string.isRequired,
    PROPERTY_PHOTOS: PropTypes.string.isRequired,
    AMENITIES: PropTypes.string.isRequired,
    PERCENT_COMPLETE: PropTypes.string.isRequired,
    SECTION_ERROR: PropTypes.string.isRequired,
    SECTION_ERROR_PLURAL: PropTypes.string.isRequired,
  }).isRequired,
  search: PropTypes.string,
  selectedFields: PropTypes.arrayOf(PropTypes.object),
  selectedSections: PropTypes.arrayOf(PropTypes.string),
};

SectionProgressGrid.defaultProps = {
  checkInOut: 0,
  propertyDetails: 0,
  amenities: 0,
  cleaning: 0,
  additionalInfo: 0,
  listing: 0,
  communication: 0,
  propertyPhotos: 0,
  pathname: '',
  search: '',
};

const mapStateToProps = state => ({
  selectedFields: state.data.toJS().value.selected_fields,
  selectedSections: state.data.toJS().value.selected_sections,
});

export default compose(connect(mapStateToProps))(SectionProgressGrid);
