import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { withHandlers } from 'recompose';

import { StyledInput } from '../../../mixins';

const StyledCurrencyInput = styled(StyledInput)`
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
`;

const mask = currencySymbol =>
  createNumberMask({
    prefix: currencySymbol && `${currencySymbol} `,
    allowDecimal: true,
  });

const CurrencyInput = ({ disabled, currencySymbol, handleChange, onBlur, name, value }) => (
  <StyledCurrencyInput
    as={MaskedInput}
    placeholder={currencySymbol}
    mask={mask(currencySymbol)}
    onBlur={onBlur}
    onChange={handleChange}
    disabled={disabled}
    value={value}
    name={name}
    type="text"
  />
);

CurrencyInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  currencySymbol: PropTypes.string,
};

CurrencyInput.defaultProps = {
  disabled: false,
  value: null,
};

const parseValue = value => parseInt(value, 10) || 0;

const formatCurrency = currencyString => {
  if (!currencyString) return;

  let [, dollars, cents] = currencyString.toString().match(/([\d,]*?)(?:\.(\d{0,2}))?$/);
  dollars = parseValue(dollars.replace(/,/g, ''));
  cents = parseValue((cents + '0').substring(0, 2));
  return dollars + cents / 100;
};

const withOnChange = withHandlers({
  handleChange: props => event => {
    props.onChange({ target: { value: formatCurrency(event.target.value), name: event.target.name } });
  },
});

export default withOnChange(CurrencyInput);
