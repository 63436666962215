import styled from 'styled-components';

const Separator = styled.span`
  display: block;
  width: 4.6875rem;
  height: 0.25rem;
  background-color: ${props => props.theme.colors.error};
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${props => props.theme.media.tablet`
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  `};
`;

export default Separator;
