import { put, select, takeEvery, call, all, fork } from 'redux-saga/effects';

import {
  formFieldChanged,
  AMENITIES_ITEM_ADDED,
  AMENITIES_ITEM_REMOVED,
  amenitiesItemAdded,
  amenitiesItemRemoved,
  updateFieldRequested,
} from 'ducks/form/form';
import { UPDATE_DATA_FIELD_SUCCESSED } from 'ducks/data/data';
import { getAmenityItemJS, getAmenityKey } from 'selectors/form.js';

export function* addOrRemoveAmenity(key, action) {
  const amenityItem = yield select(getAmenityItemJS, key);
  if (!amenityItem) return;
  const amenityExists = !!amenityItem.value;
  const defaultAmenityValue = {
    id: null,
    brand: '',
    details: '',
    disabled: true,
    location: '',
    model: '',
    pictures: [],
    type_selected_id: null,
  };
  if (action === addOrRemoveAmenity.actions.ADD && !amenityExists) {
    yield put(amenitiesItemAdded({ ...amenityItem, value: defaultAmenityValue }));
  } else if (action === addOrRemoveAmenity.actions.REMOVE && amenityExists) {
    yield put(amenitiesItemRemoved(amenityItem));
  }
}

addOrRemoveAmenity.actions = {
  ADD: 'add',
  REMOVE: 'remove',
};

export function* handleFieldSideEffects({
  payload: {
    field: { column, value },
  },
}) {
  switch (column) {
    case 'children_friendly': {
      const action = value ? addOrRemoveAmenity.actions.ADD : addOrRemoveAmenity.actions.REMOVE;
      yield call(addOrRemoveAmenity, 'kids_friendly', action);
      break;
    }
    case 'has_wifi': {
      const action = value === 'yes' ? addOrRemoveAmenity.actions.ADD : addOrRemoveAmenity.actions.REMOVE;
      yield call(addOrRemoveAmenity, 'internet', action);
      break;
    }
    default:
      return;
  }
}

export function* handleAmenitySideEffects({
  type,
  payload: {
    item: { id },
  },
}) {
  const key = yield select(getAmenityKey, id);
  let fieldName, fieldType, fieldValue;
  switch (key) {
    case 'kids_friendly': {
      fieldName = 'property_extra_information_children_friendly.value';
      fieldType = 'radio-buttons';
      fieldValue = type === AMENITIES_ITEM_ADDED ? 'yes' : 'no';
      break;
    }
    case 'internet': {
      fieldName = 'property_has_wifi.value';
      fieldType = 'frontend-only';
      fieldValue = type === AMENITIES_ITEM_ADDED ? 'yes' : 'no';
      break;
    }
    default:
      return;
  }
  yield put(formFieldChanged({ fieldName, fieldValue, fieldType }));
  yield put(updateFieldRequested({ name: fieldName, type: fieldType }));
}

export function* watchFieldChanges() {
  yield takeEvery(UPDATE_DATA_FIELD_SUCCESSED, handleFieldSideEffects);
}

export function* watchAmenityChanges() {
  yield takeEvery([AMENITIES_ITEM_ADDED, AMENITIES_ITEM_REMOVED], handleAmenitySideEffects);
}

export default function* watchSideEffectTriggers() {
  yield all([fork(watchFieldChanges), fork(watchAmenityChanges)]);
}
