import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ICONS } from '../../../../constants';

import Icon from '../../Icon/Icon';

const StyledRadioInputSet = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;

const StyledRadioInput = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  display: inline-flex;
  color: ${props => (props.disabled ? props.theme.colors.n400 : props.theme.colors.n800)};
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  &:last-child {
    margin-right: 0;
  }

  svg {
    path {
      fill: ${props => (props.disabled ? props.theme.colors.n400 : props.theme.colors.n800)};
    }
  }
`;

const Label = styled.span`
  line-height: 1.1;
  margin-left: 0.3rem;
  transition: ${props => props.theme.transitions.fast};
  &:focus {
    color: ${props => (props.disabled ? 'inherit' : props.theme.colors.primary)};
  }
`;

const RadioInput = ({ disabled, className, options, onClick, value }) => {
  const selectedRadio = <Icon icon={ICONS.RADIO_CHECKED} size="16" />;
  const emptyRadio = <Icon icon={ICONS.RADIO_EMPTY} size="16" />;
  return (
    <StyledRadioInputSet className={className}>
      {options.map(option => (
        <StyledRadioInput
          tabIndex={disabled ? -1 : 0}
          disabled={disabled}
          key={option.value}
          onClick={() => onClick(option.value)}
        >
          {option.value === value ? selectedRadio : emptyRadio} <Label disabled={disabled}>{option.label}</Label>
        </StyledRadioInput>
      ))}
    </StyledRadioInputSet>
  );
};

RadioInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

RadioInput.defaultProps = {
  disabled: false,
  value: '',
  className: '',
};

export default RadioInput;
