import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { PATHS } from '../../../../constants';
import { H3, Text } from '../../../../components/atoms/Typography/Typography';
import { shouldHaveSection } from '../../../../utils/shouldHaveSection';

const SidebarRouter = ({ match, t, selectedFields, selectedSections }) => {
  return (
    <Fragment>
      {shouldHaveSection(selectedSections, selectedFields, 'GETTING_IN_AND_OUT') && (
        <Route
          path={`/${match.params.uuid}/${PATHS.GETTING_IN_AND_OUT}`}
          render={() => (
            <div>
              <H3>{t('TITLES.GETTING_IN_AND_OUT')}</H3>
              <Text>{t('DESCRIPTIONS.GETTING_IN_AND_OUT')}</Text>
            </div>
          )}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'PROPERTY_DETAILS') && (
        <Route
          path={`/${match.params.uuid}/${PATHS.PROPERTY_DETAILS}`}
          render={() => (
            <div>
              <H3>{t('TITLES.PROPERTY_DETAILS')}</H3>
              <Text>{t('DESCRIPTIONS.PROPERTY_DETAILS')}</Text>
            </div>
          )}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'AMENITIES') && (
        <Route
          path={`/${match.params.uuid}/${PATHS.AMENITIES}`}
          render={() => (
            <div>
              <H3>{t('TITLES.AMENITIES')}</H3>
              <Text>{t('DESCRIPTIONS.AMENITIES')}</Text>
            </div>
          )}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'CLEANING_AND_MAINTENANCE') && (
        <Route
          path={`/${match.params.uuid}/${PATHS.CLEANING_AND_MAINTENANCE}`}
          render={() => (
            <div>
              <H3>{t('TITLES.CLEANING_AND_MAINTENANCE')}</H3>
              <Text>{t('DESCRIPTIONS.CLEANING_AND_MAINTENANCE')}</Text>
            </div>
          )}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'RULES_AND_ADDITIONAL_INFO') && (
        <Route
          path={`/${match.params.uuid}/${PATHS.RULES_AND_ADDITIONAL_INFO}`}
          render={() => (
            <div>
              <H3>{t('TITLES.RULES_AND_ADDITIONAL_INFO')}</H3>
              <Text>{t('DESCRIPTIONS.RULES_AND_ADDITIONAL_INFO')}</Text>
            </div>
          )}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'COMMUNICATION_WITH_OUR_AGENTS') && (
        <Route
          path={`/${match.params.uuid}/${PATHS.COMMUNICATION}`}
          render={() => (
            <div>
              <H3>{t('TITLES.COMMUNICATION')}</H3>
              <Text>{t('DESCRIPTIONS.COMMUNICATION')}</Text>
            </div>
          )}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'YOUR_LISTING') && (
        <Route
          path={`/${match.params.uuid}/${PATHS.LISTING}`}
          render={() => (
            <div>
              <H3>{t('TITLES.LISTING')}</H3>
              <Text>{t('DESCRIPTIONS.LISTING')}</Text>
            </div>
          )}
        />
      )}
      {shouldHaveSection(selectedSections, selectedFields, 'PROPERTY_PHOTOS') && (
        <Route
          path={`/${match.params.uuid}/${PATHS.PROPERTY_PHOTOS}`}
          render={() => (
            <div>
              <H3>{t('TITLES.PROPERTY_PHOTOS')}</H3>
              <Text>{t('DESCRIPTIONS.PROPERTY_PHOTOS')}</Text>
            </div>
          )}
        />
      )}
    </Fragment>
  );
};

SidebarRouter.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.object),
  selectedSections: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  selectedFields: state.data.toJS().value.selected_fields,
  selectedSections: state.data.toJS().value.selected_sections,
});

export default compose(connect(mapStateToProps))(SidebarRouter);
