import IconButton from 'components/molecules/IconButton/IconButton';
import { compose, withHandlers, withProps } from 'recompose';

export default compose(
  withHandlers({
    onClick: props => () =>
      props.handleToggle(!!props.value.disabled, {
        value: props.value,
        id: props.view.id,
        list: props.list,
        category: props.view.category,
      }),
  }),
  withProps(props => ({
    selected: !props.value.disabled,
    icon: props.view.icon,
    label: props.view.name,
    required: props.view.required,
  })),
)(IconButton);
