import styled from 'styled-components';
import BaseGridColumns from './BaseGridColumns';

const Wrapper = styled(BaseGridColumns)`
  padding-top: 1rem;
  max-width: 100%;
  justify-items: flex-start;
  grid-template-rows: -webkit-min-content minmax(0, 1fr);
  grid-template-rows: min-content minmax(0, 1fr);
  grid-template-areas:
    'page-icon header .'
    'content content content';
  ${props => props.theme.media.mobile`
    grid-template-rows: repeat(3, -webkit-min-content) minmax(0, auto);
    grid-template-rows: repeat(3, min-content) minmax(0, auto);
    grid-template-areas:
      'progress-bar progress-bar'
      'page-icon page-icon'
      'header page-number'
      'content content';
  `};
  ${props => props.theme.media.mobileSmall`
    grid-template-rows: repeat(3, -webkit-min-content) minmax(0, auto) -webkit-min-content;
    grid-template-rows: repeat(3, min-content) minmax(0, auto) min-content;
    grid-template-areas:
      'progress-bar'
      'page-icon'
      'header'
      'content'
      'page-number';
    height: auto;
    min-height: 100vh;
  `};
`;

export default Wrapper;
