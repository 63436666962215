import { all, fork } from 'redux-saga/effects';

import location from './location/location';
import status from './status/status';
import progress from './progress/progress';
import health from './health/health';
import validation from './validation/validation';

export default function* rootSaga() {
  yield all([fork(location), fork(status), fork(progress), fork(health), fork(validation)]);
}
