// section & page number of all data fields in the form
// used in form validation to direct user back to the correct page

export default {
  checkInOut: {
    1: ['property_etage', 'property_address', 'property_flat_number', 'property_address_observation'],
    2: [
      'property_info_location',
      'property_code_immeuble_1',
      'property_code_immeuble_2',
      'property_access_badge',
      'property_building_key',
      'property_lift',
      'property_lift_code',
      'property_has_intercom',
      'property_intercom',
      'property_extra_information_alarm_location',
    ],
    3: [
      'property_padlock_possible',
      'property_padlock_location',
      'property_doors_lock_when_closed',
      'property_indication_depot_clef',
      'property_all_keys_tested',
      'property_keys_observation',
      'property_metro_station',
      'property_parking',
      'property_parking_badge',
      'property_indication_parking',
      'property_transport_observation',
    ],
  },
  propertyDetails: {
    1: [
      'property_logement_type_id',
      'property_property_type',
      'property_surface_area',
      'property_license_number',
      'property_sleeps',
      'property_nom_wifi',
      'property_wifi_code',
      'property_wifi_info',
      'property_air_conditioning_supplier_details',
      'property_bills_and_services_up_to_date',
      'property_gas_supplier_details',
      'property_is_maintenance_allowed',
      'property_access_utilities',
    ],
    2: [
      'property_bedrooms_count',
      'property_bedrooms',
      'property_common_space',
      'property_extra_information_pillows_for_all_beds',
      'property_bedrooms_observation',
      'property_bathrooms_count',
      'property_bathrooms',
      'property_bathrooms_instruction',
      'property_bathrooms_observation',
    ],
  },
  amenities: {
    1: ['property_kitchen_amenities_observation'],
    3: ['property_extra_information_safety_observation', 'property_extra_information_heating_and_cooling_observation'],
  },
  cleaning: {
    1: [
      'property_extra_information_cleaning_tools_location',
      'property_extra_information_cleaning_tools_observation',
      'property_extra_information_cleaning_products_location',
      'property_extra_information_cleaning_products_observation',
    ],
    2: ['property_indication_trash', 'property_extra_information_extra_linen_place'],
    3: [
      'property_extra_information_preferred_maintenance',
      'property_extra_information_switches_location',
      'property_extra_information_boiler_location',
      'property_extra_information_heating_control_location',
      'property_extra_information_maintenance_observation',
    ],
  },
  additionalInfo: {
    1: [
      'property_house_rules',
      'property_extra_information_children_friendly',
      'property_extra_information_infant_friendly',
      'property_baby_policy',
    ],
    2: [
      'property_extra_information_letterbox_access',
      'property_indication_mailbox',
      'property_extra_information_additional_observation',
    ],
  },
  communication: {
    1: [
      'property_permanent_check_in_internal_comment',
      'property_indication_welcome',
      'property_indication_menage',
      'property_retour_proprietaire_texte',
      'property_indication_checkout',
    ],
  },
  listing: {
    1: ['property_description'],
  },
  propertyPhotos: {
    1: ['property_photos'],
  },
};
