import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import AmenitiesGrid from 'components/atoms/AmenitiesGrid/AmenitiesGrid';
import AmenityInput from 'scenes/Onboarding/components/AmenityInput';
import { SectionHeading, Text } from 'components/atoms/Typography/Typography';
import withTranslations from 'hoc/withTranslations/withTranslations';
import withWillUnmountHandler from 'hoc/withWillUnmountHandler/withWillUnmountHandler';
import Field, { FieldWithOnClick } from 'scenes/Onboarding/components/Field';

import { AMENITY_CATEGORIES } from 'constants.js';

const Description = styled.div`
  color: ${props => props.theme.colors.n800};
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
  li {
    line-height: 2rem;
  }
`;

const Page = ({ form, t, kidsAmenities, selectedFields }) => {
  return (
    <Fragment>
      <SectionHeading>{t('RULES_AND_ADDITIONAL_INFO.HEADINGS.RULES_AND_GUEST_PREFERENCES')}</SectionHeading>
      <Description>
        <List>
          <li>{t('RULES_AND_ADDITIONAL_INFO.DESCRIPTIONS.GENERAL_RULES.LABEL')}</li>
          <li>{t('RULES_AND_ADDITIONAL_INFO.DESCRIPTIONS.GENERAL_RULES.RULES_1')}</li>
          <li>{t('RULES_AND_ADDITIONAL_INFO.DESCRIPTIONS.GENERAL_RULES.RULES_2')}</li>
          <li>{t('RULES_AND_ADDITIONAL_INFO.DESCRIPTIONS.GENERAL_RULES.RULES_3')}</li>
        </List>
      </Description>
      {selectedFields.includes('property_house_rules') && (
        <Field
          name="property_house_rules.value"
          label={t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.ADDITIONAL_RULES.LABEL')}
          placeholder={t('TEXT.SHARED.ADDITIONAL_COMMENTS_TEXTAREA_PLACEHOLDER')}
          data={form.get('property_house_rules').toJS()}
          type="textarea"
        />
      )}
      <SectionHeading>{t('RULES_AND_ADDITIONAL_INFO.HEADINGS.KIDS')}</SectionHeading>
      {selectedFields.includes('property_extra_information_children_friendly') && (
        <FieldWithOnClick
          name="property_extra_information_children_friendly.value"
          label={t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.CHILD_FRIENDLY.LABEL')}
          type="radio-buttons"
          data={form.get('property_extra_information_children_friendly').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_extra_information_infant_friendly') && (
        <FieldWithOnClick
          name="property_extra_information_infant_friendly.value"
          label={t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.INFANT_FRIENDLY.LABEL')}
          type="radio-buttons"
          data={form.get('property_extra_information_infant_friendly').toJS()}
          options={[{ label: t('TEXT.SHARED.YES'), value: 'yes' }, { label: t('TEXT.SHARED.NO'), value: 'no' }]}
        />
      )}
      {selectedFields.includes('property_baby_policy') && (
        <Field
          name="property_baby_policy.value"
          label={t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.BABY_POLICY.LABEL')}
          placeholder={t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.BABY_POLICY.HINT')}
          data={form.get('property_baby_policy').toJS()}
          type="textarea"
        />
      )}
      <SectionHeading>{t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.KIDS_AMENITIES.LABEL')}</SectionHeading>
      <Text>{t('RULES_AND_ADDITIONAL_INFO.QUESTIONS.KIDS_AMENITIES.DESCRIPTION')}</Text>
      <AmenitiesGrid>
        {kidsAmenities.map(amenityView => {
          const amenityValue = form.getIn(['property_amenities', 'value', amenityView.get('id')]);
          return (
            amenityView && (
              <AmenityInput
                list="property_amenities"
                key={amenityView.get('id')}
                view={amenityView.toJS()}
                value={amenityValue && amenityValue.toJS()}
              />
            )
          );
        })}
      </AmenitiesGrid>
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  kidsAmenities: PropTypes.object.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  kidsAmenities: state.form
    .getIn(['property_amenities', 'view'])
    .filter(amenityView => amenityView.get('category') === AMENITY_CATEGORIES.CHILDREN),
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'RULES_AND_ADDITIONAL_INFO' in obj)
    .RULES_AND_ADDITIONAL_INFO,
});

const TPage = compose(
  withWillUnmountHandler(['property_amenities']),
  withTranslations,
)(Page);
export default connect(mapStateToProps)(TPage);
