import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormGrid from './components/FormGrid';
import FormRouter from './FormRouter';

const Form = ({ sidebar, ...props }) => (
  <FormGrid sidebarOpen={sidebar.open}>
    <FormRouter {...props} />
  </FormGrid>
);

Form.propTypes = {
  sidebar: PropTypes.shape({
    open: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  sidebar: state.view.get('sidebar').toJS(),
});

export default connect(mapStateToProps)(Form);
