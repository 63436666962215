import { Map } from 'immutable';

// TODO: should move this to view, and have a isDataFetching
export const getDataInfo = state => ({
  isFetching: state.data.get('isFetching'),
  isFetched: state.data.get('isFetched'),
  didInvalidate: state.data.get('didInvalidate'),
  ...state.data.get('value', Map({})).toJS(),
});

export const getDataFieldValue = (state, data) => {
  return state.data.getIn(['value', 'data', ...data.resource.split('.'), data.column]);
};

export const getDataForValidation = state => state.data.getIn(['value', 'data'], Map({})).toJS();
