import React from 'react';
import { PropTypes } from 'prop-types';
import { LabelElement } from 'components/molecules/Field/Field.js';
import FileUpload from 'scenes/Onboarding/scenes/Form/scenes/HostInfo/components/FileUpload';
import GroupContainer from 'components/molecules/Field/components/GroupContainer';

const FileUploadField = ({ form, t, label, category, hint }) => {
  return (
    <>
      <GroupContainer label={label}>
        <LabelElement label={label} hint={hint} />
      </GroupContainer>
      <FileUpload
        t={t}
        value={form
          .getIn(['property_documents', 'value'])
          .toList()
          .toJS()}
        category={category}
      />
    </>
  );
};

FileUploadField.propTypes = {
  form: PropTypes.object,
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  category: PropTypes.string,
  hint: PropTypes.string,
};

export default FileUploadField;
