import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import ProgressIcon, {
  StyledProgressIcon,
  BaseCircle,
  StyledIcon,
} from 'components/molecules/ProgressIcon/ProgressIcon';
import { NavLink } from 'react-router-dom';

const withErrorStyle = ({ hasErrors }) =>
  hasErrors &&
  css`
    ${BaseCircle} {
      background: ${props => props.theme.colors.error};
    }

    ${StyledIcon} {
      path {
        fill: ${props => props.theme.colors.white};
      }
    }

    .progress-bar {
      path {
        stroke: none;
      }
    }
  `;

const withSelectedStyle = ({ selected }) =>
  selected &&
  css`
    ${StyledIcon} {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }

    &:hover {
      ${StyledIcon} {
        path {
          fill: ${props => !props.selected && props.theme.colors.primary};
        }
      }
    }
  `;

const IconLink = styled(NavLink)`
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  text-decoration: none;

  ${StyledIcon} {
    path {
      fill: ${props => props.theme.colors.n800};
    }
  }

  ${withSelectedStyle}
  ${withErrorStyle}

  &:hover {
    ${StyledProgressIcon} {
      transform: translate(0, -3px);
    }

    ${BaseCircle} {
      box-shadow: 0.125rem 0.8125rem 0.6875rem rgba(0, 0, 0, 0.1);
    }
  }
`;

const Label = styled.span`
  color: ${props => (props.selected ? props.theme.colors.primary : props.theme.colors.n800)};
  font-weight: ${props => props.selected && 600};
  font-size: 0.75rem;
  text-align: center;
  letter-spacing: 0.02rem;
  display: inline-block;
  margin-top: 0.375rem;
  text-decoration: none;
`;

const ProgressText = styled(Label)`
  color: ${props => (+props.progress === 1 ? props.theme.colors.primary : props.theme.colors.textSecondary)};
  letter-spacing: 0.02rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
`;

const getSectionLink = (link, errors) => {
  if (errors.count) {
    return { ...link, pathname: link.pathname.replace(/\d+\/?$/, errors.pageNumbers[0]) };
  }
  return link;
};

const getProgressText = (progress, errors, percentCompleteText, t) => {
  if (errors.count) {
    return errors.count === 1 ? `${errors.count} ${t.SECTION_ERROR}` : `${errors.count} ${t.SECTION_ERROR_PLURAL}`;
  }
  return `${Math.round(progress * 100)}${percentCompleteText}`;
};

const SectionProgress = ({ percentCompleteText, errors, t, ...props }) => {
  const selected = props.pathname.replace(/\d+\/?$/, '1').match(props.link.pathname);
  const progressText = getProgressText(props.progress, errors, percentCompleteText, t);
  const link = getSectionLink(props.link, errors);

  return (
    <IconLink hasErrors={errors.count} to={link} selected={selected}>
      <ProgressText progress={props.progress}>{progressText}</ProgressText>
      <ProgressIcon {...props} />
      <Label selected={selected}>{props.label}</Label>
    </IconLink>
  );
};

SectionProgress.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.shape({
    count: PropTypes.number,
    pageNumbers: PropTypes.arrayOf(PropTypes.string),
  }),
  label: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  pathname: PropTypes.string,
  percentCompleteText: PropTypes.string,
  t: PropTypes.shape({
    SECTION_ERROR: PropTypes.string.isRequired,
    SECTION_ERROR_PLURAL: PropTypes.string.isRequired,
  }).isRequired,
};

SectionProgress.defaultProps = {
  link: { pathname: '/', search: '' },
  errors: {
    count: 0,
    pageNumbers: [],
  },
};

export default SectionProgress;
