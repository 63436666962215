import { withHandlers } from 'recompose';

const withOnClickHandler = withHandlers({
  onClick: props => value => {
    const event = { target: { value, name: props.name } };
    props.onChange(event);
    props.onBlur(event);
  },
});

export default withOnClickHandler;
