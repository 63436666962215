import { Map, List, fromJS } from 'immutable';
import countries from 'country-list';

export const alphabeticalSort = key => (a, b) => {
  const _a = a[key].toLowerCase();
  const _b = b[key].toLowerCase();
  if (_a < _b) {
    return -1;
  }
  if (_a > _b) {
    return 1;
  }
  return 0;
};

export const mapCodelistCountries = countries =>
  Map({
    countries: List(countries)
      .sort(alphabeticalSort('name'))
      .map(country => Map({ value: country.code, label: country.name })),
  });

export const mapCodelistLogementTypes = logement_types =>
  Map({
    logement_types: List(logement_types)
      .sort(alphabeticalSort('label'))
      .map(logementType => Map({ value: logementType.id, label: logementType.label })),
  });

export const mapBathroomsCounter = () =>
  Map({
    bathrooms_counter: List(Array.from(new Array(10), (val, index) => Map({ label: index + 1, value: index + 1 }))),
  });

export const mapBedroomsCounter = zeroLabel =>
  Map({
    bedrooms_counter: List(Array.from(new Array(11), (val, index) => Map({ label: index || zeroLabel, value: index }))),
  });

export default function mapCodelists({ codelist }) {
  return Map()
    .merge({
      bed_types: fromJS(codelist['bed_types']),
      bathrooms: fromJS(codelist['bathrooms']),
      amenities: fromJS(codelist['amenities']),
      property_types: fromJS(codelist['property_types']),
      bed_locations: fromJS(codelist['bed_locations']),
      discovery_source: fromJS(codelist['how_did_you_hear_about_us_types']),
      building_construction_period: fromJS(codelist['building_construction_period_types']),
      bills_paid_by: fromJS(codelist['bills_paid_by_types']),
      building_legal_status: fromJS(codelist['building_legal_status_types']),
    })
    .merge(mapCodelistCountries(countries.getData()))
    .merge(mapCodelistLogementTypes(codelist['logement_types']))
    .merge(mapBathroomsCounter())
    .merge(mapBedroomsCounter(codelist.logement_types.find(type => type.value.toLowerCase() === 'studio').label));
}
