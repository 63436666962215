import styled from 'styled-components';

const CircleWithShadow = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  box-shadow: 0 0.3125rem 0.8125rem rgba(0, 0, 0, 0.07);
  display: inline-flex;
  height: ${props => props.theme.helpers.rem(props.size)};
  justify-content: center;
  transition: ${props => props.theme.transitions.default};
  width: ${props => props.theme.helpers.rem(props.size)};
`;

export default CircleWithShadow;
