import { call, put, select, takeLatest, all, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getViewInfoJS, getValidationErrors } from 'selectors/view';
import { getDataForValidation } from 'selectors/data';

import { UPDATE_FIELD_REQUESTED } from 'ducks/form/form';
import {
  checkRequiredFieldsFailed,
  validationMapSuccessed,
  checkRequiredFieldsSuccessed,
  setErrorsForAllSections,
} from 'ducks/view/view';
import DataApi from 'api/DataApi';

import validationMapper from './validationMapper';

export function* validateForm() {
  const { uuid } = yield select(getViewInfoJS);
  try {
    const data = yield select(getDataForValidation);
    const response = yield call([DataApi.apiInstance(), 'checkRequiredFields'], data);
    const mappedValidationData = yield call(validationMapper, response);
    yield put(validationMapSuccessed(mappedValidationData));
    if (response.success) {
      yield put(push(`/${uuid}/finish`));
    }
    yield put(checkRequiredFieldsSuccessed());
  } catch (e) {
    yield put(checkRequiredFieldsFailed());
  }
}

export function* handleUpdateValidationAfterFieldChanged({ payload }) {
  const { name } = payload.field;
  const fieldName = name.split('.')[0];
  const errors = yield select(getValidationErrors);
  const newErrors = errors.map(section =>
    section.map(page => page.filterNot(field => field === fieldName)).filter(page => page.size),
  );
  yield put(setErrorsForAllSections(newErrors));
}

export function* watchFormFieldChanged() {
  yield takeLatest([UPDATE_FIELD_REQUESTED], handleUpdateValidationAfterFieldChanged);
}

export default function* watchValidation() {
  yield all([fork(watchFormFieldChanged)]);
}
