import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import withTranslations from 'hoc/withTranslations/withTranslations';
import Field from 'scenes/Onboarding/components/Field';

const Page = ({ form, t, selectedFields }) => {
  return (
    <Fragment>
      {selectedFields.includes('property_permanent_check_in_internal_comment') && (
        <Field
          name="property_permanent_check_in_internal_comment.value"
          label={t('COMMUNICATION.QUESTIONS.BOOKING_INSTRUCTIONS.LABEL')}
          hint={t('COMMUNICATION.QUESTIONS.BOOKING_INSTRUCTIONS.HINT')}
          data={form.get('property_permanent_check_in_internal_comment').toJS()}
          type="textarea"
        />
      )}
      {selectedFields.includes('property_indication_welcome') && (
        <Field
          name="property_indication_welcome.value"
          label={t('COMMUNICATION.QUESTIONS.CHECK_IN_INSTRUCTIONS.LABEL')}
          hint={t('COMMUNICATION.QUESTIONS.CHECK_IN_INSTRUCTIONS.HINT')}
          data={form.get('property_indication_welcome').toJS()}
          type="textarea"
        />
      )}
      {selectedFields.includes('property_indication_menage') && (
        <Field
          name="property_indication_menage.value"
          label={t('COMMUNICATION.QUESTIONS.CLEANING_INSTRUCTIONS.LABEL')}
          hint={t('COMMUNICATION.QUESTIONS.CLEANING_INSTRUCTIONS.HINT')}
          data={form.get('property_indication_menage').toJS()}
          type="textarea"
        />
      )}
      {selectedFields.includes('property_retour_proprietaire_texte') && (
        <Field
          name="property_retour_proprietaire_texte.value"
          label={t('COMMUNICATION.QUESTIONS.PERSONAL_STAY_INSTRUCTIONS.LABEL')}
          hint={t('COMMUNICATION.QUESTIONS.PERSONAL_STAY_INSTRUCTIONS.HINT')}
          data={form.get('property_retour_proprietaire_texte').toJS()}
          type="textarea"
        />
      )}
      {selectedFields.includes('property_indication_checkout') && (
        <Field
          name="property_indication_checkout.value"
          label={t('COMMUNICATION.QUESTIONS.CHECK_OUT_INSTRUCTIONS.LABEL')}
          hint={t('COMMUNICATION.QUESTIONS.CHECK_OUT_INSTRUCTIONS.HINT')}
          data={form.get('property_indication_checkout').toJS()}
          type="textarea"
        />
      )}
    </Fragment>
  );
};

Page.propTypes = {
  form: PropTypes.object,
  view: PropTypes.object,
  t: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  form: state.form,
  view: state.view,
  selectedFields: state.data.toJS().value.selected_fields.find(obj => 'COMMUNICATION_WITH_OUR_AGENTS' in obj)
    .COMMUNICATION_WITH_OUR_AGENTS,
});

const TPage = withTranslations(Page);
export default connect(mapStateToProps)(TPage);
