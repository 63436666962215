import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ICONS } from '../../../constants';
import Icon from '../Icon/Icon';

const StyledInputControl = styled.span`
  padding: 0.25rem;
  box-sizing: content-box;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-flex;
  outline: none;
  user-select: none;
  cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  transition: ${props => props.theme.transitions.fast};
  svg path {
    fill: ${props => (props.disabled ? props.theme.colors.n400 : props.theme.colors.n800)};
  }
  &:hover,
  &:focus {
    svg path {
      fill: ${props => (props.disabled ? props.theme.colors.n400 : props.theme.colors.primary)};
    }
  }
`;

const StyledIcon = styled(Icon)`
  path {
    transition: ${props => props.theme.transitions.fast};
  }
`;

const calc = (value, number) => {
  const v = Number(value) + number;
  if (v < 0) return 0;
  return v;
};

const InputControl = ({ value, onClick, ...props }) => {
  if (props.type === 'plus') {
    return (
      <StyledInputControl {...props} tabIndex={props.disabled ? -1 : 0} onClick={() => onClick(calc(value, 1))}>
        <StyledIcon icon={ICONS.PLUS} size="20" />
      </StyledInputControl>
    );
  } else if (props.type === 'minus') {
    return (
      <StyledInputControl {...props} tabIndex={props.disabled ? -1 : 0} onClick={() => onClick(calc(value, -1))}>
        <StyledIcon icon={ICONS.MINUS} size="20" />
      </StyledInputControl>
    );
  }
};

InputControl.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['plus', 'minus']).isRequired,
  value: function({ value }, propName, componentName) {
    if (value && isNaN(value) && value.length) {
      return new Error(
        `Failed prop type: Invalid prop '${propName}' supplied to '${componentName}', expected 'number' or empty 'string'.`,
      );
    }
  },
};

InputControl.defaultProps = {
  disabled: false,
  value: '',
};

export default InputControl;
