import styled from 'styled-components';

const Content = styled.div`
  grid-area: content;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: 720px;
  > :first-child {
    margin-top: 0;
  }
`;

export default Content;
