import { put, select, all, fork, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { getRoutingLocation } from 'selectors/routing';
import { getViewInfoJS } from 'selectors/view';

import { FETCH_DATA_SUCCESSED, FETCH_DATA_FAILED } from 'ducks/data/data';

export function* handleDataSuccessed({ payload: { data } }) {
  const { uuid } = yield select(getViewInfoJS);
  const search = window.location.search || '';

  if (!data.enabled) return yield put(replace(`/${uuid}/disabled`));

  if (['/finished', '/finish'].indexOf(data.current_location) > -1) {
    return yield put(replace(`/${uuid}/welcomeback`));
  }

  if (!(data.current_location && data.current_location.length)) return yield put(replace(`/${uuid}/welcome`));

  const routingLocation = yield select(getRoutingLocation);

  if (routingLocation === data.current_location || !routingLocation.match(`^/${uuid}(|/)$`)) return;

  return yield put(replace(`/${uuid}${data.current_location}${search}`));
}

export function* handleDataFailed() {
  yield put(replace('/notfound'));
}

export function* watchDataFailed() {
  yield takeEvery(FETCH_DATA_FAILED, handleDataFailed);
}

export function* watchDataSuccessed() {
  yield takeEvery(FETCH_DATA_SUCCESSED, handleDataSuccessed);
}

export default function* watchData() {
  yield all([fork(watchDataSuccessed), fork(watchDataFailed)]);
}
