import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NotificationPageTemplate from '../../templates/NotificationPageTemplate/NotificationPageTemplate';
import IconBadge from '../../molecules/IconBadge/IconBadge';
import { H1 } from '../../atoms/Typography/Typography';

import { ICONS } from '../../../constants';

const CustomMediaIconBadge = styled(IconBadge)`
  ${props => props.theme.media.tablet`
    width: ${props => props.theme.helpers.rem(71)};
    height: ${props => props.theme.helpers.rem(71)};
  `};
`;

const NotFoundPageDiv = styled.div`
  text-align: center;
  max-width: 540px;
  margin: 0 16px;
`;

const NotFoundPage = ({ t }) => (
  <NotificationPageTemplate>
    <NotFoundPageDiv>
      <CustomMediaIconBadge size={91} icon={ICONS.MAGNIFIER} />
      <H1 error>{t.header}</H1>
    </NotFoundPageDiv>
  </NotificationPageTemplate>
);

NotFoundPage.propTypes = {
  t: PropTypes.shape({
    header: PropTypes.string.isRequired,
  }).isRequired,
};

export default NotFoundPage;
