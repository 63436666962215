import { put, takeLatest, call } from 'redux-saga/effects';

import { FETCH_DATA_SUCCESSED } from '../../../ducks/data/data';

import { dataMapSucessed } from '../../../ducks/form/form';
import { codelistMapSuccessed } from '../../../ducks/view/view';
import mapData from './dataMapper/dataMapper';
import mapCodelist from './codelistMapper/codelistMapper';
import mapMetadata from './metadataMapper/metadataMapper';

export function* processFormData({ payload: { data, metadata } }) {
  const mappedCodelist = yield call(mapCodelist, data);
  const mappedMetadata = yield call(mapMetadata, metadata);
  yield put(codelistMapSuccessed(mappedCodelist));
  const mappedData = yield call(mapData, mappedCodelist.toJS(), mappedMetadata.toJS(), data);
  yield put(dataMapSucessed(mappedData));
}

export default function* watchFormData() {
  yield takeLatest(FETCH_DATA_SUCCESSED, processFormData);
}
