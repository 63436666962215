import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Logo from '../../atoms/Logo/Logo';

const BackgroundDiv = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.n100};
  display: grid;
  height: 100vh;
  justify-items: center;
  grid-template-rows: 1fr auto;
`;

const LogoWrapper = styled.div`
  margin-bottom: 41px;
  margin-top: 41px;
  transition: ${props => props.theme.transitions.default};
  width: 160px;
  ${props => props.theme.media.tablet`
    width: 150px;
  `};
  ${props => props.theme.media.mobile`
    margin-bottom: 31px;
    margin-top: 31px;
    width: 112px;
  `};
`;

const NotificationPageTemplate = ({ children, logo }) => (
  <BackgroundDiv>
    {children}
    {logo && (
      <LogoWrapper>
        <Logo logo={logo} />
      </LogoWrapper>
    )}
  </BackgroundDiv>
);

NotificationPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  logo: PropTypes.string,
};

export default NotificationPageTemplate;
