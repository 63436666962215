import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Heading = styled.strong`
  margin-top: 1.5rem;
  display: block;
`;

const TextWrapper = styled.div`
  max-width: 40rem;
  font-weight: ${props => props.bold && 'bold'};
`;

const Description = props => {
  return (
    <div>
      <Heading>{props.heading}</Heading>
      <TextWrapper bold={props.bold}>{props.children}</TextWrapper>
    </div>
  );
};

Description.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  bold: PropTypes.bool,
};

export default Description;
