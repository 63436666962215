import { Map, fromJS } from 'immutable';
import { put, takeEvery, call, all, select, fork } from 'redux-saga/effects';

import { FILES_ADDED, FILE_REMOVED, updateSpecialFieldFailed, updateSpecialFieldSuccessed } from 'ducks/form/form';

import { getFormFieldInfoJS } from 'selectors/form.js';

import DataApi from 'api/DataApi.js';
import { presignPicture, uploadPresignedPicture } from 'api/S3Api.js';

export function* uploadFile({ file, category }) {
  try {
    const presigned = yield call(presignPicture, file);
    const url = yield call(uploadPresignedPicture, { data: presigned.data, file });
    const value = yield call([DataApi.apiInstance(), 'updateSpecialField'], `/property_documents/`, {
      file: url,
      category,
    });

    return value;
  } catch (e) {
    yield put(updateSpecialFieldFailed(e));
  }
}

export function* addFiles({ payload: { files, category } }) {
  const uploadedDocuments = yield all(files.map(file => call(uploadFile, { file, category })));
  const existingDocuments = yield select(getFormFieldInfoJS, 'property_documents');
  const updatedDocuments = fromJS(existingDocuments.value).merge(
    fromJS(uploadedDocuments.filter(val => val).map(document => [`${document.id}`, document])),
  );
  const mappedField = yield Map().setIn(['property_documents', 'value'], updatedDocuments);
  yield put(updateSpecialFieldSuccessed(mappedField));
}

export function* removeFile({ payload: { id } }) {
  if (id) {
    // TODO: add error handling & view update
    //  - at the moment the deleted photo is immediately removed from the view, regardless of the outcome here
    yield call([DataApi.apiInstance(), 'deleteSpecialField'], '/property_documents', [id]);
  }
}

export function* watchFilesAdded() {
  yield takeEvery(FILES_ADDED, addFiles);
}

export function* watchFileRemoved() {
  yield takeEvery(FILE_REMOVED, removeFile);
}

export default function* watchFileFieldsUpdate() {
  yield all([fork(watchFilesAdded), fork(watchFileRemoved)]);
}
